// import React, { useRef, useState } from "react";
// import { Button, Row, Col, Container } from "react-bootstrap";
// import { ProgressBar } from "react-bootstrap";
// import { useWorkspace } from "../../../contexts/WorkspaceContext";

// const FileUploader = () => {
//   const { threadFiles, setThreadFiles, uploadFiles } = useWorkspace();
//   const fileInputRef = useRef(null);

//   const [uploadProgress, setUploadProgress] = useState(0);
//   // const lddd = setInterval(() => {
//   //   setUploadProgress((prev) => prev++)
//   // }, 1000);

//   const handleFileChange = async (event) => {
//     const files = Array.from(event.target.files); // Convert FileList to Array
//     const existingFileNames = threadFiles.map((file) => file.name);

//     // Filter out files that already exist in threadFiles by their names
//     const newFiles = files.filter(
//       (file) => !existingFileNames.includes(file.name)
//     );

//     // Clear the file input after selecting files
//     event.target.value = "";

//     // Map files to upload promises
//     const uploadPromises = newFiles.map(async (file) => {
//       // Map each file to the prescribed JSON format and mark as "uploading"
//       const fileToUpdate = {
//         type: "file",
//         file: file,
//         name: file.name,
//         status: "uploading", // Mark as "uploading" initially
//         fileId: null,
//       };

//       // Update threadFiles with the new file object
//       setThreadFiles((prevThreadFiles) => [...prevThreadFiles, fileToUpdate]);

//       // Upload the file and return fileId
//       const response = await uploadFiles(fileToUpdate);
//       const fileId = response.data.id;

//       // Update the file object with uploaded state or upload failed
//       setThreadFiles((prevThreadFiles) =>
//         prevThreadFiles.map((prevFile) =>
//           prevFile.name === file.name
//             ? fileId
//               ? { ...prevFile, status: "uploaded", fileId: fileId }
//               : { ...prevFile, status: "upload failed" }
//             : prevFile
//         )
//       );

//       // Return updated file object with fileId
//       return fileId
//         ? { ...fileToUpdate, status: "uploaded", fileId: fileId }
//         : { ...fileToUpdate, status: "upload failed" };
//     });

//     // Update progress bar as files are uploaded
//     let uploaded = 0;
//     uploadPromises.forEach(async (promise) => {
//       await promise;
//       uploaded++;
//       const progress = (uploaded / newFiles.length) * 100;
//       setUploadProgress(progress);
//     });

//     // Wait for all uploads to finish
//     await Promise.all(uploadPromises);
//   };

//   const handleRemoveFile = (index) => {
//     const updatedFiles = threadFiles.filter(
//       (_, fileIndex) => fileIndex !== index
//     );
//     setThreadFiles(updatedFiles); // Update the context
//   };

//   const handleFileSelect = () => {
//     fileInputRef.current.click();
//     // Show ProgressBar when file is selected
//     setUploadProgress(0);
//   };

//   return (
//     <Container fluid>
//       <Row style={{ display: "flex", flexDirection: "column" }}>
//         <Button
//           variant="outline-primary"
//           onClick={handleFileSelect}
//           className="mb-2 d-flex align-items-center justify-content-start"
//           style={{ fontSize: ".95rem" }}
//         >
//           <i className="bi bi-paperclip pe-3" />
//           Add File/s
//         </Button>
//         <input
//           id="file-upload"
//           type="file"
//           accept=".txt, .docx, .mp3, .pdf, .mp4, .xlsx, video/*"
//           style={{ display: "none" }}
//           ref={fileInputRef}
//           onChange={handleFileChange}
//           multiple
//         />
//       </Row>
//       <ProgressBar
//         now={uploadProgress}
//         label={`${uploadProgress.toFixed(2)}%`}
//         className="mb-1"
//       />
//       {threadFiles.map((file, index) => (
//         <div key={index}>
//           {file.type === "file" && (
//             <Row className="mb-1 px-0 m-0 align-items-center">
//               <Col
//                 xs="10"
//                 className={
//                   file.status === "upload failed"
//                     ? "text-danger p-0 m-0"
//                     : "p-0 m-0"
//                 }
//                 style={{
//                   overflow: "hidden",
//                   textOverflow: "ellipsis",
//                   whiteSpace: "nowrap",
//                 }}
//               >
//                 {file.name}
//               </Col>
//               <Col
//                 xs="2"
//                 className="m-0 p-0"
//                 style={{
//                   display: "flex",
//                   justifyContent: "end",
//                   alignItems: "center",
//                 }}
//               >
//                 {file.status === "uploading" && (
//                   <ProgressBar
//                     now={uploadProgress}
//                     label={`${uploadProgress.toFixed(2)}%`}
//                   />
//                 )}
//                 {file.status === "upload failed" ? (
//                   <i
//                     className="bi bi-exclamation-circle"
//                     onClick={() => handleRemoveFile(index)}
//                     style={{ cursor: "pointer" }}
//                   />
//                 ) : (
//                   <i
//                     className="bi bi-x-lg"
//                     onClick={() => handleRemoveFile(index)}
//                     style={{ cursor: "pointer" }}
//                   />
//                 )}
//               </Col>
//             </Row>
//           )}
//         </div>
//       ))}
//     </Container>
//   );
// };

// export default FileUploader;







import React, { useRef, useState, useEffect } from "react";
import { Button, Row, Col, Spinner, Container, ProgressBar } from "react-bootstrap";
import { useWorkspace } from "../../../contexts/WorkspaceContext";
import sourceUrl from "../../../api/Source.api";
import * as Sentry from "@sentry/react";
import Upload_Icon from "../../../assets/Upload_Icon.png";
import { uploadFiles_button_default_bg_color, uploadFiles_button_hovered_bg_color, uploadFiles_button_text_color_dm } from "../../../constants/colors";
import { FileUpload, trackFileUpload } from "../../../config/analytics";
import {  useMediaQuery } from "@mui/material";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CommonToast from "../../Common_Components/CommonToast";

const FileUploader = () => {
  const { threadFiles, setThreadFiles, uploadFiles, checkUrlStatus, threadId,setWaitThread,InvokeMatrixApi,upload_FilesOnly } = useWorkspace();
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [progressStatusChecking, setProgressStatusChecking] = useState({});
  const isLoggedIn = localStorage.getItem('isLoggedIn');
  const token = localStorage.getItem('token');
  const [theme,setTheme] = useState(localStorage.getItem('theme'));
  const [isHovered, setIsHovered] = useState(false);
  const email = localStorage.getItem("userEmail");
  const mobileView = useMediaQuery("(max-width:992px)");
  const [fileUploaded,setFileUploaded] = useState(false);
  const [notificationToastMessage,setNotificationToastMessage] = useState('');

  const handleFileChange = async (event) => {
    try {
      setWaitThread(true);
      const files = Array.from(event.target.files); // Convert FileList to Array
      const existingFileNames = threadFiles.map((file) => file.name);

      // Filter out files that already exist in threadFiles by their names
      const newFiles = files.filter(
        (file) => !existingFileNames.includes(file.name)
      );

      // Clear the file input after selecting files
      event.target.value = "";
      
      // Map files to upload promises
      const uploadPromises = newFiles.map(async (file) => {
        setLoading(true); // Show loader

        // Map each file to the prescribed JSON format and mark as "uploading"
        const fileToUpdate = {
          type: "file",
          file: file,
          name: file.name,
          status: "uploading", // Mark as "uploading" initially
          fileId: null,
          url: null, // Add a placeholder for the URL
        };

        // Update threadFiles with the new file object
        setThreadFiles((prevThreadFiles) => [...prevThreadFiles, fileToUpdate]);

        // Upload the file and return fileId
        const response = await upload_FilesOnly(fileToUpdate);
        const fileId = response.data.id;
        const url = response.data.url;

        // console.log('here is the url that sent before check kkkkkkkkkkkkkkkkkkkkkkkkk',response)
        // After uploading file event triggred for file uploaded

        const fileType = files[0].type 
        const fileCount = threadFiles.length+1
        const file_method  =  "Click to Upload"
        trackFileUpload("file_upload",fileType, fileCount,  file_method);
        FileUpload(fileType,fileCount)

        // Update the file object with uploaded state or upload failed
        setThreadFiles((prevThreadFiles) =>
          prevThreadFiles.map((prevFile) =>
            prevFile.name === file.name
              ? {
                  ...prevFile,
                  status: response.data.status, // Update status
                  fileId: fileId, // Update fileId
                  url: url, // Update URL
                }
              : prevFile
          )
        );

        // Start checking progress status
        setProgressStatusChecking((prev) => ({ ...prev, [url]: true }));
        
        // Poll progress status every 3 seconds
        const interval = setInterval(async () => {
          
          // const progressStatus = await checkUrlStatus(url);
          const status_resp = await fetch(`${sourceUrl}/status`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization":`Bearer ${token}`
            },
            body: JSON.stringify({ threadId, url }),
          });
          const responseData = await status_resp.json();
          if (status_resp.status === 200 && responseData.data.status === "completed") {
             // ==========================Invoke matrix api ===================================================
              InvokeMatrixApi(responseData,status_resp,'POST','status',{ threadId, url},email)
             // ===============================================================================================
            setThreadFiles((prevThreadFiles) =>
              prevThreadFiles.map((prevFile) =>
                prevFile.name === file.name
                  ? {
                      ...prevFile,
                      status:"completed", // Update status
                      fileId: fileId, // Update fileId
                      url: url, // Update URL
                    }
                  : prevFile
              )
            );
           
            setProgressStatusChecking((prev) => ({ ...prev, [url]: false })); // Stop checking progress status
            clearInterval(interval); // Stop interval
            setFileUploaded(true);
            setNotificationToastMessage('File uploaded successfully')
          }
          else {
            if(status_resp.status === 200 && responseData.data.status === "failed"){
              
              setThreadFiles((prevThreadFiles) =>
                prevThreadFiles.map((prevFile) =>
                  prevFile.name === file.name
                    ? {
                        ...prevFile,
                        status:"failed",
                        fileId:"", // Update fileId
                        url: url, // Update URL
                      }
                    : prevFile
                )
              );
               // ==========================Invoke matrix api ===================================================
                InvokeMatrixApi(responseData,status_resp,'POST','status',{ threadId, url },email)
                // ===============================================================================================
              setProgressStatusChecking((prev) => ({ ...prev, [url]: false })); // Stop checking progress status
              clearInterval(interval); // Stop interval
            }
            else if(status_resp.status === 500){
              setProgressStatusChecking((prev) => ({ ...prev, [url]: false })); // Stop checking progress status
              clearInterval(interval); // Stop interval
            } 
            // setFileUploaded(true);
            // setNotificationToastMessage('There was an error while uploading the file. Please try again')
          }
        }, 3000);

        // Return updated file object with fileId
        return fileId
          ? { ...fileToUpdate, status: "uploaded", fileId: fileId }
          : { ...fileToUpdate, status: "failed" };
      });

      // Wait for all uploads to finish
      await Promise.all(uploadPromises);

      setLoading(false); // Hide loader
      setWaitThread(false);
      // triggredFileUploadEvent(event)
    } catch (error) {
      Sentry.captureException(error);
      console.error("Error while handling file change:", error);
      setFileUploaded(true);
      setNotificationToastMessage('There was an error while uploading the file. Please try again')
    }
  };


  console.log('threadfiles', threadFiles);

  const handleRetryFile = async (file) => {
    
    // Mark the file as uploading
    const updatedFiles = threadFiles.map((prevFile) =>
      prevFile.name === file.name
        ? { ...prevFile, status: "uploading" }
        : prevFile
    );
    setThreadFiles(updatedFiles);
  
    // Retry uploading the file
    const response = await upload_FilesOnly(file);
    const fileId = response.data.id;
    const url = response.data.url;
  
    // Update the file object with the new status and fileId
    setThreadFiles((prevThreadFiles) =>
      prevThreadFiles.map((prevFile) =>
        prevFile.name === file.name
          ? {
              ...prevFile,
              status: response.data.status, // Update status
              fileId: fileId, // Update fileId
              url: url, // Update URL
            }
          : prevFile
      )
    );
  
    // Start checking progress status
    setProgressStatusChecking((prev) => ({ ...prev, [url]: true }));
  
    // Poll progress status every 3 seconds
    const interval = setInterval(async () => {
      
      const progressStatus = await checkUrlStatus(url);
      const status_resp = await fetch(`${sourceUrl}/status`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ threadId, url }),
      });
      const responseData = await status_resp.json();
      if (progressStatus && responseData.data.status === "completed") {
        // Update the file status to completed
        setThreadFiles((prevThreadFiles) =>
          prevThreadFiles.map((prevFile) =>
            prevFile.name === file.name
              ? {
                  ...prevFile,
                  status: "completed",
                }
              : prevFile
          )
        );
        // ==========================Invoke matrix api ===================================================
         InvokeMatrixApi(responseData,status_resp,'POST','status',{ threadId, url },email)
        // ===============================================================================================
        setProgressStatusChecking((prev) => ({ ...prev, [url]: false })); // Stop checking progress status
        clearInterval(interval); // Stop interval
      } else {
        // Update the file status to failed if the retry is unsuccessful
        if (responseData.data.status === "failed") {
          setThreadFiles((prevThreadFiles) =>
            prevThreadFiles.map((prevFile) =>
              prevFile.name === file.name
                ? {
                    ...prevFile,
                    status: "failed",
                    fileId:"",
                  }
                : prevFile
            )
          );
          // ==========================Invoke matrix api ===================================================
          InvokeMatrixApi(responseData,status_resp,'POST','status',{ threadId, url },email)
          // ===============================================================================================
          setProgressStatusChecking((prev) => ({ ...prev, [url]: false })); // Stop checking progress status
          clearInterval(interval); // Stop interval
        }
      }
    }, 3000);
  };

  const handleCloseToast = () => {
    setFileUploaded(false);
    setNotificationToastMessage('');
  };
  

  const handleFileSelect = () => {
    fileInputRef.current.click();
  };

  return (
    // <div></div>
    <Container fluid>
      {mobileView ?
            <Row style={{ display: "flex", flexDirection: "column",textAlign:'center'}}>
            <div>
              {/* <img src={Upload_Icon} style={{maxWidth:'44px',maxHeight:'36px',marginBottom:'30px',marginTop:'30px'}}/> */}
              {/* <div style={{maxWidth:'208px',height:'64px',lineHeight:'15.73px',}}>
                <p style={{fontSize:'13px'}}>Select or drag and drop multiple files here</p>
                <p style={{fontSize:'13px'}}>JPG, PNG, PDF, XLSX, MOV, MP3</p>
              </div> */}
                  
            </div>
            <Button 
              variant="outline-primary"
              onClick={handleFileSelect}
              className="mb-3 d-flex align-items-center justify-content-center" // Use 'justify-content-center' instead of 'justify-content-start'
              style={{
                fontSize: '16px',
                maxWidth: '282px',
                backgroundColor: isHovered ? uploadFiles_button_hovered_bg_color : (theme === 'dark' ? uploadFiles_button_default_bg_color : ''),
                maxHeight: '40px',
                fontWeight: '600',
                margin: 'auto',
                textAlign: 'center',
                color:theme === 'dark'?uploadFiles_button_text_color_dm:'',
                border:'none',
                // marginTop:'30px'
              }}
              disabled={!isLoggedIn}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <FileUploadOutlinedIcon sx={{width:"18px", height:"18px", marginRight:"8px"}}/>
              Upload Files
            </Button>
            <div style={{display : "flex", justifyContent : 'center'}}>
              <div style={{maxWidth:'208px',height:'',lineHeight:'', marginBottom : '-20px'}}>
                {/* <p style={{fontSize:'13px'}}>Select or drag and drop multiple files here</p> */}
                <p style={{fontSize:'13px'}}>JPG, PNG, PDF, XLSX, MOV, MP3</p>
              </div>
              </div>
    
            <input
              id="file-upload"
              type="file"
              accept=".txt, .docx, .mp3, .pdf, .mp4, .xlsx, video/*, .jpg, .png, .jpeg"
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={handleFileChange}
              multiple
            />
          </Row> :
                <Row style={{ display: "flex", flexDirection: "column",textAlign:'center'}}>
                <div>
                  <img src={Upload_Icon} style={{maxWidth:'44px',maxHeight:'36px',marginBottom:'30px',marginTop:'30px'}}/>
                  {/* <div style={{maxWidth:'208px',height:'64px',lineHeight:'15.73px',}}>
                    <p style={{fontSize:'13px'}}>Select or drag and drop multiple files here</p>
                    <p style={{fontSize:'13px'}}>JPG, PNG, PDF, XLSX, MOV, MP3</p>
                  </div> */}
        
                  <div style={{display : "flex", justifyContent : 'center'}}>
                  <div style={{maxWidth:'208px',height:'64px',lineHeight:'15.73px',}}>
                    <p style={{fontSize:'13px'}}>Select or drag and drop multiple files here</p>
                    <p style={{fontSize:'13px'}}>JPG, PNG, JPEG, PDF, XLSX, MOV, MP3</p>
                  </div>
                  </div>
                  
                </div>
                <Button
                  variant="outline-primary"
                  onClick={handleFileSelect}
                  className="mb-2 d-flex align-items-center justify-content-center" // Use 'justify-content-center' instead of 'justify-content-start'
                  style={{
                    fontSize: '16px',
                    maxWidth: '202px',
                    backgroundColor: isHovered ? uploadFiles_button_hovered_bg_color : (theme === 'dark' ? uploadFiles_button_default_bg_color : ''),
                    maxHeight: '40px',
                    fontWeight: '600',
                    margin: 'auto',
                    textAlign: 'center',
                    color:theme === 'dark'?uploadFiles_button_text_color_dm:'',
                    border:'none',
                    marginTop:'30px'
                  }}
                  disabled={!isLoggedIn}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  Upload Files
                </Button>
        
                <input
                  id="file-upload"
                  type="file"
                  accept=".txt, .docx, .mp3, .pdf, .mp4, .xlsx, video/*, .jpg, .png, .jpeg"
                  style={{ display: "none" }}
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  multiple
                />
              </Row>
       }

      {/* {!threadFiles.some((file) => file.type === "file") ? (
        // <Row className="text-secondary mb-1" style={{ fontSize: '.95rem' }}>
        //   Add text, audio, video, xlsx and pdf files to reference them in your
        //   conversation.
        // </Row>
        <></>
      ) : (
        threadFiles.map((file, index) => (
          <div key={index}>
            {file.type === "file" && (
              <Col>
                <Row className="mb-1 px-0 m-0 align-items-center">
                  <Col
                    xs="10"
                    className={
                      file.status === "upload failed"
                        ? "text-danger p-0 m-0"
                        : "p-0 m-0"
                    }
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      color:file.status === "failed"?"red":''
                    }}
                  >
                    {file.name}
                  </Col>
                  <Col
                    xs="2"
                    className="m-0 p-0"
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                  >
                    {file.status === "uploading" ? (
                      
                      <Spinner animation="border" role="status" size="sm">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    ) : 
                      file.status === "failed" ? (
                      // <i
                      //   className="bi bi-exclamation-circle"
                      //   // onClick={() => handleRemoveFile(index)}
                      //   style={{ cursor: "pointer" }}
                      // />
                        <i class="bi bi-arrow-repeat"
                          onClick={() => handleRetryFile(file)}
                          style={{ cursor: "pointer" }}
                        ></i>
                    ) : (
                      // <i
                      //   className="bi bi-x-lg"
                      //   onClick={() => handleRemoveFile(index)}
                      //   style={{ cursor: "pointer" }}
                      // />
                        // file.status === "failed" && <i class="bi bi-arrow-repeat"
                        //   onClick={() => handleRetryFile(file)}
                        //   style={{ cursor: "pointer" }}
                        // ></i>
                        <></>
                    )}
                  </Col>
                </Row>
                {progressStatusChecking[file.url] && (
                  <ProgressBar animated now={100} label="Processing the file...." />
                )}
              </Col>
            )}
          </div>
        ))
      )}
      {loading && (
        <Row className="mt-3">
          <Spinner animation="border" role="status" size="md">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Row>
      )} */}
      <CommonToast 
        open={fileUploaded}
        onClose={handleCloseToast}
        content={notificationToastMessage}
        bgColor="#282D51"
        fontColor="#fff"
        fontSize="14px"
        fontWeight="500"
        autoHideDuration={3000}
        iconType = {notificationToastMessage === "File uploaded successfully"? 'success' : 'fail'}
      />
    </Container>
  );
};

export default FileUploader;

