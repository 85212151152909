// import React, { useEffect, useState } from 'react';

// const sources = [
//     { id: 1, url: 'https://www.linkedin.com/pulse/4-multimodal-ai-tips-level-up-your-social-media-game-modalx-hhq3e/?trackingId=Mb0DBaf9Rf2kVI7PjwSIKw%3D%3D' },
//     { id: 2, url: 'https://www.linkedin.com/pulse/dynamic-duo-fusion-iot-multimodal-ai-your-smart-future-modalx-agfee/?trackingId=8zKZAmpzRH2psz6OzSKYbg%3D%3D' },
//     { id: 3, url: 'https://www.linkedin.com/pulse/next-gen-olympics-ais-impact-2024-paris-games-modalx-50mff/?trackingId=%2BGqtANMJR063hKEyB2y9jQ%3D%3D' },
//     { id: 4, url: 'https://www.linkedin.com/pulse/multimodal-ai-competitive-advantage-strategies-business-leaders-50xne/?trackingId=qpxmmJEYTzWD%2FoqPPDNbJA%3D%3D' },
//     // { id: 5, url: 'https://www.linkedin.com/pulse/metas-ai-team-drops-sam-2-sequel-thats-slicing-dicing-videos-modalx-weq1e/?trackingId=HJjQf6IZSL%2BenwD0d3WDUQ%3D%3D' },
// ];

// const sources = [
//     { id: 1, url: 'https://www.ancient.eu/egypt/' },
//     { id: 2, url: 'http://www.bbc.co.uk/history/ancient/egyptians/' },
//     { id: 3, url: 'https://www.nationalgeographic.com/culture/topics/reference/ancient-egypt/' },
//     { id: 4, url: 'https://www.smithsonianmag.com/history/ancient-egypt/' },
//     // { id: 5, url: 'https://www.historyextra.com/period/ancient-egypt/' },
// ];

// const RenderSourcesToMessageCard = () => {
//     const [metadata, setMetadata] = useState([]);

//     useEffect(() => {
//         const fetchMetadata = async () => {
//             const metadataArray = await Promise.all(
//                 sources.map(async (source) => {
//                     const response = await fetch(`https://api.microlink.io?url=${source.url}`);
//                     const data = await response.json();
//                     return {
//                         id: source.id,
//                         title: data.data.title,
//                         logo: data.data.logo?.url || data.data.image?.url,
//                         url: source.url
//                     };
//                 })
//             );
//             setMetadata(metadataArray);
//         };

//         fetchMetadata();
//     }, []);

//     return (
//         <div style={{ display: 'flex', flexDirection: 'column', width: '820px', gap: '20px' }}>
//             <h3>Sources</h3>
//             <div style={{ display: 'flex', gap: '20px' }}>
//                 {metadata.map((item) => (
//                     <div key={item.id} style={{ display: 'flex', width: '180px', padding: '12px', flexDirection: 'column', alignItems: 'flex-start', gap: '12px', borderRadius: '8px', backgroundColor: '#1F233E' }}>
//                         {item.logo && <img src={item.logo} alt={`${item.title} logo`} style={{ width: '24px', height: '24px' }} />}
//                         <h4>{item.title}</h4>
//                         <a href={item.url} target="_blank" rel="noopener noreferrer">{item.url}</a>
//                     </div>
//                 ))}
//             </div>
//         </div>
//     );
// };

// export default RenderSourcesToMessageCard;


import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import styled, { keyframes } from 'styled-components';
import { useMediaQuery } from 'react-responsive';

const RenderSourcesToMessageCard = ({ sources }) => {
  const [metadata, setMetadata] = useState([]);
  const [loading, setLoading] = useState(true);
  const isUnder991px = useMediaQuery({ maxWidth:991});

  useEffect(() => {
    const fetchMetadata = async () => {
      const data = await Promise.all(
        sources.map(async (source) => {
          try {
            const response = await fetch(`https://api.microlink.io?url=${source}`);
            const result = await response.json();
            return {
              id: source,
              url: source,
              title: result?.data?.title,
              logo: result?.data?.logo?.url,
              publisher: result?.data?.publisher,
            };
          } catch (err) {
            console.log(err);
          }
        })
      );
      setMetadata(data);

      setLoading(false)
      // setTimeout(() => {
        // setLoading(false)
      // }, 2000)
    };
    fetchMetadata();
  }, [sources]);

  // Additional CSS to hide the scrollbar for Chrome, Safari, and Edge
  const containerStyle = `
.scroll-container::-webkit-scrollbar {
  display: none; // Hide scrollbar for Chrome, Safari, and Edge
}
`;

  // Define the blinking animation
  const blink = keyframes`
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
`;

  // Styled component for the dot
  const Dot = styled.div`
  width: 2px;
  height: 5px;
  border-radius: 50%;
  background-color: #343a40; /* Blue color, you can change it as needed */
  margin: 0 5px;
  animation: ${blink} 1s infinite alternate; /* Apply the blinking animation */
`;

  return (
    <>
      {
        loading ?
          <div>
            <style>{containerStyle}</style>
            <div style={styles.blankcontainer} className="scroll-container">
              <p style={{ fontWeight: '400', fontSize: '16px', color: 'rgba(255,255,255,0.8)', fontFamily: 'Inter', marginLeft:isUnder991px? "10px" : "95px",}}>Sources</p>
              <Box style={styles.blankcardsContainer} sx={{marginLeft:isUnder991px? "10px" : "95px", }}>
                {Array(5)?.fill()?.map((_, index) => (
                  // <Box key={index} sx={{ ...styles.blankloaderBox }} />
                  <Dot sx={{ ...styles.blankloaderBox }} style={{ backgroundColor: '#282D51', width: '192px', height: '122px', borderRadius: '8px' }} />
                ))}
              </Box>
            </div>
          </div>
          :
          <div style={{ display: 'flex', flexDirection: 'column',  width: '100%', maxWidth: '88%', alignItems: 'flex-start', gap: '20px',marginTop: '36px',  marginLeft:isUnder991px? "10px" : "95px",}}>
            <p style={{ fontWeight: '400', fontSize: '16px', color: 'rgba(255,255,255,0.8)', fontFamily: 'Inter', margin: "0px"}}>Sources</p>
            <div style={styles.cardsContainer}>
              {metadata?.map((item) => (
                <div key={item?.id} style={styles.card} onClick={() => window.open(item?.url, '_blank', 'noopener,noreferrer')}>
                  <div style={{ height: '66px', overflow: 'hidden' }}>
                    <p style={{ fontSize: '14px', fontWeight: '400'}}>
                      {item?.title?.length > 50 ? `${item?.title?.substring(0, 40)}...` : item?.title}
                    </p>
                  </div>
                  <div style={{ display: 'flex', gap: '8px'}}>
                    <img src={item?.logo} alt={`${item?.title} logo`} style={styles.logo} />
                    <p style={{ fontSize: '12px', fontWeight: 500, marginTop: '2.5px', marginBottom:"0px" }}>                 
                     {
                      item?.publisher?.split('.')[0].length > 20 ? `${item?.publisher?.split('.')[0].substring(0, 18)}...` : item?.publisher?.split('.')[0]
                     }
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>}
    </>
  );
};

// Styling for the components
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%', // Use 100% to fill the available space
    maxWidth: '88%', // Set the max width of the container
    alignItems: 'flex-start',
    gap: '20px',
    // marginLeft: '108px',
    marginTop: '36px',
  },
  cardsContainer: {
    display: 'flex',
    gap: '20px',
    overflowX: 'auto', // Enable horizontal scrolling
    // padding: '10px 0', // Optional: add some padding
    width: '100%', // Ensure it uses the full width
    scrollbarWidth: 'none', // Hide scrollbar for Firefox
    msOverflowStyle: 'none', // Hide scrollbar for Internet Explorer and Edge
  },
  card: {
    display: 'flex',
    minWidth: '180px', // Set a minimum width for each card
    padding: '12px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '12px',
    borderRadius: '8px',
    backgroundColor: '#1F233E',
    cursor: 'pointer',
    maxWidth: '180px',
  },
  logo: {
    width: '20px',
    height: '20px',
    // marginBottom: '8px',
    borderRadius: '50%', 
    objectFit: 'cover'
  },
  blankcontainer: {
    width: '100%', 
    overflowX: 'auto',
    whiteSpace: 'nowrap', 
    scrollbarWidth: 'none', 
    msOverflowStyle: 'none', 
  },
  blankcardsContainer: {
    display: 'flex', 
    // marginLeft:"100px"
  },
  // blankloaderBox: {
  //   width: '192px',
  //   height: '122px',
  //   backgroundColor: '#282D51',
  //   borderRadius: '8px', 
  //   marginRight: '8px', 
  //   flexShrink: 0, 
  // },
};

// Hide scrollbar in WebKit browsers
const hideScrollbarStyle = `
  .cardsContainer::-webkit-scrollbar {
    display: none; // Hide the scrollbar
  }
`;

// Append the style to the document head
const styleElement = document.createElement('style');
styleElement.textContent = hideScrollbarStyle;
document.head.appendChild(styleElement);

export default RenderSourcesToMessageCard;



