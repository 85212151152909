import React, { useState, useEffect, useRef } from "react";
import { Modal  } from "react-bootstrap";
import { Box, Typography ,CardMedia,Dialog,Button, Snackbar, Alert} from "@mui/material";
import deleteIcon from '../../../assets/DeleteIcon.png'
import shareIcon from '../../../assets/shareIcon.png'
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import sourceUrl from "../../../api/Source.api";

// import sourceUrl from "../../api/Source.api";



const DeleteAndShareModal = ({showModal,setShowModal,item,setDeleteProject, setDeleteProjectData, showModalId, setShowModalId }) => {
  const theme = localStorage.getItem("theme");
  const [link, setLink] = useState("");
  const [toggleLogout , setToggleLogout] = useState(false)
  const publicLink = "https://www.modalx/public_link/thread_122";
  const [copied, setCopied] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [showMoreOptions, setShowMoreOptions] = useState(false); // State to toggle more options
  const dropdownRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [notifMsg, setNotifMsg] = useState(null); 
  const [notifVariant, setNotifVariant] = useState(null);
  const [showNotif, setShowNotif] = useState(false); 
  const token  = localStorage.getItem('token');

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowModalId(null)
      setShowModal(false); // Close the dropdown if clicked outside
      setDeleteProject(false)
      setDeleteProjectData({})
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const closefun = () => {
    setShowModalId(null)
    setToggleLogout(false);
  };

  const showDeleteModal = () =>{
    setShowModal(false)
    setToggleLogout(true)
  }

  const HandleDeleteProjectClick = () =>{
      setDeleteProject(true)
      setDeleteProjectData(item) 
      setToggleLogout(false);
  }

  const HandleShareProjectClick =async () =>{
    const randomToken = uuidv4();
    try{
        const response = await axios.post(`${sourceUrl}/sharechat`, {
        chat_id: item.id,    
        token: randomToken 
      },
      {
        headers: {
          Authorization: `Bearer ${token}` 
        },
      }
    );
      console.log(response.data.data.link)
      if (response.status === 200) {
            const  link  = await response.data.data.link;
            setNotifMsg("Link copied to clipboard!");
            setNotifVariant("success");
            setShowNotif(true);
            setOpen(true);
          //Copy link to clipboard
          navigator.clipboard.writeText(link).then(() => {
          console.log("Link copied");

      // Open the link in a new tab
        window.open(link, "_blank");
        }).catch(err => {
          console.error("Failed to copy: ", err);
        });
      }else{
        throw new Error("Error occurred while sharing the project");
      }
    }catch(e){
      setNotifMsg("Error occurred while sharing the project");
      setNotifVariant("error");
      setShowNotif(true);
      setOpen(true);
    }
    setShowModal(false)
  }

  return (
    <>
  <Box>
          <Box  ref={dropdownRef}
        sx={{
            display: showModal ? 'block' : 'none',
            width: "160px",
            // height: "116px",
            bgcolor: "#282D51",
            borderRadius: "4px",
            // paddingLeft: "4px",
            position: "sticky",
            marginBottom : '-150px',
            // top : '100px',
            // marginTop : '10px',
            marginLeft : '70px',
            zIndex : 100,
            overflow : 'visible',
            padding:"4px 8px"
        }}
      > 
        <Box
        onClick={showDeleteModal}
          sx={{
            width: "100%",
            height: "48px",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            '&:hover': {
              cursor: 'pointer',
              bgcolor:"#2F355F"
            },
          }}
        >
          <Box
          
            sx={{
              width: "16px",
              height: "16px",
            }}
          >
        <CardMedia
        component="img"
        alt="Image description"
        
        image={deleteIcon}
      />
          </Box>

          <Box
            sx={{
              width: "100px",
              height: "20px",
            }}
          >

            <Typography sx={{
                fontFamily : 'Inter',
                fontSize : '14px',
                fontWeight : 400,
                lineHeight : '20px',
                letterSpacing : '0.1px',
                textAlign : 'left',
                color : '#FFFFFF',
                

            }}>Delete project</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: '100%',
            height: "48px",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            '&:hover' :{
              cursor : "pointer",
              bgcolor:"#2F355F"
            }
          }}
        >
          <Box
            sx={{
              width: "16px",
              height: "16px",
            }}
          >
        <CardMedia
        component="img"
        alt="Image description"
        
        image={shareIcon}
      />
          </Box>

          <Box
            sx={{
              width: "100px",
              height: "20px",
            }}
          >

            <Typography onClick={HandleShareProjectClick} sx={{
                fontFamily : 'Inter',
                fontSize : '14px',
                fontWeight : 400,
                lineHeight : '20px',
                letterSpacing : '0.1px',
                textAlign : 'left',
                color : '#FFFFFF',

            }}>Share project</Typography>
          </Box>
        </Box>
      </Box>
      <Dialog
          open={toggleLogout}
          close={closefun}
          PaperProps={{
            sx: {
              width: "100%", // Increase the width as needed
              borderRadius: "8px", // Adjust if you want to change the border radius
            },
          }}
        >
          <Box
            sx={{
              width: "600px",
              height: "200px",
              borderRadius: "8px",
              border: "1px solid rgba(255, 255, 255, 0.2)",
              bgcolor: "#171A2D",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                justifyContent: "center",
                display: "flex",
              }}
            >
              <Box
                sx={{
                  width: "560px",
                  height: "28px",
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "20px",
                    fontWeight: 600,
                    lineHeight: "20px",
                    textAlign: "left",
                    color : '#FFFFFF'
                  }}
                >
                  Delete project?
                </Typography>
                <Box  sx={{
                    color : '#FFFFFF',
                    '&:hover' :{
                      cursor : "pointer"
                    }
                }} onClick={closefun}>
                    <CloseIcon/>
                    </Box>
              </Box>
            </Box>

            <Box
              sx={{
                width: "560px",
                height: "20px",

                display: "flex",
                justifyContent: "center",
                margin: "auto",
                marginTop: "20px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  textAlign: "left",
                  color : '#FFFFFF'
                }}
              >
               You will no longer see this project in the list. This action cannot be undone. Are you sure you want to delete this project?
              </Typography>
            </Box>
            <Box
              sx={{
                marginTop: "20px",
                width: "580px",
                height: "72px",
                display: "flex",
                justifyContent: "right",
              }}
            >
              <Box
                sx={{
                  width: "226px",
                  height: "40px",
                  marginRight: "16px",
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  onClick={closefun}
                  sx={{
                    width: "103px",
                    height: "40px",
                    textTransform: "none",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: 600,
                    textAlign: "center",
                    bgcolor: "#FFFFFF",
                    color: "#17182C",
                    '&:hover': {
                        backgroundColor: '#CDCFDF'

                      },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={HandleDeleteProjectClick}
                  sx={{
                    width: "103px",
                    height: "40px",
                    textTransform: "none",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: 600,
                    textAlign: "center",
                    bgcolor: "#DD2025",
                    color: "#FFFFFF",
                    '&:hover': {
                        backgroundColor: '#E6E0E91F'

                      },
                  }}
                >
                  Delete
                </Button>
              </Box>
            </Box>
          </Box>
        </Dialog>
  </Box>
  <CustomizedSnackbars
  open={open}
  setOpen={setOpen}
  notifMsg={notifMsg}
  notifVariant={notifVariant}
  setShowNotif={setShowNotif}
/>
  </>
  );
};

export default DeleteAndShareModal;

export function CustomizedSnackbars({
  open,
  setOpen,
  notifMsg,
  setShowNotif,
  notifVariant,
}) {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <div style={{ position: "relative", zIndex: 60 }}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
        <Alert
          onClose={handleClose}
          severity={notifVariant}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {notifMsg}
        </Alert>
      </Snackbar>
    </div>
  );
}
