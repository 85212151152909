// import React,{useState,useEffect} from "react";
// import { Row } from "react-bootstrap";
// import MessageWindow from "../components/workspace/message/MessageWindow"; // Import the MessageWindow component
// import Sidebar from "../components/workspace/sideBar/Sidebar";
// import SettingBar from "../components/workspace/settingBar/SettingBar";
// import { Navigate, useNavigate } from "react-router-dom";
// import Messages from "../components/workspace/message/Messages";
// import { useWorkspace } from "../contexts/WorkspaceContext";
// import CreditRechargeModal from "../components/CreditRechargeModal";
// import VideoPreview from "../assets/ModalX_Video_Preview.mp4";
// import VideoModal from "../components/VideoModal";
// import * as Sentry from "@sentry/react";

// const Workspace = () => {
//   const isLoggedIn = localStorage.getItem('isLoggedIn');
//   const [showVideoModal, setShowVideoModal] = useState(false);
//   const navigate = useNavigate();
//   const {threadId,currentThread,currentHistoryId,showFirstVisitModal,setShowFirstVisitModal} = useWorkspace();
//   window.addEventListener('beforeunload', (event) => {
//     // Check the length of the current thread
//     if (currentThread.length > 0) {
//         // Perform necessary actions before refresh
//         // For example, saving the current thread to local storage or making an API call
//         localStorage.setItem('current_thread_id', threadId);
//         localStorage.setItem('current_history_id',currentHistoryId);
//         window.location.reload();
//     }
//     // else{
//     //     localStorage.setItem('current_thread_id','');
//     //     window.location.reload();
//     // }
// });

// useEffect(() => {
//   if (!localStorage.getItem('threadId')) {
//     setShowVideoModal(true);
//   }
// }, [])


  
//   return (
//     // isLoggedIn ?
//     // <Row
//     //   style={{
//     //     height: "100vh",
//     //     maxWidth: "100vw",
//     //   }}
//     //   className="m-0"
//     // >
//     //   <Sidebar />
//     //   <MessageWindow />
//     //   <SettingBar />
//     // </Row>
//     // :
//     // <Navigate to={"/home"} /> 
//     isLoggedIn? 
//     <div style={{display:'flex',width:'100vw',height:'100vh'}}>
//       <Sidebar />
//       <MessageWindow />
//       <Sentry.ErrorBoundary fallback={<div>Something went wrong in the workspace</div>}>
//         <SettingBar />
//       </Sentry.ErrorBoundary>
      
//       <CreditRechargeModal />
//       {showFirstVisitModal &&
//       <VideoModal 
//       show={showVideoModal} 
//       handleClose={() => setShowVideoModal(false)} 
//       videoSrc={VideoPreview} 
//     />
//       }
//     </div> :''
//   );
// };

// export default Workspace;

// ====================== above one is the latest code on 08/07/2022 ====================

// import React, { useState, useEffect } from "react";
// import { Row } from "react-bootstrap";
// import MessageWindow from "../components/workspace/message/MessageWindow";
// import Sidebar from "../components/workspace/sideBar/Sidebar";
// import SettingBar from "../components/workspace/settingBar/SettingBar";
// import { Navigate, useNavigate } from "react-router-dom";
// import { useWorkspace } from "../contexts/WorkspaceContext";
// import CreditRechargeModal from "../components/CreditRechargeModal";
// import VideoPreview from "../assets/ModalX_Video_Preview.mp4";
// import VideoModal from "../components/VideoModal";
// import * as Sentry from "@sentry/react";

// const Workspace = () => {
//   const isLoggedIn = localStorage.getItem('isLoggedIn');
//   const [showVideoModal, setShowVideoModal] = useState(false);
//   const navigate = useNavigate();
//   const { threadId, currentThread, currentHistoryId, showFirstVisitModal, setShowFirstVisitModal } = useWorkspace();

//   useEffect(() => {
//     if (!localStorage.getItem('threadId')) {
//       setShowVideoModal(true);
//     }

//     const handleBeforeUnload = (event) => {
//       if (currentThread.length > 0) {
//         localStorage.setItem('current_thread_id', threadId);
//         localStorage.setItem('current_history_id', currentHistoryId);
//       }
//     };

//     window.addEventListener('beforeunload', handleBeforeUnload);

//     return () => {
//       window.removeEventListener('beforeunload', handleBeforeUnload);
//     };
//   }, [currentThread, threadId, currentHistoryId]);

//   return (
//     isLoggedIn ? 
//     <div style={{ display: 'flex', width: '100vw', height: '100vh'}}>
//       <Sidebar />
//       <MessageWindow />
//       <Sentry.ErrorBoundary fallback={<div>Something went wrong in the workspace</div>}>
//         <SettingBar />
//       </Sentry.ErrorBoundary>
//       <CreditRechargeModal />
//       {showFirstVisitModal &&
//         <VideoModal 
//           show={showVideoModal} 
//           handleClose={() => setShowVideoModal(false)} 
//           videoSrc={VideoPreview} 
//         />
//       }
//     </div> 
//     : 
//     <Navigate to={"/"} />
//   );
// };

// export default Workspace;

// ====================== above one is the latest code on 14/08/2022 ====================

// import React, { useState, useEffect } from "react";
// import MessageWindow from "../components/workspace/message/MessageWindow";
// import Sidebar from "../components/workspace/sideBar/Sidebar";
// import SettingBar from "../components/workspace/settingBar/SettingBar";
// import { Navigate } from "react-router-dom";
// import { useWorkspace } from "../contexts/WorkspaceContext";
// import CreditRechargeModal from "../components/CreditRechargeModal";
// import VideoPreview from "../assets/ModalX_Video_Preview.mp4";
// import VideoModal from "../components/VideoModal";
// import * as Sentry from "@sentry/react";

// const Workspace = () => {
//   const isLoggedIn = localStorage.getItem('isLoggedIn');
//   const [showVideoModal, setShowVideoModal] = useState(false);
//   const { threadId, currentThread, currentHistoryId, showFirstVisitModal, setShowFirstVisitModal } = useWorkspace();

//   useEffect(() => {
//     if (!localStorage.getItem('threadId')) {
//       setShowVideoModal(true);
//     }

//     const handleBeforeUnload = (event) => {
//       if (currentThread.length > 0) {
//         localStorage.setItem('current_thread_id', threadId);
//         localStorage.setItem('current_history_id', currentHistoryId);
//       }
//     };

//     window.addEventListener('beforeunload', handleBeforeUnload);

//     return () => {
//       window.removeEventListener('beforeunload', handleBeforeUnload);
//     };
//   }, [currentThread, threadId, currentHistoryId]);

//   return (
//     isLoggedIn ? 
//     <div style={{ display: 'flex', width: '100vw', height: '100vh', overflow: 'hidden' }}>
//       <Sidebar />
//       <div style={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
//         <MessageWindow />
//         <CreditRechargeModal />
//         {showFirstVisitModal && (
//           <VideoModal 
//             show={showVideoModal} 
//             handleClose={() => setShowVideoModal(false)} 
//             videoSrc={VideoPreview} 
//           />
//         )}
//       </div>
//       <Sentry.ErrorBoundary fallback={<div>Something went wrong in the workspace</div>}>
//         <SettingBar />
//       </Sentry.ErrorBoundary>
//     </div> 
//     : 
//     <Navigate to={"/"} />
//   );
// };

// export default Workspace;

// ====================== above one is the latest code on 27/08/2022 ====================


import React, { useState, useEffect } from "react";
import MessageWindow from "../components/workspace/message/MessageWindow";
import Sidebar from "../components/workspace/sideBar/Sidebar";
import SettingBar from "../components/workspace/settingBar/SettingBar";
import { Navigate, useNavigate } from "react-router-dom";
import { useWorkspace } from "../contexts/WorkspaceContext";
import CreditRechargeModal from "../components/CreditRechargeModal";
import VideoPreview from "../assets/ModalX_Video_Preview.mp4";
import VideoModal from "../components/VideoModal";
import axios from "axios";
import sourceUrl from "../api/Source.api";
import * as Sentry from "@sentry/react";
import { useAuth } from "../contexts/AuthContext";
import { Box, IconButton, Typography, Button } from "@mui/material";
import LogOut from "./LogOut";
import AllDelete from "./AllDelete";
import ShareModalForSocialMedia from "../components/workspace/message/ShareModalForSocialMedia";
import Default_WebTrackScreen from "../components/workspace/sideBar/Track_Website/Default_WebTrackScreen";
import WebTrackHome from "../components/workspace/sideBar/Track_Website/WebTrackHome";
import CommonToast from "../components/Common_Components/CommonToast";
import ImageSettings from "../components/workspace/settingBar/ImageSettings/ImageSettings";
import PptTemplates from "../components/workspace/settingBar/PptTemplates";
import AudioSettings from "../components/workspace/settingBar/AudioSettings/AudioSettings";
import VideoSettings from "../components/workspace/settingBar/VideoSettings";

const Workspace = () => {
  const isLoggedIn = localStorage.getItem('isLoggedIn');
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [aftervideomodal, setAftervideomodal] = useState(false);

  const { 
    threadId, 
    currentThread, 
    currentHistoryId, 
    showFirstVisitModal, 
    setShowFirstVisitModal,
    Today, 
    Yesterday, 
    ThisWeek, 
    ThisMonth, 
    PrevMonth,
    setCurrentThread,
    sendMessage,
    setWaitThread,
    setSending,
    freshUser,
    setFreshUser,
    iswebTrackClicked
  } = useWorkspace();

  useEffect(() => {
    if (!localStorage.getItem('threadId')) {
      setShowVideoModal(true);
    }

    const handleBeforeUnload = (event) => {
      if (currentThread.length > 0) {
        localStorage.setItem('current_thread_id', threadId);
        localStorage.setItem('current_history_id', currentHistoryId);
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [currentThread, threadId, currentHistoryId]);

  const extractNameFromEmail = (email) => {
    const namePart = email.split('@')[0]; // Get the part before '@'
    const nameParts = namePart.split(/[._]/); // Split by '.' or '_'

    // Capitalize first letter of each part
    const capitalizedParts = nameParts.map(part => part.charAt(0).toUpperCase() + part.slice(1));

    // Join the parts with a space (if there's more than one part)
    return capitalizedParts.join(' ');
  };

  // useEffect(() => {
  //   const userEmail = localStorage.getItem("userEmail");
    
  //   if (
  //     !showFirstVisitModal && 
  //     !Today.length && 
  //     !Yesterday.length && 
  //     !ThisWeek.length && 
  //     !ThisMonth.length && 
  //     !PrevMonth.length &&
  //     userEmail
  //   ) {
  //     const userName = extractNameFromEmail(userEmail);
  //     const firstTimeVisit = localStorage.getItem("hasVisitedWorkspace");
  //     const token = localStorage.getItem('token');
  //     const prompt = `Extract the company name and role of the person from deepak@pariksha.co and find details about the company.`;
  //     // const prompt = `Hi ${extractNameFromEmail(userEmail)}, welcome to ModalX!. Please give me a moment while I set things up for you.`
  //     const response = axios.get(`${sourceUrl}/user/onboardingStatus`, {
  //       headers: {
  //         'Authorization': `Bearer ${token}`,
  //         'Content-Type': 'application/json',
  //         'User-Agent': 'Mozilla/5.0',
  //       },
  //     });

  //     const { status } = response.data?.data || {};

  //     // Update modal and hasVisitedWorkspace based on status
  //     if (!status && !firstTimeVisit) {
  //       sendInitialMessage(prompt); // If status is true, show video settings modal
  //     }
  //     // if(!firstTimeVisit){
  //     //   sendInitialMessage(prompt);
  //     // }
  //   }
  // }, [showFirstVisitModal]); 
    const fetchOnboardingStatus = async () => {
      try {
        const userEmail = localStorage.getItem("userEmail");
  
        if (
          !showFirstVisitModal && 
          !Today.length && 
          !Yesterday.length && 
          !ThisWeek.length && 
          !ThisMonth.length && 
          !PrevMonth.length &&
          userEmail
        ) {
          const userName = extractNameFromEmail(userEmail);
          const firstTimeVisit = localStorage.getItem("hasVisitedWorkspace");
          const token = localStorage.getItem('token');
          // const prompt = `Extract the company name and role of the person from deepak@pariksha.io and find details about the company.`;
          const prompt = `Hi ${extractNameFromEmail(userEmail)}, welcome to ModalX!. Please give me a moment while I set things up for you.`;
          // const prompt = `Write a response that: 1. Extracts the company name and person’s name from the email address vishnurajkarockal@gmail.com. 2. Provides a brief summary of the company found through a web search, including it’s main activities, location, and any notable achievements or news. 3. Searches for information about the person associated with the email address, including their professional background, roles, and accomplishments. Please provide the extracted company and person names, followed by a web search summary article that includes the requested information.`
  
          // Make the API call asynchronous
          const response = await axios.get(`${sourceUrl}/user/onboardingStatus`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
              // 'User-Agent': 'Mozilla/5.0',
            },
          });
  
          // Safely access the response data using optional chaining
          const { status } = response.data?.data || {};
  
          // Now invoke sendInitialMessage only if status is false and it's the user's first visit
          if (!status) {
            await sendInitialMessage(prompt);  // Ensure sendInitialMessage is awaited
          }
        }
      } catch (error) {
        console.error("Error fetching onboarding status:", error);
      }
    };

  const sendInitialMessage = async (prompt) => {
    setWaitThread(true);
    setSending(true);
    try {
      const latestEntry = { sender: "user", content: prompt };
      setCurrentThread((prevThread) => [...prevThread, latestEntry]);

      const entryResponse = await sendMessage(latestEntry);
      if (entryResponse) {
        setCurrentThread((prevThread) => [...prevThread, entryResponse]);
      } else {
        setCurrentThread((prevThread) => [
          ...prevThread, 
          { content: "Unable to process your query!", sender: "modalX" }
        ]);
      }
    } catch (error) {
      console.error("Error sending initial message:", error);
    } finally {
      setWaitThread(false);
      setSending(false);
    }
  };

  if(freshUser==='loading'){
    return <div style={{height:"100vh", width:"100vw"}}></div>
  }

  return (
    isLoggedIn ? 
    <div style={{ display: 'flex', width: '100vw', height: '100vh', overflow: 'hidden' }}>
      <Sidebar />
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        {/* {
          iswebTrackClicked?
          <Default_WebTrackScreen /> :
          <MessageWindow />
        } */}
        {
          iswebTrackClicked?
          <WebTrackHome /> :
          <MessageWindow />
        }
        <CreditRechargeModal />
        <AllDelete/>
        <LogOut/>
        <CommonToast />
          <ImageSettings />
          <PptTemplates />
          <AudioSettings />
          <VideoSettings />
        {/* <ShareModalForSocialMedia/> */}
        {freshUser && (
          <VideoModal 
            show={freshUser} 
            handleClose={() =>{ setFreshUser(false)
                                fetchOnboardingStatus();
            }} 
            videoSrc={VideoPreview} 
          />
        )}
      </div>
      <Sentry.ErrorBoundary fallback={<div>Something went wrong in the workspace</div>}>
        <SettingBar />
      </Sentry.ErrorBoundary>
    </div> 
    : <Navigate to={"/"} /> 
  );
};

export default Workspace;




// import { useEffect } from "react";
// import { useAuth } from "../contexts/AuthContext";
// import { useNavigate } from "react-router-dom";

// const navigate = useNavigate();
// const { user } = useAuth();
// console.log(user);
// console.log(user.firstName);

// useEffect(() => {
//   if (user && !user.firstName) {
//     navigate("/userJourney");
//   }
// }, [user, navigate]);
