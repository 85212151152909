// import React, { useEffect, useRef, useState } from "react";
// import { Image } from "react-bootstrap";
// import ReactPlayer from "react-player";
// import styled from "styled-components";

// import { IconBase } from "react-icons";
// import { BsCopy } from "react-icons/bs";

// import {
//   FacebookShareButton,
//   FacebookIcon,
//   TwitterShareButton,
//   TwitterIcon,
//   WhatsappShareButton,
//   WhatsappIcon,
//   EmailShareButton,
//   EmailIcon,
// } from "react-share";

// const CopyToClipboardButton = ({ url }) => {
//   const handleCopy = () => {
//     navigator.clipboard.writeText(url).then(() => {
//       alert("URL copied to clipboard!");
//     });
//   };

//   return (
//     <IconBase
//       as="button"
//       onClick={handleCopy}
//       style={{
//         cursor: "pointer",
//         display: "inline-flex",
//         alignItems: "center",
//         justifyContent: "center",
//         borderRadius: "50%",
//         width: "32px",
//         height: "32px",
//         backgroundColor: "#007bff",
//         color: "#fff",
//         border: "none",
//         padding: "6px",
//         outline: "none",
//       }}
//     >
//       <BsCopy size={15} />
//     </IconBase>
//   );
// };

// const ResponsiveDocumentViewerContainer = styled.div`
//   position: relative;
//   width: 100%;
//   &:hover .download-button,
//   &:hover .share-button {
//     display: flex;
//   }
// `;

// const ResponsiveDocumentViewer = styled.iframe`
//   width: 100%;
//   height: 130%;
//   border: none;
// `;

// const ImageContainer = styled.div`
//   position: relative;
//   display: inline-block;
//   width: 100%;

//   &:hover .download-button,
//   &:hover .share-button {
//     display: flex;
//   }
// `;

// const ActionButton = styled.a`
//   position: absolute;
//   top: 10px;
//   background-color: transparent;
//   border: none;
//   color: white;
//   font-size: 20px;
//   display: none;
//   transition: transform 0.3s ease;

//   &:hover {
//     transform: scale(1.1);
//     background-color: transparent;
//     color: blue;
//     background-color:grey ;
    
//   }
// `;

// const DownloadButton = styled(ActionButton)`
//   right: 5%;
//   background-color:grey ;
//   padding:2px 8px;
//   border-radius:50%
// `;

// const ShareButton = styled.div`
//   position: absolute;
//   top: 10px;
//   left: 10px;
//   z-index: 10;
//   display: flex;
//   gap: 5px;
//   display: none;
// `;


// const PreviewMedia = ({ url }) => {
//   const shareUrl = url;

//   const videoTest = /\.(mp4)$/i;
//   const audioTest = /\.(mp3)$/i;
//   const pptTest = /\.(ppt|pptx)$/i;
//   const imageTest = /\.(jpg|jpeg|png)$/i;

//   switch (true) {
//     case videoTest.test(url):
//       return (
//         <ReactPlayer
//           url={url}
//           controls
//           width="100%"
//           style={{ margin: "10px 0" }}
//         />
//       );
//     case audioTest.test(url):
//       return (
//         <audio controls src={url} style={{ maxWidth: "100%", width: "100%" }} />
//       );
//     case pptTest.test(url):
//       return (
//         <ResponsiveDocumentViewerContainer>
//           <ResponsiveDocumentViewer
//             src={`https://view.officeapps.live.com/op/embed.aspx?src=${url}`}
//             allow="autoplay"
//           />
//           <DownloadButton
//             href={url}
//             download
//             target="_blank"
//             rel="noopener noreferrer"
//             className="download-button"
//           >
//             <i class="bi bi-cloud-arrow-down"></i>
//           </DownloadButton>
//           {/* <ShareButton className="share-button">
//             <FacebookShareButton url={shareUrl}>
//               <FacebookIcon size={32} round />
//             </FacebookShareButton>
//             <TwitterShareButton url={shareUrl}>
//               <TwitterIcon size={32} round />
//             </TwitterShareButton>
//             <WhatsappShareButton url={shareUrl}>
//               <WhatsappIcon size={32} round />
//             </WhatsappShareButton>
//             <EmailShareButton url={shareUrl}>
//               <EmailIcon size={32} round />
//             </EmailShareButton>
//             <CopyToClipboardButton url={shareUrl} />
//           </ShareButton> */}
//         </ResponsiveDocumentViewerContainer>
//       );
//     case imageTest.test(url):
//       return (
//         <ImageContainer>
//           <Image src={url} alt="Image Preview" fluid />
//           <DownloadButton
//             href={url}
//             download
//             target="_blank"
//             rel="noopener noreferrer"
//             className="download-button"
//           >
//             <i class="bi bi-cloud-arrow-down"></i>
//           </DownloadButton>
//           {/* <ShareButton className="share-button">
//             <FacebookShareButton url={shareUrl}>
//               <FacebookIcon size={32} round />
//             </FacebookShareButton>
//             <TwitterShareButton url={shareUrl}>
//               <TwitterIcon size={32} round />
//             </TwitterShareButton>
//             <WhatsappShareButton url={shareUrl}>
//               <WhatsappIcon size={32} round />
//             </WhatsappShareButton>
//             <EmailShareButton url={shareUrl}>
//               <EmailIcon size={32} round />
//             </EmailShareButton>
//             <CopyToClipboardButton url={shareUrl} />
//           </ShareButton> */}
//         </ImageContainer>
//       );
//     default:
//       return null;
//   }
// };

// export default PreviewMedia;


import React, { useState } from "react";
import { Box, CircularProgress, useMediaQuery } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Button, Image, Modal } from "react-bootstrap";
import ReactPlayer from "react-player";
import styled from "styled-components";
import { IconBase } from "react-icons";
import { BsCopy } from "react-icons/bs";
import ReactAudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import download from "downloadjs";
import { saveAs } from 'file-saver';

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";
import { transform } from "lodash";

// Styled-components for custom audio player styles
const CustomAudioPlayer = styled(ReactAudioPlayer)`
  .rhap_container {
    background-color: transparent !important;
    border-radius: 10px;
  }

  .rhap_progress-bar {
    background: #626473;
    border-radius: 10px;
  }

  .rhap_progress-indicator {
    background: #FFFFFF;
    border-radius: 50%;
  }

  .rhap_progress-filled, .rhap_volume-filled {
    background: #FFFFFF !important;
    border-radius: 10px;
  }

  .rhap_time {
    color: #007bff;
  }

  .rhap_controls-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .rhap_repeat-button {
    display: none !important;
  }
  .rhap_progress-section{
      margin:0% 10%
  }
  .rhap_download-progress{
    background-color:#626473
  }
.rhap_main-controls-button, .rhap_volume-button,  .rhap_time{
  color:#FFFFFF
}
.rhap_volume-indicator{
  background-color:#FFFFFF
  }
.rhap_volume-bar{
background:#626473
}
.rhap_volume-bar{
background-color:#FFFFFF
}
`;

const CopyToClipboardButton = ({ url }) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(url).then(() => {
      alert("URL copied to clipboard!");
    });
  };

  return (
    <IconBase
      as="button"
      onClick={handleCopy}
      style={{
        cursor: "pointer",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        width: "32px",
        height: "32px",
        backgroundColor: "#007bff",
        color: "#fff",
        border: "none",
        padding: "6px",
        outline: "none",
      }}
    >
      <BsCopy size={15} />
    </IconBase>
  );
};

const AudioContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  &:hover .download-button,
  &:hover .large-preview-button,
  &:hover .share-button {
    display: flex;
  }
`;

const ResponsiveDocumentViewerContainer = styled.div`
  position: relative;
  width: 100%;
  &:hover .download-button,
  &:hover .large-preview-button,
  &:hover .share-button {
    display: flex;
  }
`;

const ResponsiveDocumentViewer = styled.iframe`
  width: 100%;
  height: ${({ height }) => height || '100%'};
  border: none;
  
`;

const ImageContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 350px;
  border-radius: 4px; /* Add border-radius */
  &:hover .download-button,
  &:hover .large-preview-button,
  &:hover .share-button {
    display: flex;
  }
`;

const ActionButton = styled.button`
  position: absolute;
  top: 10px;
  background-color: transparent;
  border: none;
  color: white;
  font-size: 20px;
  display: none;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
    color: blue;
    background-color: grey;
  }
`;

const DownloadButton = styled.a`
  position: absolute;
  top: 10px;
  right: 3%;
  background-color: grey;
  padding: 2px 8px;
  border-radius: 50%;
  display: none;
  text-decoration: none;
  color: white;
  font-size: 20px;

  &:hover {
    transform: scale(1.1);
    color: blue;
    background-color: grey;
  }
`;

const DownloadButtonInsideModal = styled.a`
  top: 15px;
  right: 7%;
  background-color: blue;
  padding: 2px 8px;
  border-radius: 10px;
  text-decoration: none;
  color: white;
  font-size: 20px;

  &:hover {
    transform: scale(1.05);
    color: white;
    background-color: #3232ff;
  }
`;

const LargePreviewButton = styled(ActionButton)`
  right: 15%;
  background-color: grey;
  padding: 6px 10px;
  border-radius: 50%;
  font-size: 14px;
`;

const ShareButton = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 10;
  display: flex;
  gap: 5px;
  display: none;
`;

const PreviewMedia = ({ url }) => {
  console.log("Inside previewmedia.jsx",url)
  const [showModal, setShowModal] = useState(false);
  const [videoDownload, setVideoDownload] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const mobileView = useMediaQuery("(max-width:501px)");
  const handleShowModal = () => setShowModal(true);    
  const handleCloseModal = () => setShowModal(false);
  const shareUrl = url;

  if (url?.includes("dailylimitexceeded")) {
    return null;
  }
  

  const handleImageDownload = async (url) => {
    try {
      // Fetch the image data as a Blob
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const blob = await response.blob();
  
      // Create a link element and trigger the download
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = url.split('/').pop(); // Extract filename from URL
      downloadLink.click();
  
      // Revoke the object URL to free memory
      URL.revokeObjectURL(downloadLink.href);
    } catch (error) {
      console.error("Error downloading the image:", error);
    }
  };
  

  // const handleImageDownload = async (url) => {
  //   try {
  //     const response = await fetch(url, { mode: 'no-cors' });
  //     const blob = await response.blob();
  //     download(blob, "image.jpg");
  //   } catch (error) {
  //     console.error("Error downloading the image:", error);
  //   }
  // };
  


  // const handleImageDownload = async (url) => {
  //   const corsProxy = "https://cors-anywhere.herokuapp.com/";
  //   try {
  //     const response = await fetch(corsProxy + url);
  //     const blob = await response.blob();
  //     download(blob, "image.jpeg");
  //   } catch (error) {
  //     console.error("Error downloading the image:", error);
  //   }
  // };

  // const handleImageDownload = (url) => {
  //   const link = document.createElement('a');
  //   link.href = url;
  //   link.setAttribute('download', 'image.jpeg'); // specify desired filename
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  const handlePdfDownload = (url) => {
    fetch(url)
      .then(response => response.blob()) // Get the file as a blob
      .then(blob => {
        const fileName = url.split('/').pop(); // Extract filename from the URL
        saveAs(blob, fileName); // Trigger the download
      })
      .catch(error => console.error('Download failed:', error));
  };

  const handleVideoDownload = async (url) => {
    setVideoDownload(true)
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const blob = await response.blob();

      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = url.split('/').pop();
      downloadLink.click();

      URL.revokeObjectURL(downloadLink.href);
    } catch (error) {
      console.error('Error downloading the video:', error);
    }
    setVideoDownload(false)
  };
  

  const videoTest = /\.(mp4)$/i;
  const audioTest = /\.(mp3)$/i;
  const pptTest = /\.(ppt|pptx)$/i;
  const imageTest = /\.(jpg|jpeg|png)$/i;
  const pdfTest = /\.(pdf)$/i;

  switch (true) {
    case videoTest.test(url):
      return (
        <div
      style={{
        overflow: "hidden", 
        borderRadius: "8px", 
        width: "100%", 
        height:"100%",
        position: 'relative',
      }}
    >
        <ReactPlayer
          url={url}
          controls
          width= "100%"
          height="100%"
          style={{ borderRadius:"8px"}}
        />
        <button
         onMouseEnter={() => setIsHovered(true)}
         onMouseLeave={() => setIsHovered(false)} 
        onClick={() => handleVideoDownload(url)}
        style={{
          position: 'absolute',
          top:"10px",
          right: '3%',
          backgroundColor: 'grey',
          borderRadius:"50%",
          color: isHovered ? "blue" : 'white',
          fontFamily: 'Inter',
          fontWeight: 600,
          fontSize: '20px',
          padding: '2px 8px',
          cursor: 'pointer',
          border:"none"
        }}
        title="Download Video"
      >
        {videoDownload ?<div style={{display:"flex", justifyContent:"center", alignItems:"center", padding:"6px 0px"}}><CircularProgress color="inherit" size="20px"/></div>: <i className="bi bi-cloud-arrow-down"></i>}
      </button>
        </div>
      );
    case audioTest.test(url):
      return (
        <AudioContainer>
          <CustomAudioPlayer
            
            src={url}
            autoPlay={false}
            controls
            style={{ maxWidth: "100%", width: "100%", backgroundColor: 'transparent', borderRadius: '10px',paddingTop:'45px',border:"0.5px solid #66697A"}}
          />
          <DownloadButton
            href={url}
            download
            target="_blank"
            rel="noopener noreferrer"
            className="download-button"
            title="Download"
          >
            <i className="bi bi-cloud-arrow-down"></i>
          </DownloadButton>
          <LargePreviewButton
            as="button"
            onClick={handleShowModal}
            className="large-preview-button"
            title="View"
          >
            <i className="bi bi-arrows-fullscreen"></i>
          </LargePreviewButton>
          <Modal show={showModal} onHide={handleCloseModal} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Audio Preview</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CustomAudioPlayer
                src={url}
                autoPlay={false}
                controls
                style={{ maxWidth: "90%", width: "100%",backgroundColor: 'transparent',borderRadius:'20px'}}
              />
            </Modal.Body>
            <Modal.Footer>
              <DownloadButtonInsideModal
                  href={url}
                  download
                  target="_blank"
                  rel="noopener noreferrer"
                  className="download-button"
                  title="Download"
                  style={{display:'flex',alignItems:'center'}}
                >
                  <i className="bi bi-cloud-arrow-down"></i><span style={{fontSize:'1rem',marginLeft:'10px'}}> Download</span>
              </DownloadButtonInsideModal>
            </Modal.Footer>
          </Modal>
          {/* ShareButton and other buttons */}
        </AudioContainer>
      );
    case pptTest.test(url):
      return (
        <ResponsiveDocumentViewerContainer>
          <ResponsiveDocumentViewer
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${url}`}
            allow="autoplay"
            borderRadius="8px"
          />
          <DownloadButton
            href={url}
            download
            target="_blank"
            rel="noopener noreferrer"
            className="download-button"
            title="Download"
          >
            <i className="bi bi-cloud-arrow-down"></i>
          </DownloadButton>
          <LargePreviewButton
            as="button"
            onClick={handleShowModal}
            className="large-preview-button"
            title="View"
          >
            <i className="bi bi-arrows-fullscreen"></i>
          </LargePreviewButton>
          {/* <div style={{
              width: '80px',
              height: '15%',
              backgroundColor: '#444444',
              position: 'absolute',
              bottom: '-30%',
              right: '0',
            }}> */}
              {/* This i have used to hide the extra options to right bottom of iframe controls */}
          {/* </div> */}
          <Modal show={showModal} onHide={handleCloseModal} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>PPT Preview</Modal.Title>
              
            </Modal.Header>
            <Modal.Body>
              <ResponsiveDocumentViewer
                src={`https://view.officeapps.live.com/op/embed.aspx?src=${url}`}
                allow="autoplay"
                height="500px"
                borderRadius="8px"
              />
              <div style={{
                  width: '100px',
                  height: '4%',
                  backgroundColor: '#444444',
                  position: 'absolute',
                  bottom: '4.1%',
                  right: '3%',
                  overflow:'hidden'
                }}>
                  {/* This i have used to hide the extra options to right bottom of iframe controls */}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <DownloadButtonInsideModal
                  href={url}
                  download
                  target="_blank"
                  rel="noopener noreferrer"
                  className="download-button"
                  title="Download"
                  style={{display:'flex',alignItems:'center'}}
                >
                  <i className="bi bi-cloud-arrow-down"></i><span style={{fontSize:'1rem',marginLeft:'10px'}}> Download</span>
              </DownloadButtonInsideModal>
            </Modal.Footer>
          </Modal>
          {/* <ShareButton className="share-button">
            <FacebookShareButton url={shareUrl}>
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <TwitterShareButton url={shareUrl}>
              <TwitterIcon size={32} round />
            </TwitterShareButton>
            <WhatsappShareButton url={shareUrl}>
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
            <EmailShareButton url={shareUrl}>
              <EmailIcon size={32} round />
            </EmailShareButton>
            <CopyToClipboardButton url={shareUrl} />
          </ShareButton> */}
        </ResponsiveDocumentViewerContainer>
      );
    case imageTest.test(url):
      return (  
        <ImageContainer>
          <Image src={url} alt="Image Preview" fluid  style={{width:"auto"}}/>
          <DownloadButton
            // as="button"
            download
            onClick={() => handleImageDownload(url)}
            className="download-button"
            title="Download"
            style={{ cursor: 'pointer' }} 
          >
            <i className="bi bi-cloud-arrow-down"></i>
          </DownloadButton>
          <LargePreviewButton
            as="button"
            onClick={handleShowModal}
            className="large-preview-button"
            title="View"
          >
            <i className="bi bi-arrows-fullscreen"></i>
          </LargePreviewButton>
          {mobileView ?  
            <Modal 
              show={showModal} 
              onHide={handleCloseModal}  
              style={{
                backgroundColor: '#171A2D',
                width: '100%',
                border: 'none',

              }} 
              size="lg"
              > 
              <Modal.Body style={{ 
                  backgroundColor: '#171A2D', 
                  padding: 0,  // Remove padding around the body
                  margin: -8,    // Ensure no margin between Modal and Modal.Body
                  height : 160,
                  display : "flex",
                  justifyContent : "space-between",
                  alignItems : 'center'
                }}
              >
                <Box
                onClick={handleCloseModal}
                  sx={{
                  width : '40px',
                  height : '40px',
                  display : "flex",
                  justifyContent : "center",
                  alignItems : 'center',      
                  marginLeft : '20px',
                }}><CloseIcon/>

                </Box>

                <Box
                onClick={() =>handleImageDownload(url)}
                  sx={{
                  width : '40px',
                  height : '40px',
                  bgcolor : '#1F233E',
                  marginRight : '20px',
                  display : "flex",
                  justifyContent : "center",
                  alignItems : 'center',
                  borderRadius : '50%'
                }}><SaveAltIcon/>

                </Box>
                </Modal.Body>
              <Modal.Body style={{ 
                  backgroundColor: '#171A2D', 
                  padding: 0,  // Remove padding around the body
                  margin: -8    // Ensure no margin between Modal and Modal.Body
                }}
              >
                <Image 
                  src={url} 
                  style={{
                    // borderRadius: "8px",
                    width: '100%',   // Ensure the image takes up 100% width of its container
                    height: 'auto'   // Maintain aspect ratio of the image
                  }} 
                  alt="Image Preview" 
                  fluid
                />
              </Modal.Body>
           </Modal>


                 :  <Modal show={showModal} onHide={handleCloseModal} style={{backgroundColor :''}}
                 // size="lg"
                 >
                   <Modal.Header closeButton style={{backgroundColor : '#282D51'}}>
                     <Modal.Title style={{
                       fontFamily : 'Inter',
                       fontSize : '20px',
                       fontWeight : 600,
       
                     }} >Image Preview</Modal.Title>
                   </Modal.Header>
                   <Modal.Body style={{backgroundColor : '#282D51'}}>
                     <Image src={url} style={{borderRadius:"8px",}} alt="Image Preview" fluid/>
                   </Modal.Body>
                   <Modal.Footer style={{backgroundColor : '#282D51', }}>
       
                     <Button onClick={() =>handleImageDownload(url)}
                      style={{ backgroundColor : '#F6BE6B',border : 'none',
                       color : '#17182C',
                       fontFamily : 'Inter',
                       fontWeight : 600,
                       fontSize : '16px'
                       
       
                     }}>
                       <i className="bi bi-cloud-arrow-down"></i><span style={{marginLeft:'10px',}}>Download</span>
                     </Button>
                   </Modal.Footer>
                 </Modal>
          }
          {/* <Modal show={showModal} onHide={handleCloseModal}  style={{backgroundColor :'red'}}
          // size="lg"
          >
          background: #FFD9AA;

            <Modal.Header closeButton style={{backgroundColor : '#282D51'}}>
              <Modal.Title style={{
                fontFamily : 'Inter',
                fontSize : '20px',
                fontWeight : 600,

              }} >Image Preview</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{backgroundColor : '#282D51'}}>
              <Image src={url} style={{borderRadius:"8px"}} alt="Image Preview" fluid/>
            </Modal.Body>
            <Modal.Footer style={{backgroundColor : '#282D51', }}>

              <Button onClick={() =>handleImageDownload(url)} style={{ backgroundColor : '#F6BE6B',border : 'none',
                color : '#17182C',
                fontFamily : 'Inter',
                fontWeight : 600,
                fontSize : '16px'

              }}>
                <i className="bi bi-cloud-arrow-down"></i><span style={{marginLeft:'10px',}}>Download</span>
              </Button>
            </Modal.Footer>
          </Modal> */}
          

          {/* <ShareButton className="share-button">
            <FacebookShareButton url={shareUrl}>
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <TwitterShareButton url={shareUrl}>
              <TwitterIcon size={32} round />
            </TwitterShareButton>
            <WhatsappShareButton url={shareUrl}>
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
            <EmailShareButton url={shareUrl}>
              <EmailIcon size={32} round />
            </EmailShareButton>
            <CopyToClipboardButton url={shareUrl} />
          </ShareButton> */}
        </ImageContainer>
      );
      case pdfTest.test(url):
        return (
          <ResponsiveDocumentViewerContainer>
            <ResponsiveDocumentViewer
              src={url}
              allow="autoplay"
              borderRadius="50px"
            />
            
            {/* Download button that triggers download */}
            <DownloadButton
              as="button"
              onClick={() => handlePdfDownload(url)}  // Use the handlePdfDownload function
              className="download-button"
              title="Download"
            >
              <i className="bi bi-cloud-arrow-down"></i>
            </DownloadButton>
            
            <LargePreviewButton
              as="button"
              onClick={handleShowModal}
              className="large-preview-button"
              title="View"
            >
              <i className="bi bi-arrows-fullscreen"></i>
            </LargePreviewButton>
            
            <Modal show={showModal} onHide={handleCloseModal} size="lg">
              <Modal.Header closeButton>
                <Modal.Title>PDF Preview</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ResponsiveDocumentViewer
                  src={url}
                  height="500px"
                  borderRadius="8px"
                />
              </Modal.Body>
              <Modal.Footer>
                <DownloadButtonInsideModal
                  as="button"
                  onClick={() => handlePdfDownload(url)}  // Use the handlePdfDownload function
                  className="download-button"
                  title="Download"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <i className="bi bi-cloud-arrow-down"></i>
                  <span style={{ fontSize: '1rem', marginLeft: '10px' }}>Download</span>
                </DownloadButtonInsideModal>
              </Modal.Footer>
            </Modal>
          </ResponsiveDocumentViewerContainer>
        );
    default:
      return null;
  }
};


export default PreviewMedia;





// import React, { useEffect, useRef, useState } from "react";
// import { Button, Image } from "react-bootstrap";
// import ReactPlayer from "react-player";
// import { DocumentViewer } from "react-documents";
// import styled from "styled-components";

// import { IconBase } from "react-icons";
// import { BsCopy } from "react-icons/bs";

// import {
//   FacebookShareButton,
//   FacebookIcon,
//   TwitterShareButton,
//   TwitterIcon,
//   WhatsappShareButton,
//   WhatsappIcon,
//   EmailShareButton,
//   EmailIcon,
// } from "react-share";


// const CopyToClipboardButton = ({ url }) => {
//   const handleCopy = () => {
//     navigator.clipboard.writeText(url).then(() => {
//       alert("URL copied to clipboard!");
//     });
//   };

//   return (
//     <IconBase
//       as="button" // Change component to "button" for semantic purposes
//       onClick={handleCopy}
//       style={{
//         cursor: "pointer",
//         display: "inline-flex",
//         alignItems: "center",
//         justifyContent: "center",
//         borderRadius: "50%",
//         width: "32px",
//         height: "32px",
//         backgroundColor: "#007bff",
//         color: "#fff",
//         border: "none",
//         padding: "6px",
//         outline: "none",
//       }}
//     >
//       <BsCopy size={15} /> {/* Use the Bootstrap Icons copy icon */}
//     </IconBase>
//   );
// };


// const ResponsiveDocumentViewer = (props) => {
//   const viewerRef = useRef(null);
//   const [viewerHeight, setViewerHeight] = useState("0px");

//   const updateViewerHeight = () => {
//     if (viewerRef.current) {
//       const width = viewerRef.current.offsetWidth;
//       const height = width * 0.8;
//       setViewerHeight(`${height}px`);
//     }
//   };

//   useEffect(() => {
//     updateViewerHeight();
//     window.addEventListener("resize", updateViewerHeight);

//     return () => {
//       window.removeEventListener("resize", updateViewerHeight);
//     };
//   }, []);

//   return (
//     <div ref={viewerRef} style={{ width: "100%", height: viewerHeight }}>
//       <iframe
//         src={`https://view.officeapps.live.com/op/embed.aspx?src=${props.url}`}
//         width="100%"
//         height="100%"
//         frameBorder="0"
//         style={{ overflow: "hidden", height: "100%" }}
//       />
//     </div>
//   );
// };

// const ImageContainer = styled.div`
//   position: relative;
//   display: inline-block;
//   width: 100%;
  

//   &:hover .download-button,
//   &:hover .share-button {
//     display: block;
//   }
// `;

// const ActionButton = styled.a`
//   position: absolute;
//   top: 10px;
//   background-color: transparent;
//   border: none;
//   color: white;
//   font-size: 24px;
//   display: none;
//   transition: transform 0.3s ease;

//   &:hover {
//     transform: scale(1.2);
//     background-color: transparent;
//     color: blue;
//   }
// `;

// const DownloadButton = styled(ActionButton)`
//   right: 5%;
  
// `;

// const ShareButton = styled.div`
//   position: absolute;
//   top: 10px;
//   left: 10px;
//   z-index: 999;
//   display: flex;
//   gap: 5px;
//   display: none;
// `;
// const PreviewMedia = ({ url }) => {
//   const shareUrl = url; // or any other URL you want to share

//   const videoTest = /\.(mp4)$/i;
//   const audioTest = /\.(mp3)$/i;
//   const pptTest = /\.(ppt|pptx)$/i;
//   const imageTest = /\.(jpg|jpeg|png)$/i;

//   switch (true) {
//     case videoTest.test(url):
//       return (
//         <ReactPlayer
//           url={url}
//           controls
//           width="100%"
//           style={{ margin: "10px 0" }}
//         />
//       );
//     case audioTest.test(url):
//       return (
//         <audio controls src={url} style={{ maxWidth: "100%", width: "100%" }} />
//       );
//     case pptTest.test(url):
//       return <ResponsiveDocumentViewer url={url}></ResponsiveDocumentViewer>;
//     case imageTest.test(url):
//       return (
//         <ImageContainer>
//           <Image src={url} alt="Image Preview" fluid />
//           <DownloadButton
//             href={url}
//             download
//             target="_blank"
//             rel="noopener noreferrer"
//             className="download-button"
//           >
//             &#x21E9;
//           </DownloadButton>
//           <ShareButton className="share-button">
//             <FacebookShareButton url={shareUrl}>
//               <FacebookIcon size={32} round />
//             </FacebookShareButton>
//             <TwitterShareButton url={shareUrl}>
//               <TwitterIcon size={32} round />
//             </TwitterShareButton>
//             <WhatsappShareButton url={shareUrl}>
//               <WhatsappIcon size={32} round />
//             </WhatsappShareButton>
//             <EmailShareButton url={shareUrl}>
//               <EmailIcon size={32} round />
//             </EmailShareButton>
//             <CopyToClipboardButton url={shareUrl} />
//           </ShareButton>
//         </ImageContainer>
//       );
//     default:
//       return null;
//   }
// };

// export default PreviewMedia;

