import React,{useState,useRef,useEffect} from "react";
import { Box, Typography, CardMedia } from "@mui/material";
import image from "../../../assets/SuggestedForMobileView/image.png";
import search from "../../../assets/SuggestedForMobileView/search.png";
import video from "../../../assets/SuggestedForMobileView/video.png";
import presentation from "../../../assets/SuggestedForMobileView/presentation.png";
import Music from "../../../assets/SuggestedForMobileView/Music.png";
import { useWorkspace } from "../../../contexts/WorkspaceContext";
import { SuggestedActionClick, trackSearchInteraction } from "../../../config/analytics";
import { useMediaQuery } from "react-responsive";

function Suggested() {
  const {
    sending,
    shareModalforSocialMedia,
    setShareModalforSocialMedia,
    setEditClick,
    setSending,
    setWaitThread,
    setCurrentThread,
    sendMessage,
    currentThread,
    setShowCreditRecharge,
    showSuggested,
    setShowSuggested,
    // setArrayForLinksAvalible,
    // setHasLink
  } = useWorkspace();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [offsetX, setOffsetX] = useState(0);
  const sliderRef = useRef(null);

  const findUrl = (msg) => {
    const urlRegex =
      /(https?:\/\/[^']*?\.(mp3|mp4|pptx|jpg|jpeg|png|txt|ppt))/g;
    const match = urlRegex.exec(msg);
    return match ? match[1] : null;
  };

  const getFileFormat = (url) => {
    // Extract the file extension from the URL
    const parts = url.split(".");
    if (parts.length > 1) {
      return parts[parts.length - 1]; // Return the last part as the file format
    } else {
      return ""; // Return empty string if no file extension found
    }
  };

  // const extractLinks = async (inputString) => {
  //   setArrayForLinksAvalible([])
  //   // Regular expressions for each file type
  //   const pptRegex = /https?:\/\/[^\s]+\.pptx\b/g;
  //   const jpegRegex = /https?:\/\/[^\s]+\.jpeg\b/g;
  //   const mp4Regex = /https?:\/\/[^\s]+\.mp4\b/g;
  //   const mp3Regex = /https?:\/\/[^\s]+\.mp3\b/g;
    
  //   // Extract and push matches into their respective arrays
  //   const pptMatches = inputString?.match(pptRegex);
  //   if (pptMatches) {
  //     setArrayForLinksAvalible((prev)=> [...prev, "pptx"])
  //   }
    
  //   const jpegMatches = inputString?.match(jpegRegex);
  //   if (jpegMatches) {
  //     setArrayForLinksAvalible((prev)=> [...prev, "image"])
  //   }
    
  //   const mp4Matches = inputString?.match(mp4Regex);
  //   if (mp4Matches) {
  //     setArrayForLinksAvalible((prev)=> [...prev, "video"])
  //   }
  //   const mp3Matches = inputString?.match(mp3Regex);
  //   if (mp3Matches) {
  //     setArrayForLinksAvalible((prev)=> [...prev, "audio"])
  //   }
    
  //   return 
  //   };

  const handleSubmit = async (msg) => {
    const input = msg;
    trackSearchInteraction(input);
    setSending(true);
    setWaitThread(true);
    if (input.trim()) {
      const latestEntry = { sender: "user", content: input };
      // setInput(""); // Reset input field
      setCurrentThread((prevThread) => [...prevThread, latestEntry]);
      const entryResponse = await sendMessage(latestEntry).catch(console.error);
      // if(findUrl(entryResponse.content)){
      //   setHasLink(true)
      //   await extractLinks(entryResponse.content)
      //   }
      if (entryResponse) {
        // Check if entryResponse contains a daily limit exceeded message
        if (
          entryResponse &&
          entryResponse.content &&
          findUrl(entryResponse.content) &&
          findUrl(entryResponse.content).includes("dailylimitexceeded")
        ) {
          // Determine the file format from the URL (assuming findUrl() returns the URL)
          const fileFormat = getFileFormat(findUrl(entryResponse.content));

          // Prepare the response message
          const responseMessage = {
            content:
              `Hi there,\n\nIt looks like you’ve reached your daily limit for generating ` +
              fileFormat +
              ` files. We’re really sorry about that! We know how important uninterrupted access is for you.\n\nTo keep enjoying all the great benefits and have a seamless experience, we’d love for you to consider upgrading to our premium plan.\n\nThank you so much for your understanding and support. We truly value having you with us and are excited to continue providing you with the best service possible.\n\nWarm regards,\n\nThe ModalX Team`,
            sender: "modalX",
          };

          // Update the current thread with the response message
          setCurrentThread((prevThread) => [...prevThread, responseMessage]);
          setShowCreditRecharge(true);
        } else {
          // If the response does not contain a daily limit exceeded message, proceed as usual
          setCurrentThread((prevThread) => [...prevThread, entryResponse]);
        }
        setSending(false);
      } else {
        // If there's no entryResponse, indicate processing failure
        setCurrentThread((prevThread) => [
          ...prevThread,
          { content: "Unable to process your query!..", sender: "modalX" },
        ]);
        setSending(false);
      }
    }
    setWaitThread(false);
  };

  const regenerateResponse = (title) => {
    SuggestedActionClick(title);
    setShowSuggested(false);
    handleSubmit(title); // Now it submits the title instead of a static string
  };

const datas = [
    {
      id: 1,
      title: "Design an image illustrating visa application steps.",
      image: image,
      click: () => regenerateResponse(datas[0].title),
    },
    {
      id: 2,
      title: "Create a video visualizing the immigration journey.",
      image: video,
      click: () => regenerateResponse(datas[1].title),
    },
    
    {
      id: 3,
      title: "Create a PPT explaning key immigration policies.",
      image: presentation,
      click: () => regenerateResponse(datas[2].title),
    },
    {
      id: 4,
      title:
        "Generate audio showcasing consulting benefits & success",
      image: Music,
      click: () => regenerateResponse(datas[3].title),
    },
    // {
    //   id: 5,
    //   title: "Generate a blog post for a new product feature",
    //   image: presentation,
    //   click: () => regenerateResponse(datas[4].title),
    // },
  ];
  const isBelow991 = useMediaQuery({ maxWidth: 991 });
  const isBelow700 = useMediaQuery({ maxWidth: 700 });
  const isHeightAbove800 = useMediaQuery ({ minHeight: 800 });

  // // Number of slides to show at once
  // const visibleSlides = 2;

  // // Handle mouse down (start dragging)
  // const handleMouseDown = (e) => {
  //   setIsDragging(true);
  //   setStartX(e.clientX); // Set the initial position of the click
  //   setOffsetX(0); // Reset offset for a new drag session
  // };

  // // Handle mouse move (dragging)
  // const handleMouseMove = (e) => {
  //   if (!isDragging) return;

  //   const moveX = e.clientX - startX; // Calculate how far the mouse has moved
  //   setOffsetX(moveX);
  // };

  // // Handle mouse up (stop dragging)
  // const handleMouseUp = () => {
  //   if (!isDragging) return;

  //   setIsDragging(false);

  //   // Calculate the index shift (if dragged enough)
  //   if (offsetX > 100 && currentIndex > 0) {
  //     setCurrentIndex(currentIndex - 1); // Move to the left
  //   } else if (offsetX < -100 && currentIndex < datas.length - visibleSlides) {
  //     setCurrentIndex(currentIndex + 1); // Move to the right
  //   }

  //   setOffsetX(0); // Reset offset
  // };

  // // Listen for mouseup and mousemove globally when dragging starts
  // useEffect(() => {
  //   if (isDragging) {
  //     // Add global event listeners when dragging starts
  //     window.addEventListener('mousemove', handleMouseMove);
  //     window.addEventListener('mouseup', handleMouseUp);
  //   } else {
  //     // Remove the global listeners when dragging stops
  //     window.removeEventListener('mousemove', handleMouseMove);
  //     window.removeEventListener('mouseup', handleMouseUp);
  //   }

  //   // Cleanup event listeners on component unmount
  //   return () => {
  //     window.removeEventListener('mousemove', handleMouseMove);
  //     window.removeEventListener('mouseup', handleMouseUp);
  //   };
  // }, [isDragging, offsetX]);

    // Number of slides to show at once
    const visibleSlides = 2;

    // Handle mouse down or touch start
    const handleStart = (e) => {
      setIsDragging(true);
      const clientX = e.type === "touchstart" ? e.touches[0].clientX : e.clientX;
      setStartX(clientX);
      setOffsetX(0); // Reset offset for a new drag session
    };
  
    // Handle mouse move or touch move
    const handleMove = (e) => {
      if (!isDragging) return;
      const clientX = e.type === "touchmove" ? e.touches[0].clientX : e.clientX;
      const moveX = clientX - startX;
      setOffsetX(moveX);
    };
  
    // Handle mouse up or touch end
    const handleEnd = () => {
      if (!isDragging) return;
  
      setIsDragging(false);
  
      // Calculate the index shift (if dragged enough)
      if (offsetX > 100 && currentIndex > 0) {
        setCurrentIndex(currentIndex - 1); // Move to the left
      } else if (offsetX < -100 && currentIndex < datas.length - visibleSlides) {
        setCurrentIndex(currentIndex + 1); // Move to the right
      }
  
      setOffsetX(0); // Reset offset
    };
  
    // Listen for touch and mouse events globally when dragging starts
    useEffect(() => {
      if (isDragging) {
        // Add event listeners for both mouse and touch
        window.addEventListener("mousemove", handleMove);
        window.addEventListener("mouseup", handleEnd);
        window.addEventListener("touchmove", handleMove);
        window.addEventListener("touchend", handleEnd);
      } else {
        // Remove the event listeners when dragging stops
        window.removeEventListener("mousemove", handleMove);
        window.removeEventListener("mouseup", handleEnd);
        window.removeEventListener("touchmove", handleMove);
        window.removeEventListener("touchend", handleEnd);
      }
  
      // Cleanup event listeners on component unmount
      return () => {
        window.removeEventListener("mousemove", handleMove);
        window.removeEventListener("mouseup", handleEnd);
        window.removeEventListener("touchmove", handleMove);
        window.removeEventListener("touchend", handleEnd);
      };
    }, [isDragging, offsetX]);

  return (
//     <>
//     <Box
//       sx={{
//       display: showSuggested ? "block" : "none",
//       }} 
//     >
//       <Box
//   sx={{
//     margin: "0px 0px 0px 20px",
//   }}
// >
//   <Typography
//     sx={{
//       fontFamily: "Inter",
//       fontSize: "14px",
//       fontWeight: 400,
//       textAlign: "left",
//     }}
//   >
//     Suggested
//   </Typography>
// </Box>
//     <Box
//       sx={{
//         position: 'relative',
//         width: '100%',
//         margin: '0 auto',
//         overflow: 'hidden',
//         userSelect: 'none', // Prevents text selection while dragging
//         cursor: isDragging ? 'grabbing' : 'grab',
//       }}
//       ref={sliderRef}
//       onMouseDown={handleMouseDown}
//     >
//       <Box
//         sx={{
//           display: 'flex',
//           transition: isDragging ? 'none' : 'transform 0.5s ease-in-out',
//           transform: `translateX(calc(-${currentIndex * (100 / visibleSlides)}% + ${offsetX}px))`, // Adjust the position based on drag offset
//         }}
//       >
//         {datas.map((e) => (
//           <Box
//             key={e.id}
//             sx={{
//               // minWidth: `${100 / visibleSlides}%`, // Show multiple slides
//               height: '200px',
//               display: 'flex',
//               flexDirection: 'column',
//               justifyContent: 'center',
//               alignItems: 'center',
//               textAlign: 'center',
//               // bgcolor: 'red',
//               padding: '20px',
//               boxSizing: 'border-box', // Prevent slides from overflowing
//             }}
//           >
//                 <Box
//                 onClick={e.click}
//                 sx={{
//                   width: "156px",
//                   height: "140px",
//                   bgcolor: "#1F233E",
//                   borderRadius: "8px",
//                   padding: "12px",
//                   cursor : "pointer"
//                 }}
//               >
//                 <Box
//                   sx={{
//                     width: "24px",
//                     height: "24px",
//                   }}
//                 >
//                   <CardMedia
//                     component="img"
//                     image={e.image}
//                     alt="loading"
//                   />
//                 </Box>
//                 <Typography
//                   sx={{
//                     fontFamily: "Inter",
//                     fontSize: "14px",
//                     fontWeight: 400,
//                     textAlign: "left",
//                     paddingTop: "8px",
//                   }}
//                 >
//                   {e.title}
//                 </Typography>
//               </Box>
//           </Box>
//         ))}
//       </Box>
//     </Box>

//     </Box>

//     </>
<>
<Box sx={{display: isBelow700 ? "block" :"flex", justifyContent:isBelow700 ? "" : "center",  overflowX: "hidden",  }}>
<Box
  sx={{
    display: currentThread.length ===0 && showSuggested ? "block" : "none",
  }}
>
  <Box
    sx={{
      margin:isBelow991 ? "0px 0px -10px 20px" : isHeightAbove800 ? "48px 0px -10px 20px" : "0px 0px -10px 20px",
    }}
  >
    <Typography
      sx={{
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 400,
        textAlign: "left",
      }}
    >
      Suggested
    </Typography>
  </Box>
  <Box
    sx={{
      position: "relative",
      width: "100%",
      margin: "0 auto",
      overflow: "hidden",
      userSelect: "none", // Prevents text selection while dragging
      cursor: isDragging ? "grabbing" : "grab",
    }}
    ref={sliderRef}
    onMouseDown={handleStart}
    onTouchStart={handleStart}
  >
    
    <Box
      sx={{
        display: "flex",
        transition: isDragging ? "none" : "transform 0.5s ease-in-out",
        transform: `translateX(calc(-${
          currentIndex * (100 / visibleSlides)
        }% + ${offsetX}px))`, // Adjust the position based on drag offset
      }}
    >
      
      {datas.map((e) => (
        <Box
          key={e.id}
          sx={{
            height: "200px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            marginLeft : '20px',
            boxSizing: "border-box", // Prevent slides from overflowing
          }}
        >
          <Box
          onClick={e.click}
            sx={{
              width: isBelow991 ? "156px" : "180px",
              height:isBelow991 ? "140px" : "148px",
              bgcolor: "#1F233E",
              borderRadius: "8px",
              padding: "12px",
              cursor: "pointer",
            }}
          >
            <Box
              sx={{
                width: "24px",
                height: "24px",
              }}
            >
              <CardMedia component="img" image={e.image} alt="loading" />
            </Box>
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize:isBelow991 ? "14px" : "16px",
                fontWeight: 400,
                textAlign: "left",
                paddingTop:isBelow991 ? "8px" : "12px",
              }}
            >
              {e.title}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  </Box>
</Box>
</Box>

</>
  );
}

export default Suggested;