import React, { useState,useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Messages from "./Messages";
import PromptInput from "./PromptInput";
import { useWorkspace } from "../../../contexts/WorkspaceContext";
import axios from "axios";
import sourceUrl from "../../../api/Source.api";
import ShareModal from "./ShareModal";
import { darkTheme_bg_color } from "../../../constants/colors";
import ImageSettings from "../settingBar/ImageSettings/ImageSettings";
import PptTemplates from "../settingBar/PptTemplates";
import AudioSettings from "../settingBar/AudioSettings/AudioSettings";
import WarRoomHeaderForMobileResponsive from "../mobileResponsive/WarRoomHeaderForMobileResponsive";
import {  useMediaQuery } from "@mui/material";
import VideoSettings from "../settingBar/VideoSettings";


const MessageWindow = () => {
  const token = localStorage.getItem('token');
  const theme = localStorage.getItem('theme');
  const {currentThread,currentHistoryId,setCurrentHistoryId,Today,setToday,setYesterday,setThisWeek,setThisMonth,setPrevMonth,InvokeMatrixApi,threadId,showRightsideBar,showLeftsideBar, setSubscribe, subscribe,reloadForSummary, setReloadForSummary} = useWorkspace();
  const [showModal, setShowModal] = useState(false);
  const [firsttimefetch, setFirstTimeFetch] = useState(false);
  const mobileView = useMediaQuery('(max-width:992px)');
  const fetchAllHistory = async() => {
    try {
      const res = await axios.get(`${sourceUrl}/chat/summary`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      // console.log("print from summary",res)
      // console.log(res);
      if (res.data.status) { // Check if the response status is true
        const { today, yesterday, this_week, this_month ,last_month} = res.data.data;
        setToday(today || []);
        if(Today.length && Today.length < today.length){
          setCurrentHistoryId(today[0].id);
        }
        setYesterday(yesterday || []);
        setThisWeek(this_week || []);
        setThisMonth(this_month || []);
        setPrevMonth(last_month || []) ;
      } else {
        // Handle API response error
      }
      // ==========================Invoke matrix api ===================================================
      // Invoke matrix API
        // await InvokeMatrixApi(res, res, 'POST',threadId, 'summary', {});
      // ===============================================================================================
    } catch (error) {
      console.error("Error fetching history:", error);
      // ==========================Invoke matrix api ===================================================
        // Invoke matrix API
        // await InvokeMatrixApi(error, error.data, 'POST',threadId, 'summary', {});
      // ===============================================================================================
      // Handle fetch error
    }
  }
  // if(Today.length > 0 || Yesterday.length > 0 || ThisWeek.length > 0 || ThisMonth.length > 0){
  //   alert("sdkbfks")
    
  // }
  const UserCreaditStatus = async () =>{
    // console.log(token)
   if(token){
    try{
      const response = await axios.get(`${sourceUrl}/issubscribe`,{
          headers: {
            Authorization: `Bearer ${token}` 
          },
        })
      // if(response.status===200  &&  response.data.data["remaning credits"] === "User is subscribed."){
      //   setSubscribe(true)
      // }
      if(response.status===200  &&  response.data?.data?.status === true){
        setSubscribe(true)
      }
     }catch(e){
      console.log("User_Creadit_Status", e)
     }
   }
   }

  useEffect(()=>{
  UserCreaditStatus()
  },[subscribe])


  useEffect(() => {
    if((firsttimefetch && currentThread.length === 2 && currentThread.every(obj => {
      const keys = Object.keys(obj);
      return  keys.includes('sender') && keys.includes('content');
  })) || reloadForSummary){
      fetchAllHistory() 
      setFirstTimeFetch(false)
      setReloadForSummary(false)
    }
  }, [currentThread])

  useEffect(()=>{
    if(currentHistoryId==null){
      setFirstTimeFetch(true)
    }
  },[currentHistoryId])

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const navbarStyle = {
    position: "fixed",
    top: 0,            
    left: 0,          
    width: "100%",     
    zIndex: 1000,      
    backgroundColor:theme === 'dark'?darkTheme_bg_color:'',
  };

  return (
    <Col>
      <Row>
        <Col
          xs={{ span: 12, offset: 0 }}
          s={{ span: 12, offset: 0 }}
          md={{ span: 12, offset: 0 }}
          lg={{ span: 10, offset: 1 }}
          xl={{ span: 8, offset: 2 }}
          xxl={{ span: 8, offset: 2 }}
          className="pt-3 pb-4"
          style={{
            minHeight: "100vh",
            maxHeight: "100vh",
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
            width:(showLeftsideBar && showRightsideBar)?'100%':'100%',
            margin:'auto',
            backgroundColor:theme === 'dark'?darkTheme_bg_color:'',
          }}
        >
          {currentThread.length === 0 ? '' :
            // <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            //   <Button style={{ maxWidth: '50px',marginRight:'50px',backgroundColor:theme === 'dark'?'':''}} variant="outline-primary" onClick={handleShowModal}>
            //     {/* <i className="bi bi-share"></i> */}
            //     <i class="bi bi-upload"></i>
            //   </Button>
            // </div>n
            <></>
          }
          <div style={navbarStyle}><WarRoomHeaderForMobileResponsive/></div>
          <Messages />
          <div style={{paddingTop: mobileView ? '0px' :'32px'}}>
            <PromptInput />
          </div>
          {/* <VideoSettings />
          <ImageSettings />
          <PptTemplates />
          <AudioSettings /> */}
          
          <div style={{textAlign:'center',color:'grey',fontSize:'.9rem', marginTop : '20px'}}>ModalX may have errors, verify key info</div>
        </Col>
      </Row>
      <ShareModal show={showModal} handleClose={handleCloseModal} />
    </Col>
  );
};

export default MessageWindow;
