// import React from "react";
// import { Container, Row } from "react-bootstrap";
// import axios from "axios";

// function ThreadHistory({ history }) {
//   function fetchData(id) {
//     axios
//       .get(`/getChat?id=${id}`)
//       .then((response) => {
//         // Handle response data
//         console.log(response.data);
//       })
//       .catch((error) => {
//         // Handle errors
//         console.error("Error fetching chat:", error);
//       });
//   }

//   function handleClick(id) {
//     fetchData(id);
//   }

//   return (
//     <Container
//       fluid
//       style={{
//         height: "82vh",
//         overflow: "auto",
//       }}
//       className="py-2"
//     >
//       {history.map((item, index) => (
//         <Row
//           key={index}
//           className="py-1 ps-3"
//           onClick={() => handleClick(item.id)}
//           style={{ cursor: "pointer" }}
//         >
//           {item.title}
//         </Row>
//       ))}
//     </Container>
//   );
// }

// export default ThreadHistory;


import React, { useEffect, useState, useRef  } from "react";
import styled from "styled-components";
import { Container, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import moment from "moment"; // You may need to install moment for date manipulation
import { useWorkspace } from "../../../contexts/WorkspaceContext";
import sourceUrl from "../../../api/Source.api";
import { useMediaQuery } from "react-responsive";
import ShareModal from "../message/ShareModal";
import { ChatBubbleOutlineOutlined as ChatBubbleOutlineOutlinedIcon } from '@mui/icons-material';
import { hovered_chat_bg_color, selected_chat_bg_color } from "../../../constants/colors";
import { trackProjectInteraction } from "../../../config/analytics";
import DeleteAndShareModal from "../message/DeleteAndShareModal";
import CustomizedSnackbars from "../../navFoot/CustomizedSnackbars";
import { createThread } from "../../../contexts/WorkspaceUtils";

const CustomContainer = styled(Container)`
  overflow: auto;
  padding-right: 30px;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: ${({ theme }) => (theme === 'dark' ? '#2c2c2c' : '#f1f1f1')};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => (theme === 'dark' ? '#888' : '#ccc')};
    border-radius: 10px;
    border: 2px solid ${({ theme }) => (theme === 'dark' ? '#2c2c2c' : '#f1f1f1')};
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

function ThreadHistory() {
  const {setShowLeftsideBar,setThreadFiles,setCurrentHistoryId,clearThread,currentThread,setCurrentThread,threadId,setThreadId,setHistoryFiles,Today,setToday,Yesterday,setYesterday,ThisWeek,setThisWeek,ThisMonth,setThisMonth,PrevMonth,setPrevMonth,threadFiles,InvokeMatrixApi,currentHistoryId,setShowSuggested,setIswebTrackClicked, setCurrentWebsiteLinkedId,setSelectedWeb, setArrayForLinksAvalible, setHasLink} = useWorkspace()
  
  const token = localStorage.getItem('token');
  const email = localStorage.getItem("userEmail");
  const userId = localStorage.getItem("userId");
  const [history,setHistory] = useState([]);
  const [today,set_today] = useState(Today || []);
  const [yesterday,set_yesterday] = useState(Yesterday || []);
  const [thisWeek,set_thisWeek] = useState(ThisWeek || []);
  const [thisMonth,set_thisMonth] = useState(ThisMonth || []);
  const [prevMonth,set_PrevMonth] = useState(PrevMonth || []) ;
  const isXsOrSm = useMediaQuery({ maxWidth: 1280 });
  const theme = localStorage.getItem('theme');
  const [hoveredItemId, setHoveredItemId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [deleteproject, setDeleteProject] = useState(false);
  const [deleteprojectdata, setDeleteProjectData] = useState({});
  const modalRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [notifMsg, setNotifMsg] = useState(false);
  const [notifVariant, setNotifVariant] = useState("");
  const [showModalId, setShowModalId] = useState(null)

  useEffect(() => {
    set_today(Today);
    set_yesterday(Yesterday);
    set_thisWeek(ThisWeek);
    set_thisMonth(ThisMonth);
    set_PrevMonth(PrevMonth);
  }, [Today, Yesterday, ThisWeek, ThisMonth, PrevMonth,deleteproject]);


  useEffect(()=>{
    const HandleDeleteProject =async () => {
      try{
        const res = await axios.delete(`${sourceUrl}/threads/${deleteprojectdata.thread_id}/${deleteprojectdata.id}/delete`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if(res.status === 200){
          await fetchAllHistory()
         if(currentHistoryId === deleteprojectdata.id){
          clearThread();
          setThreadFiles([]);
          setHistoryFiles([]);
          setThreadId(null);
         }
          setDeleteProjectData({})
          setShowModal(false)
          setOpen(true)
          setNotifMsg("Project deleted successfully")
          notifVariant("success")
          setDeleteProject(false)
        
        }
      }catch(err){
          console.log(err)
      }
    }
    if(deleteproject){
      setClickedIndex(null)
      HandleDeleteProject()
    }
  },[deleteproject])

  const fetchAllHistory = async() => {
    try {
      const res = await axios.get(`${sourceUrl}/chat/summary`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      console.log("print from summary",res)
      console.log(res);
      if (res.data.status) { // Check if the response status is true
        const { today, yesterday, this_week, this_month ,last_month} = res.data.data;
        setToday(today || []);
        if(Today.length && Today.length < today.length){
          setCurrentHistoryId(today[0].id);
        }
        setYesterday(yesterday || []);
        setThisWeek(this_week || []);
        setThisMonth(this_month || []);
        setPrevMonth(last_month || []) ;
      } else {
        // Handle API response error
      }
      // ==========================Invoke matrix api ===================================================
      // Invoke matrix API
        // await InvokeMatrixApi(res, res, 'POST',threadId, 'summary', {});
      // ===============================================================================================
    } catch (error) {
      console.error("Error fetching history:", error);
      // ==========================Invoke matrix api ===================================================
        // Invoke matrix API
        // await InvokeMatrixApi(error, error.data, 'POST',threadId, 'summary', {});
      // ===============================================================================================
      // Handle fetch error
    }
  }
  // const extractLinks = async (inputString) => {
  //   setArrayForLinksAvalible([])
  //   // Regular expressions for each file type
  //   const pptRegex = /https?:\/\/[^\s]+\.pptx\b/g;
  //   const jpegRegex = /https?:\/\/[^\s]+\.jpeg\b/g;
  //   const mp4Regex = /https?:\/\/[^\s]+\.mp4\b/g;
  //   const mp3Regex = /https?:\/\/[^\s]+\.mp3\b/g;
    
  //   // Extract and push matches into their respective arrays
  //   const pptMatches = inputString?.match(pptRegex);
  //   if (pptMatches && !inputString.includes("dailylimitexceeded")) {
  //     setArrayForLinksAvalible((prev)=> [...prev, "pptx"])
  //     setHasLink(true)
  //   }
    
  //   const jpegMatches = inputString?.match(jpegRegex);
  //   if (jpegMatches && !inputString.includes("dailylimitexceeded")) {
  //     setArrayForLinksAvalible((prev)=> [...prev, "image"])
  //     setHasLink(true)
  //   }
    
  //   const mp4Matches = inputString?.match(mp4Regex);
  //   if (mp4Matches && !inputString.includes("dailylimitexceeded")) {
  //     setArrayForLinksAvalible((prev)=> [...prev, "video"])
  //     setHasLink(true)
  //   }
  //   const mp3Matches = inputString?.match(mp3Regex);
  //   if (mp3Matches && !inputString.includes("dailylimitexceeded")) {
  //     setArrayForLinksAvalible((prev)=> [...prev, "audio"])
  //     setHasLink(true)
  //   }
  //   return 
  // };

//  async function getLastObject(response) {
//     const history = response?.data?.data?.history;

//     // Ensure history is an array with at least one element
//     if (Array.isArray(history) && history.length > 0) {
//         // Extract the last object
//         const lastObject = history[history.length - 1];
//         await extractLinks(lastObject.agent)
//     } else {
//         console.log("History array is empty or not found.");
//     }
// }

  const handleClick = async (item) => {
    setSelectedWeb("");
    setCurrentWebsiteLinkedId(null)
    setIswebTrackClicked(false);
    setShowSuggested(false)
    if(isXsOrSm){
      setShowLeftsideBar(false);
    }
    const {id,thread_id} = item ;
    setThreadFiles([]);
    setCurrentHistoryId(id);
    try {
      const res = await axios.get(`${sourceUrl}/chat/${id}/history`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      // alert("skmsmdk")
      // console.log("current_history_threadDataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",res.data);

      // ==================
      setThreadId(`thread-${thread_id}`)
      // ==================
      setCurrentThread(res.data.data.history);
      // await getLastObject(res)
      if(res.data.data && res.data.data.input_files&& res.data.data.input_files.length){
        setHistoryFiles(res.data.data.input_files)
      }
      else{
        setHistoryFiles([])
      }
      // ==========================Invoke matrix api ===================================================
        // Invoke matrix API
        try {
          const data = {
            url: 'history',
            threadId: threadId,
            requestId: res?.data?.requestID,
            requestType: 'GET',
            requestBody: {},
            responseBody: res?.data?.data,
            responseCode: res.status,
            userId:userId
          };
        
          const headers = {
            'Content-Type': 'application/json'
          };
        
          const matrix_Res = await axios.post(`${sourceUrl}/add`, data, { headers });

        } catch (error) {
          console.error('Error making the POST request:', error);
          await InvokeMatrixApi(error, error.data, 'GET',threadId, 'summary', {}, userId);
        }
        // await InvokeMatrixApi(res, res, 'GET',threadId, 'history', {});
      // ===============================================================================================
      
      // console.log("here is the updated curr_thread",currentThread);
    } catch (error) {
      console.log(error);
      // ==========================Invoke matrix api ===================================================
        // Invoke matrix API
        await InvokeMatrixApi(error, error.data, 'GET',threadId, 'summary', {}, userId);
      // ===============================================================================================
    }
  }

  const handleMouseEnter = (itemId) => {
    setHoveredItemId(itemId);
  };

  const handleMouseLeave = () => {
    setHoveredItemId(null);
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const [clickedIndex, setClickedIndex] = useState(null); // State to track which index was clicked
  const [clickThisWeek, setClickThisWeek] = useState(null)
  const [clickyesterday, setClickYesterday] = useState(null)
  const [clickThisMonth, setClickThisMonth] = useState(null)
  const [clickPreMonth, setPreMonth] = useState(null)

  const deleteAndShareFun = (index) => {
    setClickedIndex(index); // Set the clicked index
    // Add any additional logic here, if needed
    setShowModal(!showModal)
    setClickThisWeek(null)
    setClickYesterday(null)
    setClickThisMonth(null)
    setPreMonth(null)
  };
 
  const clickYesterdayFun = (index) =>{
    setClickYesterday(index)
    setShowModal(!showModal)
    setClickedIndex(null)
    setClickThisWeek(null)
    setClickThisMonth(null)
    setPreMonth(null)

    
  }

const clickThisWeekFunc = (index) =>{
  setClickThisWeek(index)
  setShowModal(!showModal)
  setClickedIndex(null)
  setClickYesterday(null)
  setClickThisMonth(null)
  setPreMonth(null)
}

const clickThisMonthFun = (index) =>{
  setClickThisMonth(index)
  setShowModal(!showModal)
  setClickedIndex(null)
  setClickYesterday(null)
  setClickThisWeek(null)
  setPreMonth(null)
  
}
const clickPreMonthFun = (index) =>{
  setPreMonth(index)
  setShowModal(!showModal)
  setClickedIndex(null)
  setClickYesterday(null)
  setClickThisWeek(null)
  setClickThisMonth(null)

}
  
  const datas = [
    { label: 'Today', data: today, clickFunc: deleteAndShareFun },
    { label: 'Yesterday', data: yesterday, clickFunc: clickYesterdayFun },
    { label: 'This Week', data: thisWeek, clickFunc: clickThisWeekFunc },
    { label: 'This Month', data: thisMonth, clickFunc: clickThisMonthFun },
    { label: 'Previous Month', data: prevMonth, clickFunc: clickPreMonthFun }
  ]
  
  return (
    <>
    <CustomContainer 
    style={{ minHeight: '100%'}}
    fluid
    theme={theme}
    className="py-2"
    >
    <div style={{ fontSize: '.9rem'  }}>

    {
    // Filter out categories with no data, and then map over the remaining ones
    datas.filter(category => category.data.length > 0)
    .map((category, catIndex) => (
      <div key={catIndex}>
        {/* Display category title */}
        <Row className="py-1 ps-1" style={{}}>
          
          <p className="p-0 m-0" style={{ color: theme === 'dark' ? '#FFFFFF' : '', fontSize: '16px', fontWeight: '500' }}>
            {category.label}
          </p>
        </Row>

        {/* Map over each item in the category */}
        {category.data.map((item, index) => (
          <Row
            key={index}
            className="py-1"
            style={{
              display: "flex",
              justifyItems: "space-between",
              alignSelf: "center",
              // backgroundColor : 'red',
              width : '245px'
              // marginRight : '-25px'
            }}
          >
            <Col
              onClick={() => handleClick(item)}
              onMouseEnter={() => handleMouseEnter(item.id)}
              onMouseLeave={handleMouseLeave}
              style={{
                cursor: "pointer",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                backgroundColor:
                  currentHistoryId == item.id
                    ? theme == "dark"
                      ? selected_chat_bg_color
                      : "white"
                    : hoveredItemId == item.id
                    ? theme == "dark"
                      ? hovered_chat_bg_color
                      : "white"
                    : showModalId == item.id  ? theme == "dark"  ? hovered_chat_bg_color  : "white" : "",
                borderRadius:
                  currentHistoryId == item.id || hoveredItemId == item.id ||  showModalId == item.id 
                    ? "8px"
                    : "",
                display: "flex",
                padding: '16px 12px 16px 12px',
              }}
            >
              <div style={{ flex: 1, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontSize: '14px' }}>
                <ChatBubbleOutlineOutlinedIcon sx={{ marginRight: 1 }} />
                {item.title}
              </div>

              {(currentHistoryId === item.id || hoveredItemId === item.id || showModalId === item.id) && (
                <button
                  style={{ backgroundColor: "transparent", border: "none"}}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowModalId(item.id)
                    category.clickFunc(index);
                  }}
                >
                  <i className="bi bi-three-dots"></i>
                </button>
              )}
            </Col>

            {/* Show the modal when an item is clicked */}
             {((category.label === 'Today' && clickedIndex === index) ||
              (category.label === 'Yesterday' && clickyesterday === index) ||
              (category.label === 'This Week' && clickThisWeek === index) ||
              (category.label === 'This Month' && clickThisMonth === index) ||
              (category.label === 'Previous Month' && clickPreMonth === index)) && (
              <DeleteAndShareModal
                showModal={showModal}
                setShowModal={setShowModal}
                item={item}
                setDeleteProject={setDeleteProject}
                setDeleteProjectData={setDeleteProjectData}
                showModalId ={showModalId}
                setShowModalId = {setShowModalId}
              />
            )}    
          </Row>
        ))}
      </div>
    ))}
    </div>
    </CustomContainer>

    <CustomizedSnackbars open={open} setOpen={setOpen} notifMsg={notifMsg} notifVariant={notifVariant}/>
    </>
  )
}

export default ThreadHistory;


