import React from 'react'
import { Box, CircularProgress, Typography } from "@mui/material";
import { useMediaQuery } from "react-responsive";

const CircularLoader = ({msg}) => {
    const isUnder992px = useMediaQuery({ maxWidth:992});
  return (
    <div>
      <Box sx={{ margin: "0px 0px", width: "100%" }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "120px",
            marginBottom: "100px",
            padding:  isUnder992px ? "0px 0px" : "0px 78px",
          }}
        >
          <Box
            sx={{
              maxWidth:  "320px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress sx={{width:'80px',height:'80px',color:'#CAC4D0'}} />

            <Box>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontStyle:"normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                  color: "#FFFFFF",
                  marginTop: 3,
                  opacity: 0.6,
                  textAlign: "center",
                  padding:'0px',
                }}
              >
                {msg}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  )
}

export default CircularLoader