import React, { useState, useEffect } from "react";
import {Menu, MenuItem, ListItemIcon, ListItemText, Divider } from "@mui/material";
import RepeatIcon from "@mui/icons-material/Repeat";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ShareIcon from "@mui/icons-material/Share";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import AddIcon from '@mui/icons-material/Add';
import presentationIcon from '../../../../assets/Button/presentation.png'
import imageIcon from '../../../../assets/Button/Picture.png'
import videoIcon from '../../../../assets/Button/videoIcon.png'
import audioIcon from '../../../../assets/Button/Music Library.png'

const AdditionalOptionForLastConversationForTrackWebsiteForSmallScreen = ({moreoption,setMoreOption,selectedAction,setSelectedAction,copyFunc,ContinueChat,isUnder991px,prompt}) => {

    const handleClose = () => {
      setMoreOption(false);
    };


    // const HandleLikeClickInSmallScreen = () =>{
    //     const action = 'like';
    //     setSelectedAction(action);
    //     onUserAction(action);
    //     handleClick(1)
    //     handleClose()
    // }

    // const HandleDislikeClickInSmallScreen = () =>{
    //     const action = 'dislike';
    //     setSelectedAction(action);
    //     onUserAction(action);
    //     handleClick(2)
    //     handleClose()
    // }

    // const handleTempShare = () => {
    //   alert("coming soon!..")
    // }

    return (
    <div>
          <Menu 
          anchorEl={moreoption}
          open={Boolean(moreoption)}
          // open={moreoption}
          onClose={handleClose}
          PaperProps={{
            style: {
              backgroundColor: '#2F355F',
              color: 'white',
              padding: '4px',
              borderRadius: '8px',
              marginTop: '20px',
              marginLeft :"20px",
              border: '1px solid rgba(255, 255, 255, 0.2)'
            },
          }}
          anchorOrigin={{
            vertical: 'top',       // Open from the top of the button
            horizontal: 'right',    // Align to the right
          }}
          transformOrigin={{
            vertical: 'bottom',     // The menu will "pop" up from the bottom
            horizontal: 'left',    // The menu will align to the right
          }}
          >
          {/* Menu Items */}
          <MenuItem style={{padding:"8px 8px", backgroundColor: 'transparent' }} 
           onClick={() => {ContinueChat(`Create a PPT based on the following content \n ${prompt}`); 
                        handleClose()
                        }}>
            <ListItemIcon style={{padding:"0px "}}>
            <img src={presentationIcon} alt="presentationIcon" />
            </ListItemIcon>
            <ListItemText primary="Create PPT" />
          </MenuItem>


          <MenuItem style={{padding:"8px 8px", backgroundColor: 'transparent' }} 
           onClick={() => {ContinueChat(`Create an audio based on the following content \n ${prompt}`); 
                        handleClose()
                        }}
            >
            <ListItemIcon style={{padding:"0px "}}>
            <img src={audioIcon} alt="audioIcon" />
            </ListItemIcon>
            <ListItemText primary="Create audio" />
          </MenuItem>

          <MenuItem style={{padding:"8px 8px", backgroundColor: 'transparent' }} 
           onClick={() => {ContinueChat(`Create a video based on the following content \n ${prompt}`); 
                        handleClose()
                        }}
            >
            <ListItemIcon style={{padding:"0px "}}>
            <img src={videoIcon} alt="videoIcon" />
            </ListItemIcon>
            <ListItemText primary="Generate video" />
          </MenuItem>

          <MenuItem style={{padding:"8px 8px", backgroundColor: 'transparent' }} 
           onClick={() => {ContinueChat(`Create an image based on the following content \n ${prompt}`); 
                        handleClose()
                        }}
            >
            <ListItemIcon style={{padding:"0px "}}>
            <img src={imageIcon} alt="imageIcon" />
            </ListItemIcon>
            <ListItemText primary="Generate image" />
          </MenuItem>
  
          <MenuItem   style={{padding:"8px 8px", backgroundColor: 'transparent' }} 
          onClick={()=>{copyFunc(); handleClose()}}
          >
            <ListItemIcon>
              <ContentCopyIcon style={{ color: 'white' }}  />
            </ListItemIcon>
            <ListItemText primary="Copy" />
          </MenuItem>
  
          <MenuItem 
          onClick={() => { alert("Coming soon!.."); handleClose()}}
          style={{padding:"8px 8px", backgroundColor: 'transparent' }}
          >
            <ListItemIcon>
              <ShareIcon style={{ color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary="Share" />
          </MenuItem>
{/* 
          <Divider  sx={{bgcolor:"#FFFFFF", opacity: 0.2}}  />
  
          <MenuItem 
        //   onClick={HandleLikeClickInSmallScreen} 
           style={{padding:"8px 8px", backgroundColor: 'transparent' }}>
            <ListItemIcon>
              {
              selectedAction==="like" ?    <ThumbUpIcon style={{ color: 'white' }} /> : <ThumbUpOffAltIcon style={{ color: 'white' }} /> 
              }
            </ListItemIcon>
            <ListItemText primary="Good response" />
          </MenuItem>

          <MenuItem 
        //   onClick={HandleDislikeClickInSmallScreen} 
          style={{padding:"8px 8px", backgroundColor: 'transparent' }}>  
            <ListItemIcon>
            {
                selectedAction==="dislike" ?   <ThumbDownIcon style={{ color: 'white' }} /> :  <ThumbDownOffAltIcon style={{ color: 'white' }} /> 
            }  
            </ListItemIcon>
            <ListItemText primary="Bad response" />
          </MenuItem> */}
  
        </Menu>
    </div>
  )
}

export default AdditionalOptionForLastConversationForTrackWebsiteForSmallScreen