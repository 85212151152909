import { Box, Button, CardMedia, Typography, Tooltip, Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import React, { useState, useEffect } from "react";
import RepeatIcon from "@mui/icons-material/Repeat";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ShareIcon from "@mui/icons-material/Share";
import { useWorkspace } from "../../../contexts/WorkspaceContext";
import ShareModalForSocialMedia from "./ShareModalForSocialMedia";
import { trackSearchInteraction } from "../../../config/analytics";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { useMediaQuery } from "react-responsive";
import { GetAccessToken } from "./apicall";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AdditionalOptionForLastConversationInSmallScreen from "./AdditionalOptionForLastConversationInSmallScreen";
import LimitedAdditionalOptionsExceptLastConversationsInSmallScreen from "./LimitedAdditionalOptionsExceptLastConversationsInSmallScreen";


function LimitedAdditionalOptionsExceptLastConversations({ message, onUserAction }) {
  const content = (message?.sender === "modalX" && message.content) || message?.agent
  const [clickedItems, setClickedItems] = useState({});
  const [clickedItems1, setClickedItems1] = useState({});
  const [selectedAction, setSelectedAction] = useState()
  const [moreoption, setMoreOption] = useState(false);
  const { sending, shareModalforSocialMedia, setShareModalforSocialMedia, setEditClick, setSending, setWaitThread, setCurrentThread, sendMessage, currentThread, setShowCreditRecharge, setArrayForLinksAvalible, setHasLink } = useWorkspace()
  // const [state , setState] = useState(message)
  const isXsOrSm = useMediaQuery({ maxWidth: 1600 });
  const isUnder991px = useMediaQuery({ maxWidth:991});


  // const decodeUnicode = (str) => {
  //   // const stringWithoutDoubleBackslashes = str.split("\\\")
  //   const stringNewLine = str.split("\\n").join("\n")
  //   let stringNewLineWithAnchorTag = stringNewLine.replace(/\\u[\dA-F]{4}|u[\dA-F]{4}/gi, (match) => {
  //     const hexCode = match.replace(/\\?u/g, ''); // Remove '\u' or 'u'
  //     return String.fromCharCode(parseInt(hexCode, 16)); // Convert hex code to character
  //   });
  //   const finalString = stringNewLineWithAnchorTag.replace(/\\/g, '');
  //   return finalString
  // };

  const decodeUnicode = (str) => {
    // Ensure str is a valid string, if not, return an empty string or handle it as needed
    if (!str || typeof str !== "string") return str || "";

    // Handle new line replacements
    const stringNewLine = str.split("\\n").join("\n");

    // Handle unicode characters
    let stringNewLineWithAnchorTag = stringNewLine.replace(/\\u[\dA-F]{4}|u[\dA-F]{4}/gi, (match) => {
      const hexCode = match.replace(/\\?u/g, ''); // Remove '\u' or 'u'
      return String.fromCharCode(parseInt(hexCode, 16)); // Convert hex code to character
    });

    // Remove any remaining backslashes
    const finalString = stringNewLineWithAnchorTag.replace(/\\/g, '');

    return finalString;
  };


  // Function to decode HTML entities
  const decodeContent = (content) => {
    return decodeUnicode((content));
  };

  const decodedContent = decodeContent(content);

  const handleShare = () => {
    setShareModalforSocialMedia(true)
  }

  const [copy, setCopy] = useState(false);

  const copyFunc = () => {
    // Copy decodedContent to clipboard
    navigator.clipboard.writeText(decodedContent).then(() => {
      setCopy(true); // Set copy to true to indicate the content has been copied
      setTimeout(() => setCopy(false), 2000); // Reset after 2 seconds
    });
  };

  // Toggle the clicked status for the item with the given id
  const handleClick = (id) => {
    setClickedItems((prevState) => ({
      ...prevState,
      [id]: !prevState[id], // Toggle between true and false
    }));
  };

  const handleClick1 = (id) => {
    setClickedItems1((prevState) => ({
      ...prevState,
      [id]: !prevState[id], // Toggle between true and false
    }));
  };

  const findUrl = (msg) => {
    const urlRegex = /(https?:\/\/[^']*?\.(mp3|mp4|pptx|jpg|jpeg|png|txt|ppt))/g;
    const match = urlRegex.exec(msg);
    return match ? match[1] : null;
  };

  const getFileFormat = (url) => {
    // Extract the file extension from the URL
    const parts = url.split('.');
    if (parts.length > 1) {
      return parts[parts.length - 1]; // Return the last part as the file format
    } else {
      return ''; // Return empty string if no file extension found
    }
  };

  const extractLinks = async (inputString) => {
    setArrayForLinksAvalible([])
    // Regular expressions for each file type
    const pptRegex = /https?:\/\/[^\s]+\.pptx\b/g;
    const jpegRegex = /https?:\/\/[^\s]+\.jpeg\b/g;
    const mp4Regex = /https?:\/\/[^\s]+\.mp4\b/g;
    const mp3Regex = /https?:\/\/[^\s]+\.mp3\b/g;
    
    // Extract and push matches into their respective arrays
    const pptMatches = inputString?.match(pptRegex);
    if (pptMatches) {
      setArrayForLinksAvalible((prev)=> [...prev, "pptx"])
    }
    
    const jpegMatches = inputString?.match(jpegRegex);
    if (jpegMatches) {
      setArrayForLinksAvalible((prev)=> [...prev, "image"])
    }
    
    const mp4Matches = inputString?.match(mp4Regex);
    if (mp4Matches) {
      setArrayForLinksAvalible((prev)=> [...prev, "video"])
    }
    const mp3Matches = inputString?.match(mp3Regex);
    if (mp3Matches) {
      setArrayForLinksAvalible((prev)=> [...prev, "audio"])
    }
    
    return 
    };

  const handleSubmit = async (msg) => {
    const input = msg;
    trackSearchInteraction(input);
    setSending(true);
    setWaitThread(true);
    if (input.trim()) {
      const latestEntry = { sender: "user", content: input };
      // setInput(""); // Reset input field
      setCurrentThread((prevThread) => [...prevThread, latestEntry]);
      const entryResponse = await sendMessage(latestEntry).catch(console.error);
      if(findUrl(entryResponse.content)){
        setHasLink(true)
        await extractLinks(entryResponse.content)
        }
      if (entryResponse) {
        // Check if entryResponse contains a daily limit exceeded message
        if (
          entryResponse &&
          entryResponse.content &&
          findUrl(entryResponse.content) &&
          findUrl(entryResponse.content).includes("dailylimitexceeded")
        ) {
          // Determine the file format from the URL (assuming findUrl() returns the URL)
          const fileFormat = getFileFormat(findUrl(entryResponse.content));

          // Prepare the response message
          const responseMessage = {
            content: `Hi there,\n\nIt looks like you’ve reached your daily limit for generating ` + fileFormat + ` files. We’re really sorry about that! We know how important uninterrupted access is for you.\n\nTo keep enjoying all the great benefits and have a seamless experience, we’d love for you to consider upgrading to our premium plan.\n\nThank you so much for your understanding and support. We truly value having you with us and are excited to continue providing you with the best service possible.\n\nWarm regards,\n\nThe ModalX Team`,
            sender: 'modalX'
          };

          // Update the current thread with the response message
          setCurrentThread((prevThread) => [...prevThread, responseMessage]);
          setShowCreditRecharge(true);
        } else {
          // If the response does not contain a daily limit exceeded message, proceed as usual
          setCurrentThread((prevThread) => [...prevThread, entryResponse]);
        }
        setSending(false);
      } else {
        // If there's no entryResponse, indicate processing failure
        setCurrentThread((prevThread) => [...prevThread, { content: "Unable to process your query!..", sender: 'modalX' }]);
        setSending(false);
      }
    }
    setWaitThread(false);
  };

  const regenerateResponse = () => {
    const promptFromUser = message?.user || currentThread[currentThread.length - 2]?.content;
    handleSubmit(promptFromUser);
  }

  const editResponse = () => {
    setEditClick(true)
  }

  const handleTempShare = () => {
    alert("coming soon!..")
  }



  // const colorMap = {
  //   1: "blue",   // Color for id 1
  //   2: "red",    // Color for id 2
  //   // You can add more ids with colors here
  // };

  const findUrls = (msg) => {
    const urlRegex = /(https?:\/\/[^']*?\.(mp4|jpg|jpeg|png))/g;
    const match = urlRegex.exec(msg);
    return match ? match[1] : null;
  };



  const messageContent = findUrls(message.content)
  const messageAgent = findUrls(message.agent)

  let shareButtonHide = ''
  if (messageContent !== null || messageAgent !== null) {
    shareButtonHide = false
  }
  else {

    shareButtonHide = true

  }


  const data1 = [
    { id: 1, image: <ThumbUpOffAltIcon />, Hovertitle: 'Good response', },
    { id: 2, image: <ThumbDownOffAltIcon />, Hovertitle: 'Bad response', },
  ];

  const data2 = [
    // { id: 1, image: <EditIcon />, Hovertitle: 'Edit', click: editResponse },
    { id: 2, image: <ContentCopyIcon />, Hovertitle: copy ? "Copied" : 'Copy', click: copyFunc },
    // { id: 3, image: <RepeatIcon />, Hovertitle: 'Regenerate response', click: regenerateResponse },
    // { id: 4, image: <ShareIcon />, Hovertitle: 'Share', click: handleTempShare },
  ];

  const getColor = (id) => {
    if (selectedAction === 'like' && id === 1) return "blue";
    if (selectedAction === 'dislike' && id === 2) return "red";
    return "#CAC4D0"; // Default color if none match
  };

  useEffect(() => {
    // Set the selectedAction based on the message.action when the component mounts
    if (message?.action) {
      setSelectedAction(message.action);
    }
  }, [message.action]);

  useEffect(() => {
    const GetAccessTokenforLikedin = async () => {
      const response = await GetAccessToken()
      console.log(response?.data?.data)
      if (response?.data?.data?.access_token && response?.data?.data?.sub_id) {
        localStorage.setItem("accessToken", response?.data?.data?.access_token)
        localStorage.setItem("sub", response?.data?.data?.sub_id)
      }
    }
    GetAccessTokenforLikedin()
  }, [])
  
  const handleClickMoreOption = (event) => {
    setMoreOption(event.currentTarget); // Get button element
  }; 

  return (
    <>
      <Box sx={{ display: sending ? 'none' : 'block'}}>

        {
          isUnder991px ? 
          <Box
          sx={{
            maxWidth: '600px',
            height: "48px",
            display: "flex",
            justifyContent: "space-between",
            marginLeft: message.sender ?  "12px" : '12px',
            marginTop: "12px",
          }}
        >
              <Box
                sx={{
                  width: "36px",
                  height: "36px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor : moreoption ?   "#2F355F" : "#1F233E",
                  borderRadius: "50%",
                  "&:hover": {
                    backgroundColor:  "#282D51",
                    cursor: "pointer"
                  },

                }}
              >
                <Tooltip
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#E6E0E9",
                        color: "#17182C",
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontWeight: 500,
                        padding: "4px 8px 4px 8px",
                        borderRadius: "4px",
                        maxWidth: "104px",
                        textAlign: "left",
                      },
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: 'center',
                    }}
                    onClick={handleClickMoreOption}>
                    <MoreVertIcon />
                  </Box>
                  
                </Tooltip>
              </Box>
          </Box> 
              :
              <Box style={{backgroundColor: ""}}
              sx={{
                maxWidth: '600px',
                height: "48px",
                display: "flex",
                justifyContent: "space-between",
                marginLeft: message.sender ? isXsOrSm ? "98px" : '98px' : '98px',
                marginRight: message.sender ? isXsOrSm ? "50px" : '50px' : '50px',
                // marginLeft: message.sender ? isXsOrSm ?  "10%" : '10.5%' : '8.5%',
                // backgroundColor: message.sender ?  isXsOrSm ?  "blue" : "black"  : "red",
                marginTop: "12px",
              }}
            >
              <Box
                sx={{
                  width: "156px",
                  height: "48px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {data1.map((e) => (
                  <Box 
                    // onClick={() => handleClick(e.id)}
                    onClick={() => {
                      const action = e.id === 1 ? 'like' : 'dislike';
                      setSelectedAction(action);
                      onUserAction(action);
                      handleClick(e.id); // Call handleClick after setting the action
                    }}
                    key={e.id}
                    sx={{
                      width: "48px",
                      height: "48px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // backgroundColor: clickedItems[e.id] ? "#2F355F" : "#1F233E",
                      backgroundColor : e.id === 1 && selectedAction === 'like' || e.id === 2 && selectedAction === 'dislike' ? '#2F355F' : '#1F233E',
                      borderRadius: "50%",
                      "&:hover": {
                        backgroundColor: clickedItems[e.id] ? "" : "#282D51",
                        cursor: "pointer"
                      },
    
                    }}
                  >
                    <Tooltip
                      title={e.Hovertitle}
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#E6E0E9",
                            color: "#17182C",
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontWeight: 500,
                            padding: "4px 8px 4px 8px",
                            borderRadius: "4px",
                            maxWidth: "104px",
                            textAlign: "left",
                          },
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: 'center',
                          // color: clickedItems[e.id]
                          // ? colorMap[e.id] || "blue" // Use color from colorMap or default to blue
                          // : "#CAC4D0",   
                          // color : getColor(e.id)                      
                          // "&:hover": {
                          //   color: "#FFFFFF",
                          //   cursor : "pointer"
                          // },
                        }}
                        // onClick={(e) => onUserAction(e.id === 1?'like':'dislike')}
                        // onClick={() => {
                        //   const action = e.id === 1 ? 'like' : 'dislike';
                        //   setSelectedAction(action);
                        //   onUserAction(action);
                        // }}
                      >
                        {(e.id === 1 && selectedAction === 'like') ? <ThumbUpIcon /> : (e.id === 2 && selectedAction === 'dislike') ? <ThumbDownIcon /> : e.image}
    
                      </Box>
                    </Tooltip>
                  </Box>
                ))}
                {data2.map((e) => (
                  <Box
                    // onClick={() => handleClick1(e.id)}
                    onClick={(event) =>{
                      if(e.click){
                        e.click(event)
                      }
                      handleClick1(e.id)
                    }}
                    key={e.id}
                    sx={{
                      width: "48px",
                      height: "48px",
                      display: "flex",
                      justifyContent: "center",
                      display: e.id === 4 && shareButtonHide == true ? 'none' : '',
                      alignItems: "center",
                      backgroundColor: clickedItems1[e.id] ? "#2F355F" : "#1F233E",
                      borderRadius: "50%",
                      "&:hover": {
                        backgroundColor: clickedItems1[e.id] ? "" : "#282D51",
                        cursor: "pointer"
                      },
                    }}
                  >
                    <Tooltip
                      title={e.Hovertitle}
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#E6E0E9",
                            color: "#17182C",
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontWeight: 500,
                            padding: "4px 8px 4px 8px",
                            borderRadius: "4px",
                            textAlign: "left",
                          },
                        },
                      }}
                    >
                      <Box
                        // onClick={e.click}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: 'center',
                          color: "#CAC4D0",
                          marginTop: '12px',
                          "&:hover": {
    
                          },
                        }}
                      >
                        {e.image}
                      </Box>
                    </Tooltip>
                  </Box>
                ))}
              </Box>

              <Box 
                sx={{
                  width: shareButtonHide == true ? '171px' : "228px",
                  height: "48px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {/* {data2.map((e) => (
                  <Box
                    // onClick={() => handleClick1(e.id)}
                    onClick={(event) =>{
                      if(e.click){
                        e.click(event)
                      }
                      handleClick1(e.id)
                    }}
                    key={e.id}
                    sx={{
                      width: "48px",
                      height: "48px",
                      display: "flex",
                      justifyContent: "center",
                      display: e.id === 4 && shareButtonHide == true ? 'none' : '',
                      alignItems: "center",
                      backgroundColor: clickedItems1[e.id] ? "#2F355F" : "#1F233E",
                      borderRadius: "50%",
                      "&:hover": {
                        backgroundColor: clickedItems1[e.id] ? "" : "#282D51",
                        cursor: "pointer"
                      },
                    }}
                  >
                    <Tooltip
                      title={e.Hovertitle}
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#E6E0E9",
                            color: "#17182C",
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontWeight: 500,
                            padding: "4px 8px 4px 8px",
                            borderRadius: "4px",
                            textAlign: "left",
                          },
                        },
                      }}
                    >
                      <Box
                        // onClick={e.click}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: 'center',
                          color: "#CAC4D0",
                          marginTop: '12px',
                          "&:hover": {
    
                          },
                        }}
                      >
                        {e.image}
                      </Box>
                    </Tooltip>
                  </Box>
                ))} */}
              </Box>

            </Box>
}

      </Box>
      <ShareModalForSocialMedia message={message} />
    {isUnder991px && <LimitedAdditionalOptionsExceptLastConversationsInSmallScreen moreoption={moreoption} setMoreOption={setMoreOption} decodedContent={decodedContent} setSelectedAction={setSelectedAction} selectedAction={selectedAction} onUserAction={onUserAction} editResponse={editResponse} message={message} copyFunc={copyFunc} handleShare={handleShare} regenerateResponse={regenerateResponse} handleClick={handleClick} isUnder991px={isUnder991px}/>
 } 
    </>


  );
}

export default LimitedAdditionalOptionsExceptLastConversations;