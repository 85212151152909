import React, { useState, useEffect, useRef } from "react";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import { useWorkspace } from "../../../contexts/WorkspaceContext";
import PromtInputFileUploader from "./PromtInputFileUpliader";
import { useUI } from "../../../contexts/UIContext";
import axios from "axios";
import { useCreditRecharge } from "../../../contexts/CreditRechageContext";
import sourceUrl from "../../../api/Source.api";
import ServicesForDefaultWindow from "./ServicesForDefaultWindow";
import { prompt_Input_bg_color_for_darkmode } from "../../../constants/colors";
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { trackSearchInteraction } from "../../../config/analytics";
import Suggested from "../mobileResponsive/Suggested";
import {useMediaQuery} from '@mui/material'


const PromptInput = () => {
  const [files, setFiles] = useState([]);
  const [fileProcessing,setFileProcessing] = useState(false);
  const [theme,setTheme] = useState(localStorage.getItem("theme"));
  const isLoggedIn = localStorage.getItem('isLoggedIn');
  const token  = localStorage.getItem('token');
  const { toggleTheme, currentTheme } = useUI();
  const { showCreditRecharge, setShowCreditRecharge } = useCreditRecharge();
  const {
    Today,
    currentThread,
    setCurrentThread,
    waitThread,
    setWaitThread,
    sendMessage,
    checkUrlStatus,
    threadFiles,
    setSending,
    sending,
    threadId,
    InvokeMatrixApi,
    setCurrentHistoryId,
    suggestedPrompt,
    setShowSuggested,
    // setHasLink,
    // setArrayForLinksAvalible
  } = useWorkspace(); // Access sendPrompt function from WorkspaceContext
  const textareaRef = useRef(null);
  const mobileView = useMediaQuery('(max-width:992px)');

  const fetchAllHistory = async() => {
    try {
      const res = await axios.get(`${sourceUrl}/chat/summary`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      console.log("print from summary",res)
      console.log(res);

      if (res.data.status) { // Check if the response status is true
        const { today, yesterday, this_week, this_month } = res.data.data;
        if(today.length ){
          // localStorage.setItem('current_history_id',today[0].id);
          if(Today.length && Today.length < today.length){
            setCurrentHistoryId(today[0].id);
          }
          
        }
      } else {
        // Handle API response error
      }
      // ==========================Invoke matrix api ===================================================
      // Invoke matrix API
        await InvokeMatrixApi(res, res, 'POST',threadId, 'summary', {});
      // ===============================================================================================
    } catch (error) {
      console.error("Error fetching history:", error);
      // ==========================Invoke matrix api ===================================================
        // Invoke matrix API
        await InvokeMatrixApi(error , error.data, 'POST',threadId, 'summary', {});
      // ===============================================================================================
      // Handle fetch error
    }
  }

  // useEffect(() => {
  //   fetchAllHistory();
  // }, [ currentThread])
  

  const [input, setInput] = useState("");

  useEffect(() => {
    setInput(suggestedPrompt);
  }, [suggestedPrompt])
  

  useEffect(() => {
    setTheme(currentTheme);
  }, [currentTheme])
  
  
  

  useEffect(() => {
    setFiles(threadFiles);
  }, [threadFiles]);
  
  useEffect(() => {
    // let intervalId;
  
    // const checkFileStatus = async () => {
    //   const processedFiles = await Promise.all(
    //     files.map(async (file) => {
    //     const url = file.url ;
    //     const fileId = file.fileId ;
    //       // const res = await checkUrlStatus(file.url);
    //       // return res;
    //       const res = await fetch("https://www.modalx.ai/status", {
    //         method: "POST",
    //         headers: {
    //           "Content-Type": "application/json",
    //           "Authorization":`Bearer ${token}`
    //         },
    //         body: JSON.stringify({ fileId, url }),
    //       });
    //       return res.status === 200 || res.status === 500 ;
    //     })
    //   );
  
    //   const allFilesProcessed = processedFiles.every((res) => res);
  
    //   if (allFilesProcessed) {
    //     clearInterval(intervalId);
    //   }
  
    //   setFileProcessing(allFilesProcessed);
    // };
  
    // // Run once initially
    // checkFileStatus();
  
    // // Set interval to periodically check file status
    // intervalId = setInterval(checkFileStatus, 3000);
  
    // return () => {
    //   clearInterval(intervalId);
    //   setFileProcessing(false);
    // };
    const isAnyFileProcessing = files?.some(file => file && file.status === 'processing');
    setFileProcessing(isAnyFileProcessing);
  }, [files]);

  // console.log("aaaaaaaaaaaaaaaaaaaaaaa",theme);

  // Ref to keep interval IDs
  const intervalRef = useRef({});

  // Listen for changes in the currentThread, set interval accordingly
  // useEffect(() => {
  //   console.log("currentThread changed");
  //   currentThread.forEach(async (message) => {
  //     if (
  //       message.sender === "modalX" &&
  //       message.file?.status === "processing" &&
  //       !intervalRef.current[message.file.url]
  //     ) {
  //       console.log("Setting interval for", message.file.url);
  //       intervalRef.current[message.file.url] = setInterval(async () => {
  //         console.log("Checking status for", message.file.url);
  //         const isProcessed = await checkUrlStatus(message.file.url);

  //         if (isProcessed) {
  //           const currRes = await fetch(`${sourceUrl}/status`, {
  //             method: "POST",
  //             headers: {
  //               "Content-Type": "application/json",
  //               "Authorization":`Bearer ${token}`
  //             },
  //             body: JSON.stringify({ fileId:message.file.id, url:message.file.url }),
  //           });
  //           const responseData = await currRes.json();
           
  //           if(responseData.data.status === "completed"){
  //             setCurrentThread((prevThread) =>
  //               prevThread.map((msg) =>
  //                 msg.sender === "modalX" && msg.file?.url === message.file.url
  //                   ? { ...msg, file: { ...msg.file, status: "processed" } }
  //                   : msg
  //               )
  //             );
  //           }
  //           else{
  //             setCurrentThread((prevThread) =>
  //               prevThread.map((msg) =>
  //                 msg.sender === "modalX" && msg.file?.url === message.file.url
  //                   ? { ...msg, file: { ...msg.file, status: "failed" } }
  //                   : msg
  //               )
  //             );
  //           }
  //           console.log("File processed:", message.file.url);
  //           clearInterval(intervalRef.current[message.file.url]);
  //           delete intervalRef.current[message.file.url]; // Clean up for this file URL

            
  //         }
  //       }, 5000);
  //     }
  //   });

  //   return () => {
  //     // Stop all intervals
  //     Object.values(intervalRef.current).forEach(clearInterval);
  //     intervalRef.current = {}; // Clear all interval IDs
  //   };
  // }, [currentThread, setCurrentThread, checkUrlStatus]);

  // useEffect(() => {
  //   currentThread.forEach(async (message) => {
  //     if (
  //       message.sender === "modalX" &&
  //       message.file?.status === "processing" &&
  //       !intervalRef.current[message.file.urls]
  //     ) {
  //       console.log("Setting interval for", message.file.urls);
  //       intervalRef.current[message.file.urls.join('_')] = setInterval(async () => {
  //         console.log("Checking status for", message.file.url);
  
      
  //             const currRes = await fetch(`${sourceUrl}/status/batch`, {
  //               method: "POST",
  //               headers: {
  //                 "Content-Type": "application/json",
  //                 "Authorization":`Bearer ${token}`
  //               },
  //               body: JSON.stringify({ fileId:message.file.id, urls:message.file.urls }),
  //             });
  //             const responseData = await currRes.json();
          
  //             console.log(responseData, 'multipleResponseAnswer' );

            
          // if(responseData.data[0].status === "completed"){
          //     setCurrentThread((prevThread) =>
          //       prevThread.map((msg) =>
          //         msg.sender === "modalX" && msg.file?.url === message.file.url
          //           ? { ...msg, file: { ...msg.file, status: "processed" } }
          //           : msg
          //       )
          //     );
          //   }
          //   else{
          //     setCurrentThread((prevThread) =>
          //       prevThread.map((msg) =>
          //         msg.sender === "modalX" && msg.file?.url === message.file.url
          //           ? { ...msg, file: { ...msg.file, status: "failed" } }
          //           : msg
          //       )
          //   );          
          //   console.log("File processed:", message.file.url);
          //   clearInterval(intervalRef.current[message.file.urls.join('_')]);
          //   delete intervalRef.current[message.file.urls.join('_')]; // Clean up for this file URL
          // }

  //         responseData.data.forEach((responseItem, index) => {
  //           console.log(responseItem, "==================responseItem====================")
  //           if (responseItem.status === "completed") {
  //             setCurrentThread((prevThread) =>
  //               prevThread.map((msg) =>
  //                 msg.sender === "modalX" && msg.file?.urls[index] === responseItem.url
  //                   ? { ...msg, file: { ...msg.file, status: "processed" } }
  //                   : msg
  //               )
  //             );
  //           }else if(responseItem.status === "pending"){
              
  //           }
  //            else {
  //             setCurrentThread((prevThread) =>
  //               prevThread.map((msg) =>
  //                 msg.sender === "modalX" && msg.file?.urls[index] === responseItem.url
  //                   ? { ...msg, file: { ...msg.file, status: "failed" } }
  //                   : msg
  //               )
  //             );
  //           }
  //         });
          
  //       }, 5000);
  //     }
  //   });    
  //   return () => {
  //     // Stop all intervals
  //     Object.values(intervalRef.current).forEach(clearInterval);
  //     intervalRef.current = {}; // Clear all interval IDs
  //   };
  // }, [currentThread, setCurrentThread, checkUrlStatus]);

  //logic failed in one delay and one complete 

  const findUrls = (msg) => {
    const urlRegex = /(https?:\/\/[^']*?\.(mp3|mp4|pptx|jpg|jpeg|png|txt|ppt|pdf))/g;
    const matches = msg.match(urlRegex); // Use match to find all occurrences
    // console.log(matches, "=============================matches===================");
    return matches ? matches : null; // Return all matches or null if no matches
  }


  useEffect(() => {
    // console.log("currentThread changed", currentThread);
    currentThread.forEach(async (message) => {
      if (
        message.content &&
        findUrls(message.content) &&
        message.sender === "modalX" &&
        message.status === "processing"
        && !intervalRef.current[message.file]
      ) {

       let newurlArray = message.file?.map((ele)=> {
        // console.log(ele.url)
        return ele.url
       })

      //  console.log(newurlArray, "====================Url Array============================")

        let count = 0;
        intervalRef.current[message.file] = setInterval(async () => {
          console.log("Checking status for", message.file);
  
          const currRes = await fetch(`${sourceUrl}/status/batch`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify({ fileId: message.file?.id, urls: newurlArray }),
          });
          const responseData = await currRes.json();
  
          console.log(responseData, 'responseData');
  
          // Process each response individually
          responseData.data?.forEach((responseItem, index) => {
            // console.log(responseItem, "==================responseItem====================");
           
  
            if (responseItem.status === "completed") {
              // Mark the specific URL as processed
              count++;
              setCurrentThread((prevThread) =>
                prevThread.map((msg) =>
                  msg.sender === "modalX" && msg.file?.some((file) => file.url === responseItem.url)
                    ?       {
                      sender: msg.sender,
                      content: msg.content,
                      status: msg.status,
                      settings_data: msg.settings_data, 
                      file: msg.file.map((file) =>
                        file.url === responseItem.url
                          ? { ...file, status: "completed" } // Update the file's status
                          : file
                      ),
                    }
                  : msg
                )
              )
              // console.log(currentThread, "++currentThread==========")
              // console.log("File processed:", responseItem.url);
  
              // If all URLs are processed, stop the interval for that set of URLs
              if (!responseData.data?.some((item) => item.status === "pending")) {
                clearInterval(intervalRef.current[message.file]);
                delete intervalRef.current[message.file];
              }
  
            } else if (responseItem.status === "pending") {
              // Re-fetch pending URLs in the next interval, no action needed here
              console.log("Status is still pending for:", responseItem);
              setCurrentThread((prevThread) =>
                prevThread.map((msg) =>
                  msg.sender === "modalX" && msg.file?.some((file) => file.url === responseItem.url)
                    ?       {
                      sender: msg.sender,
                      content: msg.content,
                      status: msg.status,
                      settings_data: msg.settings_data, 
                      file: msg.file.map((file) =>
                        file.url === responseItem.url
                          ? { ...file, status: "pending", progress: responseItem?.progress } // Update the file's status
                          : file
                      ),
                    }
                  : msg
                )
              )
  
            } else if (responseItem.status === "failed") {
              count++;
              // Mark the specific URL as failed
              // setCurrentThread((prevThread) =>
              //   prevThread.map((msg) =>
              //     msg.sender === "modalX" && msg.file.url === responseItem.url
              //       ? { ...msg, file: { ...msg.file, status: "failed" } }
              //       : msg
              //   )
              // );
              setCurrentThread((prevThread) =>
                prevThread.map((msg) =>
                  msg.sender === "modalX" && msg.file?.some((file) => file.url === responseItem.url)
                    ?       {
                      sender: msg.sender,
                      content: msg.content,
                      status: msg.status,
                      file: msg.file.map((file) =>
                        file.url === responseItem.url
                          ? { ...file, status: "failed" } // Update the file's status
                          : file
                      ),
                    }
                  : msg
                )
              )
  
              // console.log("File failed:", responseItem.url);
  
              // Remove the failed URL from further checking
              if (!responseData.data?.some((item) => item.status === "pending")) {
                clearInterval(intervalRef.current[message.file]);
                delete intervalRef.current[message.file];
              }
            }
            
          });

           // Check if all URLs are either completed or failed
        if ( count  >= message.file?.length) {
          // Clear the interval for these URLs when all are completed or failed
           setCurrentThread((prevThread) =>
                prevThread.map((msg) =>
                  msg.sender === "modalX"
                    ? { ...msg, status: "completed" }
                    : msg
                )
              );
          clearInterval(intervalRef.current[message.file]);
          delete intervalRef.current[message.file];
          console.log("All URLs processed or failed. Clearing interval for:", message.file);
        }

  
        }, 5000);
      }
    });
  
    return () => {
      // Clear all intervals when component unmounts or dependencies change
      Object.values(intervalRef.current).forEach(clearInterval);
      intervalRef.current = {}; // Clear all interval references
    };
  }, [currentThread, setCurrentThread, checkUrlStatus]);

  

  // Handler for the input change
  // const handleInputChange = (e) => {
  //   setInput(e.target.value);
  // };

  const findUrl = (msg) => {
    const urlRegex = /(https?:\/\/[^']*?\.(mp3|mp4|pptx|jpg|jpeg|png|txt|ppt))/g;
    const match = urlRegex.exec(msg);
    return match ? match[1] : null;
  };

  const getFileFormat = (url) => {
    // Extract the file extension from the URL
    const parts = url.split('.');
    if (parts.length > 1) {
      return parts[parts.length - 1]; // Return the last part as the file format
    } else {
      return ''; // Return empty string if no file extension found
    }
  };
  
// const extractLinks = async (inputString) => {
//   setArrayForLinksAvalible([])
//   // Regular expressions for each file type
//   const pptRegex = /https?:\/\/[^\s]+\.pptx\b/g;
//   const jpegRegex = /https?:\/\/[^\s]+\.jpeg\b/g;
//   const mp4Regex = /https?:\/\/[^\s]+\.mp4\b/g;
//   const mp3Regex = /https?:\/\/[^\s]+\.mp3\b/g;
  
//   // Extract and push matches into their respective arrays
//   const pptMatches = inputString?.match(pptRegex);
//   if (pptMatches) {
//     setArrayForLinksAvalible((prev)=> [...prev, "pptx"])
//   }
  
//   const jpegMatches = inputString?.match(jpegRegex);
//   if (jpegMatches) {
//     setArrayForLinksAvalible((prev)=> [...prev, "image"])
//   }
  
//   const mp4Matches = inputString?.match(mp4Regex);
//   if (mp4Matches) {
//     setArrayForLinksAvalible((prev)=> [...prev, "video"])
//   }
//   const mp3Matches = inputString?.match(mp3Regex);
//   if (mp3Matches) {
//     setArrayForLinksAvalible((prev)=> [...prev, "audio"])
//   }
  
//   return 
//   };

  // Handler for the input submit
  const handleSubmit = async () => {
    // setArrayForLinksAvalible([])
    trackSearchInteraction(input);
    setSending(true);
    setWaitThread(true);
    if (input.trim()) {
      const latestEntry = { sender: "user", content: input };
      setInput(""); // Reset input field
      setCurrentThread((prevThread) => [...prevThread, latestEntry]);
      const entryResponse = await sendMessage(latestEntry).catch(console.error);
      // if(findUrl(entryResponse.content)){
      // setHasLink(true)
      // await extractLinks(entryResponse.content)
      // }
      if (entryResponse) {
        // Check if entryResponse contains a daily limit exceeded message
        if (
          entryResponse &&
          entryResponse.content &&
          findUrl(entryResponse.content) &&
          findUrl(entryResponse.content).includes("dailylimitexceeded")
        ) {
          // Determine the file format from the URL (assuming findUrl() returns the URL)
          const fileFormat = getFileFormat(findUrl(entryResponse.content));
  
          // Prepare the response message
          const responseMessage = {
            content: `Hi there,\n\nIt looks like you’ve reached your daily limit for generating `+ fileFormat +` files. We’re really sorry about that! We know how important uninterrupted access is for you.\n\nTo keep enjoying all the great benefits and have a seamless experience, we’d love for you to consider upgrading to our premium plan.\n\nThank you so much for your understanding and support. We truly value having you with us and are excited to continue providing you with the best service possible.\n\nWarm regards,\n\nThe ModalX Team`,
            sender: 'modalX'
          };
  
          // Update the current thread with the response message
          setCurrentThread((prevThread) => [...prevThread, responseMessage]);
          setShowCreditRecharge(true);
        } else if(entryResponse.content === ""){
          setCurrentThread((prevThread) => [...prevThread, {content:"There was an error while generating a response",sender:'modalX'}]);
        } else {
          // If the response does not contain a daily limit exceeded message, proceed as usual
          setCurrentThread((prevThread) => [...prevThread, entryResponse]);
        }
        setSending(false);
      } else {
        // If there's no entryResponse, indicate processing failure
        setCurrentThread((prevThread) => [...prevThread, {content:"There was an error while generating a response",sender:'modalX'}]);
        setSending(false);
      }
    }
    setWaitThread(false);
  };
  
  
  // console.log("entryresponse_from_/message api ",currentThread)
  // Optional: Prevent form submit on Enter key
  const handleKeyDown = (e) => {
   
    if (e.key === "Enter" && !e.shiftKey) {
      setShowSuggested(false)
      e.preventDefault();
      handleSubmit();

    }
  };

  useEffect(() => {
    if (textareaRef.current) {

      textareaRef.current.style.height = "auto";

      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [input]);

  return (
    <>
          <style>
        {`
          /* Custom scrollbar styles for the FormControl textarea */
          textarea::-webkit-scrollbar {
            width: 6px;
             background-color: #1F233E;
          }

          textarea::-webkit-scrollbar-thumb {
            background-color: rgba(255, 255, 255, 0.2);
            border-radius: 2px;
            height: 5px;
          }
 
        `}
      </style>
        <div>
        {mobileView ? (
            <Suggested />
            ) : (
            <div style={{ marginBottom: '10px' }}>
              <ServicesForDefaultWindow />
            </div>
            )}
            {!mobileView && (
            <p style={{ margin: 'auto', textAlign: 'center', marginBottom: '20px' }}>
              Generate a summary of your content in One-Click in any of the above formats
            </p>
            )}
      {/* <div style={{marginBottom:'10px', }}>
        <ServicesForDefaultWindow />
      </div>
      <p style={{margin:'auto',textAlign:'center',marginBottom:'20px',}}>Generate a summary of your content  in One-Click in any of the above formats</p> */}
      
      <InputGroup style={{maxWidth:'760px',margin:'auto', padding : '0px 20px 0px 20px' , }}>
      
      <Button style={{backgroundColor:theme === 'dark'?prompt_Input_bg_color_for_darkmode:'',borderRight:'transparent',
      display : "flex", alignItems : 'end',
             borderTopLeftRadius:'16px',borderBottomLeftRadius:'16px',border:'none', }}>
          <PromtInputFileUploader />
          </Button>
        <FormControl
        ref={textareaRef}
        
          as="textarea"
          placeholder={
            !sending && !fileProcessing ? 'What would you like to do today?' : (fileProcessing? 'Your file is processing.. How can I help you?' : (sending ? "Generating the response...":''))
          }
          disabled={sending}
          // style={{
          //   resize: "none",
          //   overflow: "hidden",
          //   height: "auto",
          //   maxHeight: "35vh",
          //   backgroundColor:theme === 'dark'?prompt_Input_bg_color_for_darkmode:'',
          //   border:'none'
          // }}
          style={{
            fontSize : mobileView ? '14px' : '16px',
            resize: "none",
            overflowY: "auto",
            maxHeight :  input.length > 50 ? '184px' :'60px',
            backgroundColor:theme === 'dark'?prompt_Input_bg_color_for_darkmode:'',
            border:'none',
            outline: 'none',
            boxShadow: 'none',
            // scrollbarWidth: 'thin', // Firefox: doesn't allow showing only thumb,
            paddingTop : mobileView ? '20px' : '18px'
            
            
  

            
          }}
          
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleKeyDown}
          aria-label="Message input"
        />
 

        <Button
          variant="outline-secondary"
          onClick={handleSubmit}
          disabled={waitThread || fileProcessing }
          style={{
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderLeft: "none",
            color: waitThread || fileProcessing?'grey':'white',
            borderColor:theme === 'dark'?'#6c757d':'#e0dcdc',
            backgroundColor:theme === 'dark'?prompt_Input_bg_color_for_darkmode:'',
            borderTopRightRadius:'16px',
            borderBottomRightRadius:'16px',
            border:'none',
            display : "flex",
            alignItems : 'end',
            paddingBottom : '19px',
            paddingRight : '20px'
            // marginBottom : '10px'
          }}
          // className="my-0 py-0"
        >
          <SendOutlinedIcon style={{ width: '22px', height: '22px' }} /> 
        </Button>
      </InputGroup>
    </div>
    </>

  );
};

export default PromptInput;



// import React, { useState, useEffect, useRef } from "react";
// import { InputGroup, FormControl, Button } from "react-bootstrap";
// import { useWorkspace } from "../../../contexts/WorkspaceContext";

// const PromptInput = () => {
//   const {
//     currentThread,
//     setCurrentThread,
//     waitThread,
//     setWaitThread,
//     sendMessage,
//     checkUrlStatus,
//   } = useWorkspace(); // Access sendPrompt function from WorkspaceContext
//   const [input, setInput] = useState("");

  

//   // Ref to keep interval IDs
//   const intervalRef = useRef({});

//   // Listen for changes in the currentThread, set interval accordingly
//   useEffect(() => {
//     console.log("currentThread changed");
//     currentThread.forEach(async (message) => {
//       if (
//         message.sender === "modalX" &&
//         message.file?.status === "processing" &&
//         !intervalRef.current[message.file.url]
//       ) {
//         console.log("Setting interval for", message.file.url);
//         intervalRef.current[message.file.url] = setInterval(async () => {
//           console.log("Checking status for", message.file.url);
//           const isProcessed = await checkUrlStatus(message.file.url);

//           if (isProcessed) {
//             console.log("File processed:", message.file.url);
//             clearInterval(intervalRef.current[message.file.url]);
//             delete intervalRef.current[message.file.url]; // Clean up for this file URL

//             setCurrentThread((prevThread) =>
//               prevThread.map((msg) =>
//                 msg.sender === "modalX" && msg.file?.url === message.file.url
//                   ? { ...msg, file: { ...msg.file, status: "processed" } }
//                   : msg
//               )
//             );
//           }
//         }, 5000);
//       }
//     });

//     return () => {
//       // Stop all intervals
//       Object.values(intervalRef.current).forEach(clearInterval);
//       intervalRef.current = {}; // Clear all interval IDs
//     };
//   }, [currentThread, setCurrentThread, checkUrlStatus]);

//   // Handler for the input change
//   const handleInputChange = (e) => {
//     setInput(e.target.value);
//   };

//   // Handler for the input submit
//   const handleSubmit = async () => {
//     setWaitThread(true);
//     if (input.trim()) {
//       const latestEntry = { sender: "user", content: input };
//       setInput(""); // Reset input field
//       setCurrentThread((prevThread) => [...prevThread, latestEntry]);
//       const entryResponse = await sendMessage(latestEntry).catch(console.error);
//       if (entryResponse) {
//         setCurrentThread((prevThread) => [...prevThread, entryResponse]);
//       }
//     }
//     setWaitThread(false);
//     // here add the functionality to check url status and update it in the currentThread
//   };

//   // Optional: Prevent form submit on Enter key
//   const handleKeyDown = (e) => {
//     if (e.key === "Enter" && !e.shiftKey) {
//       e.preventDefault();
//       handleSubmit();
//     }
//   };

//   return (
//     <InputGroup>
//       <FormControl
//         as="textarea"
//         placeholder={
//           waitThread ? "Working on it..." : "What would you like to do today?"
//         }
//         disabled={waitThread}
//         style={{
//           resize: "none",
//           overflow: "hidden",
//           height: "auto",
//           maxHeight: "35vh",
//         }}
//         value={input}
//         onChange={handleInputChange}
//         onKeyDown={handleKeyDown}
//         aria-label="Message input"
//       />
//       <Button
//         variant="outline-secondary"
//         onClick={handleSubmit}
//         disabled={waitThread}
//         style={{
//           borderTopLeftRadius: 0,
//           borderBottomLeftRadius: 0,
//           borderLeft: "none",
//         }}
//         className="my-0 py-0"
//       >
//         <i className="bi bi-arrow-up-circle-fill fs-4" />
//       </Button>
//     </InputGroup>
//   );
// };

// export default PromptInput;