// import React from "react";
// import { Box, Typography, Button } from "@mui/material";
// import RequestDemoNearFooter from "../components/LandingPage/RequestDemoNearFooter";
// import PageLayout from "../components/navFoot/PageLayout";
// function TermsOfService() {
//   return (
//     <>
//       <PageLayout>
//         {/* section 1 */}

//         <Box
//           sx={{
//             width: "100%",
//             // height: "500px",
//             marginTop: "246px",
//             gap: "48px",
//             opacity: "0px",
//             "@media (max-width : 991px)": {
//               marginTop: "156px",

//             },
//           }}
//         >
//           <Typography
//             sx={{
//               fontFamily: "Inter",
//               fontSize: "48px",
//               fontWeight: "500px",
//               lineHeight: "57.6px",
//               letterSpacing: "-0.32",
//               opacity: "80%",
//               textAlign: "center",
//               color: "#FFFFFF",
//               "@media (max-width : 991px)": {
//                 fontSize: "28px",
//               },
//             }}
//           >
//             Terms of Use
//           </Typography>
//         </Box>

//         {/* section 1 */}

//         {/* section 2 */}

//         <Box
//           sx={{
//             width: "100%",
//             marginTop: "40px",
//             gap: "0px",
//             opacity: "0px",
//             "@media (max-width : 991px)": {
//               marginTop: "36px",

//             },
//           }}
//         >
//           <Typography
//             sx={{
//               fontFamily: "Inter",
//               fontSize: "16px",
//               fontWeight: "500px",
//               lineHeight: "24px",
//               textAlign: "center",
//               color: "rgba(255, 255, 255, 1)",
//               "@media (max-width : 991px)": {
//                 fontSize: "14px",
//               },
//             }}
//           >
//             Last Updated: July 26, 2024{" "}
//           </Typography>
//         </Box>

//         {/* section 2 */}

//         {/* section 3 */}
//         <Box
//           display={{
//             display: "flex",
//             justifyContent: "center",
//           }}
//         >
//           <Box
//             sx={{
//               width: "900px",
//               marginTop: "100px",
//               gap: "20px",
//               opacity: "0px",
//               "@media (max-width : 991px)": {
//                 minWidth: "350px",
//                 marginTop: "60px",

//               },
//             }}
//           >
//             <Box
//               sx={{
//                 width: "100%",
//                 height: "24px",
//                 display: "flex",
//                 "@media (max-width : 991px)": {
//                   padding: "0px 4.4% 0px 4.4%",
//                   minWidth: "350px",

//                 },
//               }}
//             >
//               <Typography
//                 sx={{
//                   fontFamily: "Inter",
//                   fontSize: "16px",
//                   fontWeight: "600px",
//                   lineHeight: "24px,",
//                   textAlign: "left",
//                   "@media (max-width : 991px)": {
//                     fontSize: "14px",
//                   },
//                 }}
//               >
//                 Effective:{" "}
//                 <Typography
//                   sx={{
//                     fontFamily: "Inter",
//                     fontSize: "16px",
//                     fontWeight: "500px",
//                     lineHeight: "24px,",
//                     textAlign: "left",
//                     "@media (max-width : 991px)": {
//                       fontSize: "14px",
//                     },
//                   }}
//                   component="span"
//                 >
//                   August 1 2024
//                 </Typography>{" "}
//               </Typography>
//             </Box>

//             <Box
//               sx={{
//                 width: "100%",
//                 marginTop: "20px",
//                 "@media (max-width : 991px)": {
//                   minWidth: "350px",
//                   padding: "0px 4.4% 0px 4.4%",
//                 },
//               }}
//             >
//               <Typography
//                 sx={{
//                   fontFamily: "Inter",
//                   fontSize: "16px",
//                   fontWeight: "500px",
//                   lineHeight: "24px",
//                   textAlign: "left",
//                   color: "rgba(255, 255, 255, 0.9)",
//                   "@media (max-width : 991px)": {
//                     fontSize: "14px",
//                   },
//                 }}
//               >
//                 These terms and conditions ("Terms") are an agreement between
//                 the user and BRIGHTSPOT APP INC.  ("Company"). By accessing or
//                 using our AI services ("Services"), the user agree to be bound
//                 by these Terms. If the user does not agree to all of these
//                 Terms, do not access or use the Services.
//               </Typography>
//             </Box>
//           </Box>
//         </Box>

//         {/* section 3 */}

//         {/* section 4 */}
//         <Box
//           sx={{
//             display: "flex",
//             justifyContent: "center",
//           }}
//         >
//           <Box
//             sx={{
//               width: "900px",
//               marginTop: "80px",
//               gap: "30px",
//               opacity: "0px",
//               "@media (max-width : 991px)": {
//                 marginTop: "40px",
//                 minWidth: "350px",

//               },
//             }}
//           >
//             <Box
//               sx={{
//                 width: "100%",
//                 "@media (max-width : 991px)": {
//                   minWidth: "350px",
//                   paddingLeft : '4.4%'
//                 },
//               }}
//             >
//               <Typography
//                 sx={{
//                   fontFamily: "Inter",
//                   fontSize: "20px",
//                   fontWeight: "600px",
//                   lineHeight: "30px",
//                   textAlign: "left",
//                   color: "rgba(255, 255, 255, 0.9)",
//                   "@media (max-width : 991px)": {
//                     fontSize: "16px",
//                   },
//                 }}
//               >
//                 1. Acceptance
//               </Typography>
//             </Box>
//             <Box
//               sx={{
//                 width: "100%",
//                 marginTop: "30px",
//                 "@media (max-width : 991px)": {
//                   marginTop: "24px",
//                   minWidth: "350px",
//                   padding: "0px 4.4% 0px 4.4%",
//                 },
//               }}
//             >
//               {" "}
//               <Box component="ul" sx={{ paddingLeft: 2 }}>
//                 <Box component="li">
//                   <Typography
//                     variant="body1"
//                     sx={{
//                       fontSize: "16px",
//                       fontWeight: "400px",
//                       lineHeight: "24px",
//                       textAlign: "left",
//                       "@media (max-width : 991px)": {
//                         fontSize: "14px",
//                       },
//                     }}
//                   >
//                     By using the Services, the user agrees to comply with and be
//                     bound by these Terms. If the user does not agree, please do
//                     not use the Services.
//                   </Typography>
//                 </Box>
//                 <Box component="li">
//                   <Typography
//                     variant="body1"
//                     sx={{
//                       fontSize: "16px",
//                       fontWeight: "400px",
//                       lineHeight: "24px",
//                       textAlign: "left",
//                       "@media (max-width : 991px)": {
//                         fontSize: "14px",
//                       },
//                     }}
//                   >
//                     Users are responsible for ensuring their use does not
//                     violate any laws, regulations, or third-party rights.
//                   </Typography>
//                 </Box>
//                 <Box component="li">
//                   <Typography
//                     variant="body1"
//                     sx={{
//                       fontSize: "16px",
//                       fontWeight: "400px",
//                       lineHeight: "24px",
//                       textAlign: "left",
//                       "@media (max-width : 991px)": {
//                         fontSize: "14px",
//                       },
//                     }}
//                   >
//                     The Company may deny access, terminate contracts, or report
//                     misconduct to authorities if Users violate laws,
//                     regulations, or these Terms, harm the Company’s interests,
//                     or infringe on third-party rights.
//                   </Typography>
//                 </Box>
//               </Box>
//             </Box>
//           </Box>
//         </Box>

//         {/* section 4 */}

//         {/* section 5 */}
//         <Box
//           sx={{
//             display: "flex",
//             justifyContent: "center",
//           }}
//         >
//           <Box
//             sx={{
//               width: "900px",
//               marginTop: "80px",
//               gap: "30px",
//               opacity: "0px",
//               "@media (max-width : 991px)": {

//                 marginTop: "40px",
//                 minWidth: "350px",
//               },
//             }}
//           >
//             <Box
//               sx={{
//                 width: "100%",
//                 "@media (max-width : 991px)": {
//                   minWidth: "350px",
//                   padding: "0px 4.4% 0px 4.4%",

//                 },
//               }}
//             >
//               <Typography
//                 sx={{
//                   fontFamily: "Inter",
//                   fontSize: "20px",
//                   fontWeight: "600px",
//                   lineHeight: "30px",
//                   textAlign: "left",
//                   color: "rgba(255, 255, 255, 0.9)",
//                   "@media (max-width : 991px)": {
//                     fontSize: "16px",
//                   },
//                 }}
//               >
//                 2. Age Restrictions
//               </Typography>
//             </Box>
//             <Box
//               sx={{
//                 width: "100%",
//                 marginTop: "30px",
//                 "@media (max-width : 991px)": {
//                   marginTop: "24px",
//                   minWidth: "350px",
//                   padding: "0px 4.4% 0px 4.4%",
//                 },
//               }}
//             >
//               {" "}
//               <Box component="ul" sx={{ paddingLeft: 2 }}>
//                 <Box component="li">
//                   <Typography
//                     variant="body1"
//                     sx={{
//                       fontSize: "16px",
//                       fontWeight: "400px",
//                       lineHeight: "24px",
//                       textAlign: "left",
//                       "@media (max-width : 991px)": {
//                         fontSize: "14px",
//                       },
//                     }}
//                   >
//                     If the user is under 18 or the legal age in the user's
//                     country to manage your own modalx.ai account, the user must
//                     have his parent's or guardian's permission to use modalx.ai.
//                     Please have them review these terms with the user.
//                   </Typography>
//                 </Box>
//                 <Box component="li">
//                   <Typography
//                     variant="body1"
//                     sx={{
//                       fontSize: "16px",
//                       fontWeight: "400px",
//                       lineHeight: "24px",
//                       textAlign: "left",
//                       "@media (max-width : 991px)": {
//                         fontSize: "14px",
//                       },
//                     }}
//                   >
//                     If the user is a parent or guardian allowing your child to
//                     use modalx.ai services, the user agrees to these terms and
//                     takes responsibility for their activity.
//                   </Typography>
//                 </Box>
//               </Box>
//             </Box>
//           </Box>
//         </Box>

//         {/* section 5 */}

//         {/* section 6 */}
//         <Box
//           sx={{
//             display: "flex",
//             justifyContent: "center",
//           }}
//         >
//           <Box
//             sx={{
//               width: "900px",
//               marginTop: "80px",
//               gap: "30px",
//               opacity: "0px",
//               "@media (max-width : 991px)": {
//                 marginTop: "40px",
//                 minWidth: "350px",
//                 padding: "0px 4.4% 0px 4.4%",
//                 marginBottom: "487px",
//               },
//             }}
//           >
//             <Box
//               sx={{
//                 width: "100%",

//               }}
//             >
//               <Typography
//                 sx={{
//                   fontFamily: "Inter",
//                   fontSize: "20px",
//                   fontWeight: "600px",
//                   lineHeight: "30px",
//                   textAlign: "left",
//                   color: "rgba(255, 255, 255, 0.9)",
//                   "@media (max-width : 991px)": {
//                     fontSize: "16px",
//                   },
//                 }}
//               >
//                 3. User Accounts
//               </Typography>
//             </Box>
//             <Box
//               sx={{
//                 width: "100%",
//                 marginTop: "30px",
//                 "@media (max-width : 991px)": {
//                   marginTop: "24px",

//                 },
//               }}
//             >
//               {" "}
//               <Box component="ul" sx={{ paddingLeft: 2 }}>
//                 <Box component="li">
//                   <Typography
//                     variant="body1"
//                     sx={{
//                       fontSize: "16px",
//                       fontWeight: "400px",
//                       lineHeight: "24px",
//                       textAlign: "left",
//                       "@media (max-width : 991px)": {
//                         fontSize: "14px",
//                       },
//                     }}
//                   >
//                     To use certain features, the user must create an account.
//                     The user is responsible for safeguarding their account, and
//                     the user must notify us immediately of any unauthorized use.
//                   </Typography>
//                 </Box>
//                 <Box component="li">
//                   <Typography
//                     variant="body1"
//                     sx={{
//                       fontSize: "16px",
//                       fontWeight: "400px",
//                       lineHeight: "24px",
//                       textAlign: "left",
//                       "@media (max-width : 991px)": {
//                         fontSize: "14px",
//                       },
//                     }}
//                   >
//                     The user may not create an account using false information
//                     or through unauthorized means, such as automated devices,
//                     scripts, bots, spiders, or scrapers.
//                   </Typography>
//                 </Box>
//                 <Box component="li">
//                   <Typography
//                     variant="body1"
//                     sx={{
//                       fontSize: "16px",
//                       fontWeight: "400px",
//                       lineHeight: "24px",
//                       textAlign: "left",
//                       "@media (max-width : 991px)": {
//                         fontSize: "14px",
//                       },
//                     }}
//                   >
//                     The Company reserves the right to modify or terminate the
//                     user’s account for any reason at any time without notice.
//                   </Typography>
//                 </Box>
//               </Box>
//             </Box>
//           </Box>
//         </Box>

//         {/* section 6 */}

//         {/* section 7 */}
//         <Box
//           sx={{
//             display: "flex",
//             justifyContent: "center",
//           }}
//         >
//           <Box
//             sx={{
//               width: "900px",
//               // marginTop: "120px",
//               marginBottom: "320px",
//               gap: "0px",
//               opacity: "0px",
//               "@media (max-width : 991px)": {
//                 display: "none",
//               },
//             }}
//           >
//             <Typography
//               sx={{
//                 fontFamily: "Inter",
//                 fontSize: "16px",
//                 fontWeight: "500px",
//                 lineHeight: "24px,",
//                 textAlign: "left",
//               }}
//             >
//               For any questions regarding these terms, please contact us at:{" "}
//               <Typography
//                 sx={{
//                   fontFamily: "Inter",
//                   fontSize: "16px",
//                   fontWeight: "500px",
//                   lineHeight: "24px,",
//                   textAlign: "left",
//                 }}
//                 component="span"
//               >
//                 support@modalx.ai
//               </Typography>{" "}
//             </Typography>
//           </Box>
//         </Box>

//         {/* section 7 */}
//         <Box>
//           <RequestDemoNearFooter />
//         </Box>
//       </PageLayout>
//     </>
//   );
// }

// export default TermsOfService;

import React, { useRef } from "react";
import { Box, Typography, Button } from "@mui/material";
import RequestDemoNearFooter from "../components/LandingPage/RequestDemoNearFooter";
import PageLayout from "../components/navFoot/PageLayout";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
function TermsOfService() {
  const explanation1Ref = useRef(null);
  const explanation2Ref = useRef(null);
  const explanation3Ref = useRef(null);
  const explanation4Ref = useRef(null);
  const explanation5Ref = useRef(null);
  const explanation6Ref = useRef(null);
  const explanation7Ref = useRef(null);
  const explanation8Ref = useRef(null);
  const explanation9Ref = useRef(null);
  const explanation10Ref = useRef(null);
  const explanation11Ref = useRef(null);
  const explanation12Ref = useRef(null);
  const explanation13Ref = useRef(null);
  const explanation14Ref = useRef(null);
  const explanation15Ref = useRef(null);
  const explanation16Ref = useRef(null);
  const explanation17Ref = useRef(null);
  const explanation18Ref = useRef(null);
  const explanation19Ref = useRef(null);
  const explanation20Ref = useRef(null);
  const explanation21Ref = useRef(null);
  const explanation22Ref = useRef(null);
  const explanation23Ref = useRef(null);
  const explanation24Ref = useRef(null);
  const explanation25Ref = useRef(null);
  const explanation26Ref = useRef(null);
  const explanation27Ref = useRef(null);
  const explanation28Ref = useRef(null);
  const explanation29Ref = useRef(null);
  const explanation30Ref = useRef(null);
  const explanation31Ref = useRef(null);

  const commonStyles = {
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    textAlign: "left",
    "@media (max-width: 991px)": {
      fontSize: "14px",
    },
  };

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <PageLayout>
        {/* section 1 */}

        <Box
          sx={{
            width: "100%",
            // height: "500px",
            marginTop: "163px",
            gap: "48px",
            opacity: "0px",
            "@media (max-width : 991px)": {
              marginTop: "156px",
            },
          }}
        >
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "48px",
              fontWeight: "500px",
              lineHeight: "57.6px",
              letterSpacing: "-0.32",
              opacity: "80%",
              textAlign: "center",
              color: "#FFFFFF",
              "@media (max-width : 991px)": {
                fontSize: "28px",
              },
            }}
          >
            Terms of Use
          </Typography>
        </Box>

        {/* section 1 */}

        {/* section 2 */}

        <Box
          sx={{
            width: "100%",
            marginTop: "40px",
            gap: "0px",
            opacity: "0px",
            "@media (max-width : 991px)": {
              marginTop: "36px",
            },
          }}
        >
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "16px",
              fontWeight: "500px",
              lineHeight: "24px",
              textAlign: "center",
              color: "rgba(255, 255, 255, 1)",
              "@media (max-width : 991px)": {
                fontSize: "14px",
              },
            }}
          >
            Last Updated: Nov 23, 2024{" "}
          </Typography>
        </Box>

        {/* section 2 */}

        {/* section 3 */}
        <Box
          display={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "900px",
              marginTop: "100px",
              gap: "20px",
              opacity: "0px",
              "@media (max-width : 991px)": {
                minWidth: "350px",
                marginTop: "60px",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
                marginTop: "20px",
                "@media (max-width : 991px)": {
                  minWidth: "350px",
                  padding: "0px 4.4% 0px 4.4%",
                },
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontWeight: "500px",
                  lineHeight: "24px",
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.9)",
                  "@media (max-width : 991px)": {
                    fontSize: "14px",
                  },
                }}
              >
                We are Brightspot App, Inc.("Company," "we," "us," "our").
                <br />
                &nbsp;
                <br />
                We operate the website https://modalx.ai (the "Site"), as well
                as any other related products and services that refer or link to
                these legal terms (the "Legal Terms") (collectively, the
                "Services").
                <br />
                &nbsp;
                <br />
                We provide a platform to hire digital workers, which are
                complimented by a suite of software tools.
                <br />
                &nbsp;
                <br />
                You can contact us by email at support@modalx.ai or by mail to
                Suite 2700, Brookfield Place 225 - 6th Ave SW Calgary ALBERTA
                T2P 1N2.
                <br />
                &nbsp;
                <br />
                These Legal Terms constitute a legally binding agreement made
                between you, whether personally or on behalf of an entity
                ("you"), and ModalX AI, Inc., concerning your access to and use
                of the Services. You agree that by accessing the Services, you
                have read, understood, and agreed to be bound by all of these
                Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS,
                THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND
                YOU MUST DISCONTINUE USE IMMEDIATELY.
                <br />
                &nbsp;
                <br />
                Supplemental terms and conditions or documents that may be
                posted on the Services from time to time are hereby expressly
                incorporated herein by reference. We reserve the right, in our
                sole discretion, to make changes or modifications to these Legal
                Terms from time to time. We will alert you about any changes by
                updating the "Last updated" date of these Legal Terms, and you
                waive any right to receive specific notice of each such change.
                It is your responsibility to periodically review these Legal
                Terms to stay informed of updates. You will be subject to, and
                will be deemed to have been made aware of and to have accepted,
                the changes in any revised Legal Terms by your continued use of
                the Services after the date such revised Legal Terms are posted.
                <br />
                &nbsp;
                <br />
                The Services are intended for users who are at least 18 years
                old. Persons under the age of 18 are not permitted to use or
                register for the Services.
                <br />
                &nbsp;
                <br />
                We recommend that you print a copy of these Legal Terms for your
                records.
                <br />
                &nbsp;
                <br />
              </Typography>
            </Box>
          </Box>
        </Box>
        {/* section 3 */}

        {/* section main with all point */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "900px",
              marginTop: "80px",
              gap: "30px",
              opacity: "0px",
              "@media (max-width : 991px)": {
                marginTop: "40px",
                minWidth: "350px",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
                "@media (max-width : 991px)": {
                  minWidth: "350px",
                  paddingLeft: "4.4%",
                },
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: "600px",
                  lineHeight: "30px",
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.9)",
                  "@media (max-width : 991px)": {
                    fontSize: "16px",
                  },
                }}
              >
                TABLE OF CONTENTS
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                marginTop: "30px",
                "@media (max-width : 991px)": {
                  marginTop: "24px",
                  minWidth: "350px",
                  padding: "0px 4.4% 0px 4.4%",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  paddingLeft: 2,
                  gap: "20px",
                }}
              >
                <Box
                  onClick={() => scrollToSection(explanation1Ref)}
                  style={{ cursor: "pointer" }}
                >
                  <Typography sx={commonStyles}>1. OUR SERVICES</Typography>
                </Box>
                <Box
                  onClick={() => scrollToSection(explanation2Ref)}
                  style={{ cursor: "pointer" }}
                >
                  <Typography sx={commonStyles}>
                    2. INTELLECTUAL PROPERTY RIGHTS
                  </Typography>
                </Box>
                <Box
                  onClick={() => scrollToSection(explanation3Ref)}
                  style={{ cursor: "pointer" }}
                >
                  <Typography sx={commonStyles}>
                    3. USER REPRESENTATIONS
                  </Typography>
                </Box>
                <Box
                  onClick={() => scrollToSection(explanation4Ref)}
                  style={{ cursor: "pointer" }}
                >
                  <Typography sx={commonStyles}>
                    4. USER REGISTRATION
                  </Typography>
                </Box>
                <Box
                  onClick={() => scrollToSection(explanation5Ref)}
                  style={{ cursor: "pointer" }}
                >
                  <Typography sx={commonStyles}>
                    5. PURCHASES AND PAYMENT
                  </Typography>
                </Box>
                <Box
                  onClick={() => scrollToSection(explanation6Ref)}
                  style={{ cursor: "pointer" }}
                >
                  <Typography sx={commonStyles}>6. FREE TRIAL</Typography>
                </Box>
                <Box
                  onClick={() => scrollToSection(explanation7Ref)}
                  style={{ cursor: "pointer" }}
                >
                  <Typography sx={commonStyles}>7. CANCELLATION</Typography>
                </Box>
                <Box
                  onClick={() => scrollToSection(explanation8Ref)}
                  style={{ cursor: "pointer" }}
                >
                  <Typography sx={commonStyles}>
                    8. PROHIBITED ACTIVITIES
                  </Typography>
                </Box>
                <Box
                  onClick={() => scrollToSection(explanation9Ref)}
                  style={{ cursor: "pointer" }}
                >
                  <Typography sx={commonStyles}>
                    9. USER GENERATED CONTRIBUTIONS
                  </Typography>
                </Box>
                <Box
                  onClick={() => scrollToSection(explanation10Ref)}
                  style={{ cursor: "pointer" }}
                >
                  <Typography sx={commonStyles}>
                    10. CONTRIBUTION LICENSE
                  </Typography>
                </Box>
                <Box
                  onClick={() => scrollToSection(explanation11Ref)}
                  style={{ cursor: "pointer" }}
                >
                  <Typography sx={commonStyles}>
                    11. GUIDELINES FOR REVIEWS
                  </Typography>
                </Box>
                <Box
                  onClick={() => scrollToSection(explanation12Ref)}
                  style={{ cursor: "pointer" }}
                >
                  <Typography sx={commonStyles}>12. SOCIAL MEDIA</Typography>
                </Box>
                <Box
                  onClick={() => scrollToSection(explanation13Ref)}
                  style={{ cursor: "pointer" }}
                >
                  <Typography sx={commonStyles}>
                    13. THIRD-PARTY WEBSITES AND CONTENT
                  </Typography>
                </Box>
                <Box
                  onClick={() => scrollToSection(explanation14Ref)}
                  style={{ cursor: "pointer" }}
                >
                  <Typography sx={commonStyles}>
                    14. SERVICES MANAGEMENT
                  </Typography>
                </Box>
                <Box
                  onClick={() => scrollToSection(explanation15Ref)}
                  style={{ cursor: "pointer" }}
                >
                  <Typography sx={commonStyles}>15. PRIVACY POLICY</Typography>
                </Box>
                <Box
                  onClick={() => scrollToSection(explanation16Ref)}
                  style={{ cursor: "pointer" }}
                >
                  <Typography sx={commonStyles}>
                    16. COPYRIGHT INFRINGEMENTS
                  </Typography>
                </Box>
                <Box
                  onClick={() => scrollToSection(explanation17Ref)}
                  style={{ cursor: "pointer" }}
                >
                  <Typography sx={commonStyles}>
                    17. TERM AND TERMINATION
                  </Typography>
                </Box>
                <Box
                  onClick={() => scrollToSection(explanation18Ref)}
                  style={{ cursor: "pointer" }}
                >
                  <Typography sx={commonStyles}>
                    18. MODIFICATIONS AND INTERRUPTIONS
                  </Typography>
                </Box>
                <Box
                  onClick={() => scrollToSection(explanation19Ref)}
                  style={{ cursor: "pointer" }}
                >
                  <Typography sx={commonStyles}>19. GOVERNING LAW</Typography>
                </Box>
                <Box
                  onClick={() => scrollToSection(explanation20Ref)}
                  style={{ cursor: "pointer" }}
                >
                  <Typography sx={commonStyles}>
                    20. DISPUTE RESOLUTION
                  </Typography>
                </Box>
                <Box
                  onClick={() => scrollToSection(explanation21Ref)}
                  style={{ cursor: "pointer" }}
                >
                  <Typography sx={commonStyles}>21. CORRECTIONS</Typography>
                </Box>
                <Box
                  onClick={() => scrollToSection(explanation22Ref)}
                  style={{ cursor: "pointer" }}
                >
                  <Typography sx={commonStyles}>22. DISCLAIMER</Typography>
                </Box>
                <Box
                  onClick={() => scrollToSection(explanation23Ref)}
                  style={{ cursor: "pointer" }}
                >
                  <Typography sx={commonStyles}>
                    23. LIMITATIONS OF LIABILITY
                  </Typography>
                </Box>
                <Box
                  onClick={() => scrollToSection(explanation24Ref)}
                  style={{ cursor: "pointer" }}
                >
                  <Typography sx={commonStyles}>24. INDEMNIFICATION</Typography>
                </Box>
                <Box
                  onClick={() => scrollToSection(explanation25Ref)}
                  style={{ cursor: "pointer" }}
                >
                  <Typography sx={commonStyles}>25. USER DATA</Typography>
                </Box>
                <Box
                  onClick={() => scrollToSection(explanation26Ref)}
                  style={{ cursor: "pointer" }}
                >
                  <Typography sx={commonStyles}>
                    26. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
                  </Typography>
                </Box>
                <Box
                  onClick={() => scrollToSection(explanation27Ref)}
                  style={{ cursor: "pointer" }}
                >
                  <Typography sx={commonStyles}>
                    27. Alberta USERS AND RESIDENTS
                  </Typography>
                </Box>
                <Box
                  onClick={() => scrollToSection(explanation28Ref)}
                  style={{ cursor: "pointer" }}
                >
                  <Typography sx={commonStyles}>28. MISCELLANEOUS</Typography>
                </Box>
                <Box
                  onClick={() => scrollToSection(explanation29Ref)}
                  style={{ cursor: "pointer" }}
                >
                  <Typography sx={commonStyles}>
                    29. RIGHT TO TERMINATE ACCOUNT
                  </Typography>
                </Box>
                <Box
                  onClick={() => scrollToSection(explanation30Ref)}
                  style={{ cursor: "pointer" }}
                >
                  <Typography sx={commonStyles}>30. HOLD HARMLESS</Typography>
                </Box>
                <Box
                  onClick={() => scrollToSection(explanation31Ref)}
                  style={{ cursor: "pointer" }}
                >
                  <Typography sx={commonStyles}>31. CONTACT US</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* section main with all point */}

        {/* section 4 */}
        <Box
          ref={explanation1Ref}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "900px",
              marginTop: "80px",
              gap: "30px",
              opacity: "0px",
              "@media (max-width : 991px)": {
                marginTop: "40px",
                minWidth: "350px",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
                "@media (max-width : 991px)": {
                  minWidth: "350px",
                  paddingLeft: "4.4%",
                },
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: "600px",
                  lineHeight: "30px",
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.9)",
                  "@media (max-width : 991px)": {
                    fontSize: "16px",
                  },
                }}
              >
                1. OUR SERVICES
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                marginTop: "30px",
                "@media (max-width : 991px)": {
                  marginTop: "24px",
                  minWidth: "350px",
                  padding: "0px 4.4% 0px 4.4%",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  paddingLeft: 2,
                  gap: "20px",
                }}
              >
                <Box>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400px",
                      lineHeight: "24px",
                      textAlign: "left",
                      "@media (max-width : 991px)": {
                        fontSize: "14px",
                      },
                    }}
                  >
                    The information provided when using the Services is not
                    intended for distribution to or use by any person or entity
                    in any jurisdiction or country where such distribution or
                    use would be contrary to law or regulation or which would
                    subject us to any registration requirement within such
                    jurisdiction or country. Accordingly, those persons who
                    choose to access the Services from other locations do so on
                    their own initiative and are solely responsible for
                    compliance with local laws, if and to the extent local laws
                    are applicable.
                    <br />
                    &nbsp;
                    <br />
                    ModalX's use and transfer of information received from
                    Google APIs to any other app will adhere to Google API
                    Services User Data Policy, including the Limited Use
                    requirements.
                    <br />
                    &nbsp;
                    <br />
                    The Services are not tailored to comply with
                    industry-specific regulations (Health Insurance Portability
                    and Accountability Act (HIPAA), Federal Information Security
                    Management Act (FISMA), etc.), so if your interactions would
                    be subjected to such laws, you may not use the Services. You
                    may not use the Services in a way that would violate the
                    Gramm-Leach-Bliley Act (GLBA).
                    <br />
                    &nbsp;
                    <br />
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* section 4 */}

        {/* section 5 */}
        <Box
          ref={explanation2Ref}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "900px",
              marginTop: "80px",
              gap: "30px",
              opacity: "0px",
              "@media (max-width : 991px)": {
                marginTop: "40px",
                minWidth: "350px",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
                "@media (max-width : 991px)": {
                  minWidth: "350px",
                  padding: "0px 4.4% 0px 4.4%",
                },
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: "600px",
                  lineHeight: "30px",
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.9)",
                  "@media (max-width : 991px)": {
                    fontSize: "16px",
                  },
                }}
              >
                2. INTELLECTUAL PROPERTY RIGHTS
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                marginTop: "30px",
                "@media (max-width : 991px)": {
                  marginTop: "24px",
                  minWidth: "350px",
                  padding: "0px 4.4% 0px 4.4%",
                },
              }}
            >
              {" "}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  paddingLeft: 2,
                  gap: "20px",
                }}
              >
                <Box>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400px",
                      lineHeight: "24px",
                      textAlign: "left",
                      "@media (max-width : 991px)": {
                        fontSize: "14px",
                      },
                    }}
                  >
                    Our intellectual property
                    <br />
                    &nbsp;
                    <br />
                    We are the owner or the licensee of all intellectual
                    property rights in our Services, including all source code,
                    databases, functionality, software, website designs, audio,
                    video, text, photographs, and graphics in the Services
                    (collectively, the "Content"), as well as the trademarks,
                    service marks, and logos contained therein (the "Marks").
                    <br />
                    &nbsp;
                    <br />
                    Our Content and Marks are protected by copyright and
                    trademark laws (and various other intellectual property
                    rights and unfair competition laws) and treaties in the
                    Canada and around the world.
                    <br />
                    &nbsp;
                    <br />
                    The Content and Marks are provided in or through the
                    Services "AS IS" for your internal business purpose only.
                    <br />
                    &nbsp;
                    <br />
                    Your use of our Services
                    <br />
                    &nbsp;
                    <br />
                    Subject to your compliance with these Legal Terms, including
                    the "PROHIBITED ACTIVITIES" section below, we grant you a
                    non-exclusive, non-transferable, revocable license to:
                  </Typography>
                </Box>

                <Box sx={{ marginLeft: 4 }}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400px",
                      lineHeight: "24px",
                      textAlign: "left",
                      paddingLeft: "3px",
                      "@media (max-width : 991px)": {
                        fontSize: "14px",
                      },
                    }}
                  >
                    access the Services; and
                    <br />
                    &nbsp;
                    <br />
                    download or print a copy of any portion of the Content to
                    which you have properly gained access.
                    <br />
                    &nbsp;
                    <br />
                  </Typography>
                </Box>

                <Box>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400px",
                      lineHeight: "24px",
                      textAlign: "left",
                      "@media (max-width : 991px)": {
                        fontSize: "14px",
                      },
                    }}
                  >
                    This license is granted solely for your internal business
                    purposes.
                    <br />
                    &nbsp;
                    <br />
                    Except as set out in this section or elsewhere in our Legal
                    Terms, no part of the Services and no Content or Marks may
                    be copied, reproduced, aggregated, republished, uploaded,
                    posted, publicly displayed, encoded, translated,
                    transmitted, distributed, sold, licensed, or otherwise
                    exploited for any commercial purpose whatsoever, without our
                    express prior written permission.
                    <br />
                    &nbsp;
                    <br />
                    If you wish to make any use of the Services, Content, or
                    Marks other than as set out in this section or elsewhere in
                    our Legal Terms, please address your request to:
                    support@modalx.ai. If we ever grant you the permission to
                    post, reproduce, or publicly display any part of our
                    Services or Content, you must identify us as the owners or
                    licensors of the Services, Content, or Marks and ensure that
                    any copyright or proprietary notice appears or is visible on
                    posting, reproducing, or displaying our Content.
                    <br />
                    &nbsp;
                    <br />
                    We reserve all rights not expressly granted to you in and to
                    the Services, Content, and Marks.
                    <br />
                    &nbsp;
                    <br />
                    Any breach of these Intellectual Property Rights will
                    constitute a material breach of our Legal Terms and your
                    right to use our Services will terminate immediately.
                    <br />
                    &nbsp;
                    <br />
                    Your submissions and contributionsPlease review this section
                    and the "PROHIBITED ACTIVITIES" section carefully prior to
                    using our Services to understand the (a) rights you give us
                    and (b) obligations you have when you post or upload any
                    content through the Services.
                    <br />
                    &nbsp;
                    <br />
                    Submissions: By directly sending us any question, comment,
                    suggestion, idea, feedback, or other information about the
                    Services ("Submissions"), you agree to assign to us all
                    intellectual property rights in such Submission. You agree
                    that we shall own this Submission and be entitled to its
                    unrestricted use and dissemination for any lawful purpose,
                    commercial or otherwise, without acknowledgment or
                    compensation to you.
                    <br />
                    &nbsp;
                    <br />
                    Contributions: The Services may invite you to chat,
                    contribute to, or participate in blogs, message boards,
                    online forums, and other functionality during which you may
                    create, submit, post, display, transmit, publish,
                    distribute, or broadcast content and materials to us or
                    through the Services, including but not limited to text,
                    writings, video, audio, photographs, music, graphics,
                    comments, reviews, rating suggestions, personal information,
                    or other material ("Contributions"). Any Submission that is
                    publicly posted shall also be treated as a Contribution.
                    <br />
                    &nbsp;
                    <br />
                    You understand that Contributions may be viewable by other
                    users of the Services and possibly through third-party
                    websites.
                    <br />
                    &nbsp;
                    <br />
                    When you post Contributions, you grant us a license
                    (including use of your name, trademarks, and logos): By
                    posting any Contributions, you grant us an unrestricted,
                    unlimited, irrevocable, perpetual, non-exclusive,
                    transferable, royalty-free, fully-paid, worldwide right, and
                    license to: use, copy, reproduce, distribute, sell, resell,
                    publish, broadcast, retitle, store, publicly perform,
                    publicly display, reformat, translate, excerpt (in whole or
                    in part), and exploit your Contributions (including, without
                    limitation, your image, name, and voice) for any purpose,
                    commercial, advertising, or otherwise, to prepare derivative
                    works of, or incorporate into other works, your
                    Contributions, and to sublicense the licenses granted in
                    this section. Our use and distribution may occur in any
                    media formats and through any media channels.
                    <br />
                    &nbsp;
                    <br />
                    This license includes our use of your name, company name,
                    and franchise name, as applicable, and any of the
                    trademarks, service marks, trade names, logos, and personal
                    and commercial images you provide.
                    <br />
                    &nbsp;
                    <br />
                    You are responsible for what you post or upload: By sending
                    us Submissions and/or posting Contributions through any part
                    of the Services or making Contributions accessible through
                    the Services by linking your account through the Services to
                    any of your social networking accounts, you:
                    <br />
                    &nbsp;
                    <br />
                    <Box sx={{ marginLeft: 4 }}>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400px",
                          lineHeight: "24px",
                          textAlign: "left",
                          paddingLeft: "3px",
                          "@media (max-width : 991px)": {
                            fontSize: "14px",
                          },
                        }}
                      >
                        confirm that you have read and agree with our
                        "PROHIBITED ACTIVITIES" and will not post, send,
                        publish, upload, or transmit through the Services any
                        Submission nor post any Contribution that is illegal,
                        harassing, hateful, harmful, defamatory, obscene,
                        bullying, abusive, discriminatory, threatening to any
                        person or group, sexually explicit, false, inaccurate,
                        deceitful, or misleading;
                        <br />
                        &nbsp;
                        <br />
                        to the extent permissible by applicable law, waive any
                        and all moral rights to any such Submission and/or
                        Contribution;
                        <br />
                        &nbsp;
                        <br />
                        warrant that any such Submission and/or Contributions
                        are original to you or that you have the necessary
                        rights and licenses to submit such Submissions and/or
                        Contributions and that you have full authority to grant
                        us the above-mentioned rights in relation to your
                        Submissions and/or Contributions; and
                        <br />
                        &nbsp;
                        <br />
                        warrant and represent that your Submissions and/or
                        Contributions do not constitute confidential
                        information.
                        <br />
                        &nbsp;
                        <br />
                      </Typography>
                    </Box>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* section 5 */}

        {/* section 6 */}
        <Box
          ref={explanation3Ref}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "900px",
              marginTop: "80px",
              gap: "30px",
              opacity: "0px",
              "@media (max-width : 991px)": {
                marginTop: "40px",
                minWidth: "350px",
                padding: "0px 4.4% 0px 4.4%",
                marginBottom: "487px",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: "600px",
                  lineHeight: "30px",
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.9)",
                  "@media (max-width : 991px)": {
                    fontSize: "16px",
                  },
                }}
              >
                3. USER REPRESENTATIONS
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                marginTop: "30px",
                "@media (max-width : 991px)": {
                  marginTop: "24px",
                },
              }}
            >
              {" "}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  paddingLeft: 2,
                }}
              >
                <Box>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400px",
                      lineHeight: "24px",
                      textAlign: "left",
                      "@media (max-width : 991px)": {
                        fontSize: "14px",
                      },
                    }}
                  >
                    By using the Services, you represent and warrant that:
                  </Typography>
                </Box>
                <Box sx={{ marginLeft: 4 }}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400px",
                      lineHeight: "24px",
                      textAlign: "left",
                      paddingLeft: "3px",
                      "@media (max-width : 991px)": {
                        fontSize: "14px",
                      },
                    }}
                  >
                    all registration information you submit will be true,
                    accurate, current, and complete;
                    <br />
                    &nbsp;
                    <br />
                    you will maintain the accuracy of such information and
                    promptly update such registration information as necessary;
                    <br />
                    &nbsp;
                    <br />
                    you have the legal capacity and you agree to comply with
                    these Legal Terms;
                    <br />
                    &nbsp;
                    <br />
                    you are not a minor in the jurisdiction in which you reside;
                    <br />
                    &nbsp;
                    <br />
                    you will not access the Services through automated or
                    non-human means, whether through a bot, script or otherwise;
                    <br />
                    &nbsp;
                    <br />
                    you will not use the Services for any illegal or
                    unauthorized purpose; and
                    <br />
                    &nbsp;
                    <br />
                    your use of the Services will not violate any applicable law
                    or regulation.
                    <br />
                    &nbsp;
                    <br />
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400px",
                      lineHeight: "24px",
                      textAlign: "left",
                      "@media (max-width : 991px)": {
                        fontSize: "14px",
                      },
                    }}
                  >
                    If you provide any information that is untrue, inaccurate,
                    not current, or incomplete, we have the right to suspend or
                    terminate your account and refuse any and all current or
                    future use of the Services (or any portion thereof).
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* section 6 */}

        {/* section 7 */}
        <Box
          ref={explanation4Ref}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "900px",
              marginTop: "80px",
              gap: "30px",
              opacity: "0px",
              "@media (max-width : 991px)": {
                marginTop: "40px",
                minWidth: "350px",
                padding: "0px 4.4% 0px 4.4%",
                marginBottom: "487px",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: "600px",
                  lineHeight: "30px",
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.9)",
                  "@media (max-width : 991px)": {
                    fontSize: "16px",
                  },
                }}
              >
                4. USER REGISTRATION
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                marginTop: "30px",
                "@media (max-width : 991px)": {
                  marginTop: "24px",
                },
              }}
            >
              {" "}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  paddingLeft: 2,
                }}
              >
                <Box>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400px",
                      lineHeight: "24px",
                      textAlign: "left",
                      "@media (max-width : 991px)": {
                        fontSize: "14px",
                      },
                    }}
                  >
                    You may be required to register to use the Services. You
                    agree to keep your password confidential and will be
                    responsible for all use of your account and password. We
                    reserve the right to remove, reclaim, or change a username
                    you select if we determine, in our sole discretion, that
                    such username is inappropriate, obscene, or otherwise
                    objectionable.
                    <br />
                    &nbsp;
                    <br />
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* section 7 */}

        {/* section 8 */}
        <Box
          ref={explanation5Ref}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "900px",
              marginTop: "80px",
              gap: "30px",
              opacity: "0px",
              "@media (max-width : 991px)": {
                marginTop: "40px",
                minWidth: "350px",
                padding: "0px 4.4% 0px 4.4%",
                marginBottom: "487px",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: "600px",
                  lineHeight: "30px",
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.9)",
                  "@media (max-width : 991px)": {
                    fontSize: "16px",
                  },
                }}
              >
                5. PURCHASES AND PAYMENT
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                marginTop: "30px",
                "@media (max-width : 991px)": {
                  marginTop: "24px",
                },
              }}
            >
              {" "}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  paddingLeft: 2,
                }}
              >
                <Box>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400px",
                      lineHeight: "24px",
                      textAlign: "left",
                      "@media (max-width : 991px)": {
                        fontSize: "14px",
                      },
                    }}
                  >
                    We accept the following forms of payment:
                  </Typography>
                </Box>
                <Box sx={{ marginLeft: 4 }}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400px",
                      lineHeight: "24px",
                      textAlign: "left",
                      paddingLeft: "3px",
                      "@media (max-width : 991px)": {
                        fontSize: "14px",
                      },
                    }}
                  >
                    Visa
                    <br />
                    &nbsp;
                    <br />
                    Mastercard
                    <br />
                    &nbsp;
                    <br />
                    American Express
                    <br />
                    &nbsp;
                    <br />
                    Discover
                    <br />
                    &nbsp;
                    <br />
                    PayPal
                    <br />
                    &nbsp;
                    <br />
                    Apple Pay
                    <br />
                    &nbsp;
                    <br />
                    Google Pay
                    <br />
                    &nbsp;
                    <br />
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400px",
                      lineHeight: "24px",
                      textAlign: "left",
                      "@media (max-width : 991px)": {
                        fontSize: "14px",
                      },
                    }}
                  >
                    You agree to provide current, complete, and accurate
                    purchase and account information for all purchases made via
                    the Services. You further agree to promptly update account
                    and payment information, including email address, payment
                    method, and payment card expiration date, so that we can
                    complete your transactions and contact you as needed. Sales
                    tax will be added to the price of purchases as deemed
                    required by us. We may change prices at any time. All
                    payments shall be in US/CAD dollars.
                    <br />
                    &nbsp;
                    <br />
                    You agree to pay all charges at the prices then in effect
                    for your purchases and any applicable shipping fees, and you
                    authorize us to charge your chosen payment provider for any
                    such amounts upon placing your order. If your order is
                    subject to recurring charges, then you consent to our
                    charging your payment method on a recurring basis without
                    requiring your prior approval for each recurring charge,
                    until such time as you cancel the applicable order. We
                    reserve the right to correct any errors or mistakes in
                    pricing, even if we have already requested or received
                    payment.
                    <br />
                    &nbsp;
                    <br />
                    We reserve the right to refuse any order placed through the
                    Services. We may, in our sole discretion, limit or cancel
                    quantities purchased per person, per household, or per
                    order. These restrictions may include orders placed by or
                    under the same customer account, the same payment method,
                    and/or orders that use the same billing or shipping address.
                    We reserve the right to limit or prohibit orders that, in
                    our sole judgment,appear to be placed by dealers, resellers,
                    or distributors.
                    <br />
                    &nbsp;
                    <br />
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* section  8 */}

        {/* section 9 */}
        <Box
          ref={explanation6Ref}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "900px",
              marginTop: "80px",
              gap: "30px",
              opacity: "0px",
              "@media (max-width : 991px)": {
                marginTop: "40px",
                minWidth: "350px",
                padding: "0px 4.4% 0px 4.4%",
                marginBottom: "487px",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: "600px",
                  lineHeight: "30px",
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.9)",
                  "@media (max-width : 991px)": {
                    fontSize: "16px",
                  },
                }}
              >
                6. FREE TRIAL
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                marginTop: "30px",
                "@media (max-width : 991px)": {
                  marginTop: "24px",
                },
              }}
            >
              {" "}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  paddingLeft: 2,
                }}
              >
                <Box>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400px",
                      lineHeight: "24px",
                      textAlign: "left",
                      "@media (max-width : 991px)": {
                        fontSize: "14px",
                      },
                    }}
                  >
                    We offer a 2-day free trial to new users who register with
                    the Services. The account will be charged according to the
                    user's chosen subscription at the end of the free trial.
                    <br />
                    &nbsp;
                    <br />
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* section 9 */}

        {/* section 10 */}
        <Box
          ref={explanation7Ref}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "900px",
              marginTop: "80px",
              gap: "30px",
              opacity: "0px",
              "@media (max-width : 991px)": {
                marginTop: "40px",
                minWidth: "350px",
                padding: "0px 4.4% 0px 4.4%",
                marginBottom: "487px",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: "600px",
                  lineHeight: "30px",
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.9)",
                  "@media (max-width : 991px)": {
                    fontSize: "16px",
                  },
                }}
              >
                7. CANCELLATION
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                marginTop: "30px",
                "@media (max-width : 991px)": {
                  marginTop: "24px",
                },
              }}
            >
              {" "}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  paddingLeft: 2,
                }}
              >
                <Box>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400px",
                      lineHeight: "24px",
                      textAlign: "left",
                      "@media (max-width : 991px)": {
                        fontSize: "14px",
                      },
                    }}
                  >
                    All purchases are non-refundable. If you are on a legacy
                    recurring monthly subscription, you can cancel your
                    subscription at any time by contacting us. If you have
                    signed an annual commitment order form, you must abide by
                    the cancellation terms specified within your order form.
                    <br />
                    &nbsp;
                    <br />
                    If you are unsatisfied with our Services, please email us at
                    support@modalx.ai.
                    <br />
                    &nbsp;
                    <br />
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* section 10 */}

        {/* section 11 */}
        <Box
          ref={explanation8Ref}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "900px",
              marginTop: "80px",
              gap: "30px",
              opacity: "0px",
              "@media (max-width : 991px)": {
                marginTop: "40px",
                minWidth: "350px",
                padding: "0px 4.4% 0px 4.4%",
                marginBottom: "487px",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: "600px",
                  lineHeight: "30px",
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.9)",
                  "@media (max-width : 991px)": {
                    fontSize: "16px",
                  },
                }}
              >
                8. PROHIBITED ACTIVITIES
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                marginTop: "30px",
                "@media (max-width : 991px)": {
                  marginTop: "24px",
                },
              }}
            >
              {" "}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  paddingLeft: 2,
                }}
              >
                <Box>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400px",
                      lineHeight: "24px",
                      textAlign: "left",
                      "@media (max-width : 991px)": {
                        fontSize: "14px",
                      },
                    }}
                  >
                    You may not access or use the Services for any purpose other
                    than that for which we make the Services available. The
                    Services may not be used in connection with any commercial
                    endeavours except those that are specifically endorsed or
                    approved by us.
                    <br />
                    &nbsp;
                    <br />
                    As a user of the Services, you agree not to:
                  </Typography>
                </Box>
                <Box sx={{ marginLeft: 4 }}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400px",
                      lineHeight: "24px",
                      textAlign: "left",
                      paddingLeft: "3px",
                      "@media (max-width : 991px)": {
                        fontSize: "14px",
                      },
                    }}
                  >
                    Systematically retrieve data or other content from the
                    Services to create or compile, directly or indirectly, a
                    collection, compilation, database, or directory without
                    written permission from us.
                    <br />
                    &nbsp;
                    <br />
                    Trick, defraud, or mislead us and other users, especially in
                    any attempt to learn sensitive account information such as
                    user passwords.
                    <br />
                    &nbsp;
                    <br />
                    Circumvent, disable, or otherwise interfere with
                    security-related features of the Services, including
                    features that prevent or restrict the use or copying of any
                    Content or enforce limitations on the use of the Services
                    and/or the Content contained therein.
                    <br />
                    &nbsp;
                    <br />
                    Disparage, tarnish, or otherwise harm, in our opinion, us
                    and/or the Services.
                    <br />
                    &nbsp;
                    <br />
                    Use any information obtained from the Services in order to
                    harass, abuse, or harm another person.
                    <br />
                    &nbsp;
                    <br />
                    Make improper use of our support services or submit false
                    reports of abuse or misconduct.
                    <br />
                    &nbsp;
                    <br />
                    Use the Services in a manner inconsistent with any
                    applicable laws or regulations.
                    <br />
                    &nbsp;
                    <br />
                    Engage in unauthorized framing of or linking to the
                    Services.
                    <br />
                    &nbsp;
                    <br />
                    Upload or transmit (or attempt to upload or to transmit)
                    viruses, Trojan horses, or other material, including
                    excessive use of capital letters and spamming (continuous
                    posting of repetitive text), that interferes with any
                    party’s uninterrupted use and enjoyment of the Services or
                    modifies, impairs, disrupts, alters, or interferes with the
                    use, features, functions, operation, or maintenance of the
                    Services.
                    <br />
                    &nbsp;
                    <br />
                    Engage in any automated use of the system, such as using
                    scripts to send comments or messages, or using any data
                    mining, robots, or similar data gathering and extraction
                    tools.
                    <br />
                    &nbsp;
                    <br />
                    Delete the copyright or other proprietary rights notice from
                    any Content.
                    <br />
                    &nbsp;
                    <br />
                    Attempt to impersonate another user or person or use the
                    username of another user.
                    <br />
                    &nbsp;
                    <br />
                    Upload or transmit (or attempt to upload or to transmit) any
                    material that acts as a passive or active information
                    collection or transmission mechanism, including without
                    limitation, clear graphics interchange formats ("gifs"), 1×1
                    pixels, web bugs, cookies, or other similar devices
                    (sometimes referred to as "spyware" or "passive collection
                    mechanisms" or "pcms").
                    <br />
                    &nbsp;
                    <br />
                    Interfere with, disrupt, or create an undue burden on the
                    Services or the networks or services connected to the
                    Services.
                    <br />
                    &nbsp;
                    <br />
                    Harass, annoy, intimidate, or threaten any of our employees
                    or agents engaged in providing any portion of the Services
                    to you.
                    <br />
                    &nbsp;
                    <br />
                    Attempt to bypass any measures of the Services designed to
                    prevent or restrict access to the Services, or any portion
                    of the Services.
                    <br />
                    &nbsp;
                    <br />
                    Copy or adapt the Services' software, including but not
                    limited to Flash, PHP, HTML, JavaScript, or other code.
                    <br />
                    &nbsp;
                    <br />
                    Except as permitted by applicable law, decipher, decompile,
                    disassemble, or reverse engineer any of the software
                    comprising or in any way making up a part of the Services.
                    <br />
                    &nbsp;
                    <br />
                    Except as may be the result of standard search engine or
                    Internet browser usage, use, launch, develop, or distribute
                    any automated system, including without limitation, any
                    spider, robot, cheat utility, scraper, or offline reader
                    that accesses the Services, or use or launch any
                    unauthorized script or other software.
                    <br />
                    &nbsp;
                    <br />
                    Use a buying agent or purchasing agent to make purchases on
                    the Services.
                    <br />
                    &nbsp;
                    <br />
                    Make any unauthorized use of the Services, including
                    collecting usernames and/or email addresses of users by
                    electronic or other means for the purpose of sending
                    unsolicited email, or creating user accounts by automated
                    means or under false pretenses.
                    <br />
                    &nbsp;
                    <br />
                    Use the Services as part of any effort to compete with us or
                    otherwise use the Services and/or the Content for any
                    revenue-generating endeavor or commercial enterprise.
                    <br />
                    &nbsp;
                    <br />
                    Create multiple accounts
                    <br />
                    &nbsp;
                    <br />
                    Attempt to use ModalX for anything other than their
                    advertised intended purpose.
                    <br />
                    &nbsp;
                    <br />
                    Spam people.
                    <br />
                    &nbsp;
                    <br />
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* section 11 */}

        {/* section 12 */}
        <Box
          ref={explanation9Ref}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "900px",
              marginTop: "80px",
              gap: "30px",
              opacity: "0px",
              "@media (max-width : 991px)": {
                marginTop: "40px",
                minWidth: "350px",
                padding: "0px 4.4% 0px 4.4%",
                marginBottom: "487px",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: "600px",
                  lineHeight: "30px",
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.9)",
                  "@media (max-width : 991px)": {
                    fontSize: "16px",
                  },
                }}
              >
                9. USER GENERATED CONTRIBUTIONS
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                marginTop: "30px",
                "@media (max-width : 991px)": {
                  marginTop: "24px",
                },
              }}
            >
              {" "}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  paddingLeft: 2,
                }}
              >
                <Box>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400px",
                      lineHeight: "24px",
                      textAlign: "left",
                      "@media (max-width : 991px)": {
                        fontSize: "14px",
                      },
                    }}
                  >
                    The Services may invite you to chat, contribute to, or
                    participate in blogs, message boards, online forums, and
                    other functionality, and may provide you with the
                    opportunity to create, submit, post, display, transmit,
                    perform, publish, distribute, or broadcast content and
                    materials to us or on the Services, including but not
                    limited to text, writings, video, audio, photographs,
                    graphics, comments, suggestions, or personal information or
                    other material (collectively, "Contributions").
                    Contributions may be viewable by other users of the Services
                    and through third-party websites. As such, any Contributions
                    you transmit may be treated as non-confidential and
                    non-proprietary. When you create or make available any
                    Contributions, you thereby represent and warrant that:
                  </Typography>
                </Box>
                <Box sx={{ marginLeft: 4 }}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400px",
                      lineHeight: "24px",
                      textAlign: "left",
                      paddingLeft: "3px",
                      "@media (max-width : 991px)": {
                        fontSize: "14px",
                      },
                    }}
                  >
                    The creation, distribution, transmission, public display, or
                    performance, and the accessing, downloading, or copying of
                    your Contributions do not and will not infringe the
                    proprietary rights, including but not limited to the
                    copyright, patent, trademark, trade secret, or moral rights
                    of any third party.
                    <br />
                    &nbsp;
                    <br />
                    You are the creator and owner of or have the necessary
                    licenses, rights, consents, releases, and permissions to use
                    and to authorize us, the Services, and other users of the
                    Services to use your Contributions in any manner
                    contemplated by the Services and these Legal Terms.
                    <br />
                    &nbsp;
                    <br />
                    You have the written consent, release, and/or permission of
                    each and every identifiable individual person in your
                    Contributions to use the name or likeness of each and every
                    such identifiable individual person to enable inclusion and
                    use of your Contributions in any manner contemplated by the
                    Services and these Legal Terms.
                    <br />
                    &nbsp;
                    <br />
                    Your Contributions are not false, inaccurate, or misleading.
                    <br />
                    &nbsp;
                    <br />
                    Your Contributions are not unsolicited or unauthorized
                    advertising, promotional materials, pyramid schemes, chain
                    letters, spam, mass mailings, or other forms of
                    solicitation.
                    <br />
                    &nbsp;
                    <br />
                    Your Contributions are not obscene, lewd, lascivious,
                    filthy, violent, harassing, libelous, slanderous, or
                    otherwise objectionable (as determined by us).
                    <br />
                    &nbsp;
                    <br />
                    Your Contributions do not ridicule, mock, disparage,
                    intimidate, or abuse anyone.
                    <br />
                    &nbsp;
                    <br />
                    Your Contributions are not used to harass or threaten (in
                    the legal sense of those terms) any other person and to
                    promote violence against a specific person or class of
                    people.
                    <br />
                    &nbsp;
                    <br />
                    Your Contributions do not violate any applicable law,
                    regulation, or rule.
                    <br />
                    &nbsp;
                    <br />
                    Your Contributions do not violate the privacy or publicity
                    rights of any third party.
                    <br />
                    &nbsp;
                    <br />
                    Your Contributions do not violate any applicable law
                    concerning child pornography, or otherwise intended to
                    protect the health or well-being of minors.
                    <br />
                    &nbsp;
                    <br />
                    Your Contributions do not include any offensive comments
                    that are connected to race, national origin, gender, sexual
                    preference, or physical handicap.
                    <br />
                    &nbsp;
                    <br />
                    Your Contributions do not otherwise violate, or link to
                    material that violates, any provision of these Legal Terms,
                    or any applicable law or regulation.
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400px",
                      lineHeight: "24px",
                      textAlign: "left",
                      paddingLeft: "3px",
                      "@media (max-width : 991px)": {
                        fontSize: "14px",
                      },
                    }}
                  >
                    Any use of the Services in violation of the foregoing
                    violates these Legal Terms and may result in, among other
                    things, termination or suspension of your rights to use the
                    Services.
                    <br />
                    &nbsp;
                    <br />
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* section 12 */}

        {/* section 13 */}
        <Box
          ref={explanation10Ref}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "900px",
              marginTop: "80px",
              gap: "30px",
              opacity: "0px",
              "@media (max-width : 991px)": {
                marginTop: "40px",
                minWidth: "350px",
                padding: "0px 4.4% 0px 4.4%",
                marginBottom: "487px",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: "600px",
                  lineHeight: "30px",
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.9)",
                  "@media (max-width : 991px)": {
                    fontSize: "16px",
                  },
                }}
              >
                10. CONTRIBUTION LICENSE
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                marginTop: "30px",
                "@media (max-width : 991px)": {
                  marginTop: "24px",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  paddingLeft: 2,
                }}
              >
                <Box>
                  <Typography sx={commonStyles}>
                    By posting your Contributions to any part of the Services or
                    making Contributions accessible to the Services by linking
                    your account from the Services to any of your social
                    networking accounts, you automatically grant, and you
                    represent and warrant that you have the right to grant, to
                    us an unrestricted, unlimited, irrevocable, perpetual,
                    non-exclusive, transferable, royalty-free, fully-paid,
                    worldwide right, and license to host, use, copy, reproduce,
                    disclose, sell, resell, publish, broadcast, retitle,
                    archive, store, cache, publicly perform, publicly display,
                    reformat, translate, transmit, excerpt (in whole or in
                    part), and distribute such Contributions (including, without
                    limitation, your image and voice) for any purpose,
                    commercial, advertising, or otherwise, and to prepare
                    derivative works of, or incorporate into other works, such
                    Contributions, and grant and authorize sub-licenses of the
                    foregoing. The use and distribution may occur in any media
                    formats and through any media channels.
                    <br />
                    &nbsp;
                    <br />
                    This license will apply to any form, media, or technology
                    now known or hereafter developed, and includes our use of
                    your name, company name, and franchise name, as applicable,
                    and any of the trademarks, service marks, trade names,
                    logos, and personal and commercial images you provide. You
                    waive all moral rights in your Contributions, and you
                    warrant that moral rights have not otherwise been asserted
                    in your Contributions.
                    <br />
                    &nbsp;
                    <br />
                    We do not assert any ownership over your Contributions. You
                    retain full ownership of all of your Contributions and any
                    intellectual property rights or other proprietary rights
                    associated with your Contributions. We are not liable for
                    any statements or representations in your Contributions
                    provided by you in any area on the Services. You are solely
                    responsible for your Contributions to the Services and you
                    expressly agree to exonerate us from any and all
                    responsibility and to refrain from any legal action against
                    us regarding your Contributions.
                    <br />
                    &nbsp;
                    <br />
                    We have the right, in our sole and absolute discretion, (1)
                    to edit, redact, or otherwise change any Contributions; (2)
                    to re-categorize any Contributions to place them in more
                    appropriate locations on the Services; and (3) to pre-screen
                    or delete any Contributions at any time and for any reason,
                    without notice. We have no obligation to monitor your
                    Contributions.
                    <br />
                    &nbsp;
                    <br />
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* section 13 */}

        {/* section 14 */}
        <Box
          ref={explanation11Ref}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "900px",
              marginTop: "80px",
              gap: "30px",
              opacity: "0px",
              "@media (max-width : 991px)": {
                marginTop: "40px",
                minWidth: "350px",
                padding: "0px 4.4% 0px 4.4%",
                marginBottom: "487px",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: "600px",
                  lineHeight: "30px",
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.9)",
                  "@media (max-width : 991px)": {
                    fontSize: "16px",
                  },
                }}
              >
                11. GUIDELINES FOR REVIEWS
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                marginTop: "30px",
                "@media (max-width : 991px)": {
                  marginTop: "24px",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  paddingLeft: 2,
                }}
              >
                <Box>
                  <Typography sx={commonStyles}>
                    We may provide you areas on the Services to leave reviews or
                    ratings. When posting a review, you must comply with the
                    following criteria: (1) you should have firsthand experience
                    with the person/entity being reviewed; (2) your reviews
                    should not contain offensive profanity, or abusive, racist,
                    offensive, or hateful language; (3) your reviews should not
                    contain discriminatory references based on religion, race,
                    gender, national origin, age, marital status, sexual
                    orientation, or disability; (4) your reviews should not
                    contain references to illegal activity; (5) you should not
                    be affiliated with competitors if posting negative reviews;
                    (6) you should not make any conclusions as to the legality
                    of conduct; (7) you may not post any false or misleading
                    statements; and (8) you may not organize a campaign
                    encouraging others to post reviews, whether positive or
                    negative.
                    <br />
                    &nbsp;
                    <br />
                    We may accept, reject, or remove reviews in our sole
                    discretion. We have absolutely no obligation to screen
                    reviews or to delete reviews, even if anyone considers
                    reviews objectionable or inaccurate. Reviews are not
                    endorsed by us, and do not necessarily represent our
                    opinions or the views of any of our affiliates or partners.
                    We do not assume liability for any review or for any claims,
                    liabilities, or losses resulting from any review. By posting
                    a review, you hereby grant to us a perpetual, non-exclusive,
                    worldwide, royalty-free, fully paid, assignable, and
                    sublicensable right and license to reproduce, modify,
                    translate, transmit by any means, display, perform, and/or
                    distribute all content relating to review.
                    <br />
                    &nbsp;
                    <br />
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* section 14 */}

        {/* section 15 */}
        <Box
          ref={explanation12Ref}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "900px",
              marginTop: "80px",
              gap: "30px",
              opacity: "0px",
              "@media (max-width : 991px)": {
                marginTop: "40px",
                minWidth: "350px",
                padding: "0px 4.4% 0px 4.4%",
                marginBottom: "487px",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: "600px",
                  lineHeight: "30px",
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.9)",
                  "@media (max-width : 991px)": {
                    fontSize: "16px",
                  },
                }}
              >
                12. SOCIAL MEDIA
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                marginTop: "30px",
                "@media (max-width : 991px)": {
                  marginTop: "24px",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  paddingLeft: 2,
                }}
              >
                <Box>
                  <Typography sx={commonStyles}>
                    As part of the functionality of the Services, you may link
                    your account with online accounts you have with third-party
                    service providers (each such account, a "Third-Party
                    Account") by either: (1) providing your Third-Party Account
                    login information through the Services; or (2) allowing us
                    to access your Third-Party Account, as is permitted under
                    the applicable terms and conditions that govern your use of
                    each Third-Party Account. You represent and warrant that you
                    are entitled to disclose your Third-Party Account login
                    information to us and/or grant us access to your Third-Party
                    Account, without breach by you of any of the terms and
                    conditions that govern your use of the applicable
                    Third-Party Account, and without obligating us to pay any
                    fees or making us subject to any usage limitations imposed
                    by the third-party service provider of the Third-Party
                    Account. By granting us access to any Third-Party Accounts,
                    you understand that (1) we may access, make available, and
                    store (if applicable) any content that you have provided to
                    and stored in your Third-Party Account (the "Social Network
                    Content") so that it is available on and through the
                    Services via your account, including without limitation any
                    friend lists and (2) we may submit to and receive from your
                    Third-Party Account additional information to the extent you
                    are notified when you link your account with the Third-Party
                    Account. Depending on the Third-Party Accounts you choose
                    and subject to the privacy settings that you have set in
                    such Third-Party Accounts, personally identifiable
                    information that you post to your Third-Party Accounts may
                    be available on and through your account on the Services.
                    Please note that if a Third-Party Account or associated
                    service becomes unavailable or our access to such
                    Third-Party Account is terminated by the third-party service
                    provider, then Social Network Content may no longer be
                    available on and through the Services. You will have the
                    ability to disable the connection between your account on
                    the Services and your Third-Party Accounts at any time.
                    PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE THIRD-PARTY
                    SERVICE PROVIDERS ASSOCIATED WITH YOUR THIRD-PARTY ACCOUNTS
                    IS GOVERNED SOLELY BY YOUR AGREEMENT(S) WITH SUCH
                    THIRD-PARTY SERVICE PROVIDERS. We make no effort to review
                    any Social Network Content for any purpose, including but
                    not limited to, for accuracy, legality, or non-infringement,
                    and we are not responsible for any Social Network Content.
                    You acknowledge and agree that we may access your email
                    address book associated with a Third-Party Account and your
                    contacts list stored on your mobile device or tablet
                    computer solely for purposes of identifying and informing
                    you of those contacts who have also registered to use the
                    Services. You can deactivate the connection between the
                    Services and your Third-Party Account by contacting us using
                    the contact information below or through your account
                    settings (if applicable). We will attempt to delete any
                    information stored on our servers that was obtained through
                    such Third-Party Account, except the username and profile
                    picture that become associated with your account.
                    <br />
                    &nbsp;
                    <br />
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* section 15 */}

        {/* section 16 */}
        <Box
          ref={explanation13Ref}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "900px",
              marginTop: "80px",
              gap: "30px",
              opacity: "0px",
              "@media (max-width : 991px)": {
                marginTop: "40px",
                minWidth: "350px",
                padding: "0px 4.4% 0px 4.4%",
                marginBottom: "487px",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: "600px",
                  lineHeight: "30px",
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.9)",
                  "@media (max-width : 991px)": {
                    fontSize: "16px",
                  },
                }}
              >
                13. THIRD-PARTY WEBSITES AND CONTENT
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                marginTop: "30px",
                "@media (max-width : 991px)": {
                  marginTop: "24px",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  paddingLeft: 2,
                }}
              >
                <Box>
                  <Typography sx={commonStyles}>
                    The Services may contain (or you may be sent via the Site)
                    links to other websites ("Third-Party Websites") as well as
                    articles, photographs, text, graphics, pictures, designs,
                    music, sound, video, information, applications, software,
                    and other content or items belonging to or originating from
                    third parties ("Third-Party Content"). Such Third-Party
                    Websites and Third-Party Content are not investigated,
                    monitored, or checked for accuracy, appropriateness, or
                    completeness by us, and we are not responsible for any
                    Third-Party Websites accessed through the Services or any
                    Third-Party Content posted on, available through, or
                    installed from the Services, including the content,
                    accuracy, offensiveness, opinions, reliability, privacy
                    practices, or other policies of or contained in the
                    Third-Party Websites or the Third-Party Content. Inclusion
                    of, linking to, or permitting the use or installation of any
                    Third-Party Websites or any Third-Party Content does not
                    imply approval or endorsement thereof by us. If you decide
                    to leave the Services and access the Third-Party Websites or
                    to use or install any Third-Party Content, you do so at your
                    own risk, and you should be aware these Legal Terms no
                    longer govern. You should review the applicable terms and
                    policies, including privacy and data gathering practices, of
                    any website to which you navigate from the Services or
                    relating to any applications you use or install from the
                    Services. Any purchases you make through Third-Party
                    Websites will be through other websites and from other
                    companies, and we take no responsibility whatsoever in
                    relation to such purchases which are exclusively between you
                    and the applicable third party. You agree and acknowledge
                    that we do not endorse the products or services offered on
                    Third-Party Websites and you shall hold us blameless from
                    any harm caused by your purchase of such products or
                    services. Additionally, you shall hold us blameless from any
                    losses sustained by you or harm caused to you relating to or
                    resulting in any way from any Third-Party Content or any
                    contact with Third-Party Websites.
                    <br />
                    &nbsp;
                    <br />
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* section 16 */}

        {/* section 17 */}
        <Box
          ref={explanation14Ref}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "900px",
              marginTop: "80px",
              gap: "30px",
              opacity: "0px",
              "@media (max-width : 991px)": {
                marginTop: "40px",
                minWidth: "350px",
                padding: "0px 4.4% 0px 4.4%",
                marginBottom: "487px",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: "600px",
                  lineHeight: "30px",
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.9)",
                  "@media (max-width : 991px)": {
                    fontSize: "16px",
                  },
                }}
              >
                14. SERVICES MANAGEMENT
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                marginTop: "30px",
                "@media (max-width : 991px)": {
                  marginTop: "24px",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  paddingLeft: 2,
                }}
              >
                <Box>
                  <Typography sx={commonStyles}>
                    We reserve the right, but not the obligation, to: (1)
                    monitor the Services for violations of these Legal Terms;
                    (2) take appropriate legal action against anyone who, in our
                    sole discretion, violates the law or these Legal Terms,
                    including without limitation, reporting such user to law
                    enforcement authorities; (3) in our sole discretion and
                    without limitation, refuse, restrict access to, limit the
                    availability of, or disable (to the extent technologically
                    feasible) any of your Contributions or any portion thereof;
                    (4) in our sole discretion and without limitation, notice,
                    or liability, to remove from the Services or otherwise
                    disable all files and content that are excessive in size or
                    are in any way burdensome to our systems; and (5) otherwise
                    manage the Services in a manner designed to protect our
                    rights and property and to facilitate the proper functioning
                    of the Services
                    <br />
                    &nbsp;
                    <br />
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* section 17 */}

        {/* section 18 */}
        <Box
          ref={explanation15Ref}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "900px",
              marginTop: "80px",
              gap: "30px",
              opacity: "0px",
              "@media (max-width : 991px)": {
                marginTop: "40px",
                minWidth: "350px",
                padding: "0px 4.4% 0px 4.4%",
                marginBottom: "487px",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: "600px",
                  lineHeight: "30px",
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.9)",
                  "@media (max-width : 991px)": {
                    fontSize: "16px",
                  },
                }}
              >
                15. PRIVACY POLICY
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                marginTop: "30px",
                "@media (max-width : 991px)": {
                  marginTop: "24px",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  paddingLeft: 2,
                }}
              >
                <Box>
                  <Typography sx={commonStyles}>
                    We care about data privacy and security. Please review our
                    Privacy Policy: https://modalx.ai/privacy-policy/. By using
                    the Services, you agree to be bound by our Privacy Policy,
                    which is incorporated into these Legal Terms. Please be
                    advised the Services are hosted in the Canada. If you access
                    the Services from any other region of the world with laws or
                    other requirements governing personal data collection, use,
                    or disclosure that differ from applicable laws in the
                    Canada, then through your continued use of the Services, you
                    are transferring your data to the Canada, and you expressly
                    consent to have your data transferred to and processed in
                    the Canada.
                    <br />
                    &nbsp;
                    <br />
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* section 18 */}

        {/* section 19 */}
        <Box
          ref={explanation16Ref}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "900px",
              marginTop: "80px",
              gap: "30px",
              opacity: "0px",
              "@media (max-width : 991px)": {
                marginTop: "40px",
                minWidth: "350px",
                padding: "0px 4.4% 0px 4.4%",
                marginBottom: "487px",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: "600px",
                  lineHeight: "30px",
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.9)",
                  "@media (max-width : 991px)": {
                    fontSize: "16px",
                  },
                }}
              >
                16. COPYRIGHT INFRINGEMENTS
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                marginTop: "30px",
                "@media (max-width : 991px)": {
                  marginTop: "24px",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  paddingLeft: 2,
                }}
              >
                <Box>
                  <Typography sx={commonStyles}>
                    We respect the intellectual property rights of others. If
                    you believe that any material available on or through the
                    Services infringes upon any copyright you own or control,
                    please immediately notify us using the contact information
                    provided below (a "Notification"). A copy of your
                    Notification will be sent to the person who posted or stored
                    the material addressed in the Notification. Please be
                    advised that pursuant to applicable law you may be held
                    liable for damages if you make material misrepresentations
                    in a Notification. Thus, if you are not sure that material
                    located on or linked to by the Services infringes your
                    copyright, you should consider first contacting an attorney.
                    <br />
                    &nbsp;
                    <br />
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* section 19 */}

        {/* section 20 */}
        <Box
          ref={explanation17Ref}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "900px",
              marginTop: "80px",
              gap: "30px",
              opacity: "0px",
              "@media (max-width : 991px)": {
                marginTop: "40px",
                minWidth: "350px",
                padding: "0px 4.4% 0px 4.4%",
                marginBottom: "487px",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: "600px",
                  lineHeight: "30px",
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.9)",
                  "@media (max-width : 991px)": {
                    fontSize: "16px",
                  },
                }}
              >
                17. TERM AND TERMINATION
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                marginTop: "30px",
                "@media (max-width : 991px)": {
                  marginTop: "24px",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  paddingLeft: 2,
                }}
              >
                <Box>
                  <Typography sx={commonStyles}>
                    These Legal Terms shall remain in full force and effect
                    while you use the Services. WITHOUT LIMITING ANY OTHER
                    PROVISION OF THESE LEGAL TERMS, WE RESERVE THE RIGHT TO, IN
                    OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY
                    ACCESS TO AND USE OF THE SERVICES (INCLUDING BLOCKING
                    CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR
                    NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY
                    REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE
                    LEGAL TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY
                    TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES OR
                    DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU
                    POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
                    <br />
                    &nbsp;
                    <br />
                    If we terminate or suspend your account for any reason, you
                    are prohibited from registering and creating a new account
                    under your name, a fake or borrowed name, or the name of any
                    third party, even if you may be acting on behalf of the
                    third party. In addition to terminating or suspending your
                    account, we reserve the right to take appropriate legal
                    action, including without limitation pursuing civil,
                    criminal, and injunctive redress.
                    <br />
                    &nbsp;
                    <br />
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* section 20 */}

        {/* section 21 */}
        <Box
          ref={explanation18Ref}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "900px",
              marginTop: "80px",
              gap: "30px",
              opacity: "0px",
              "@media (max-width : 991px)": {
                marginTop: "40px",
                minWidth: "350px",
                padding: "0px 4.4% 0px 4.4%",
                marginBottom: "487px",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: "600px",
                  lineHeight: "30px",
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.9)",
                  "@media (max-width : 991px)": {
                    fontSize: "16px",
                  },
                }}
              >
                18. MODIFICATIONS AND INTERRUPTIONS
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                marginTop: "30px",
                "@media (max-width : 991px)": {
                  marginTop: "24px",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  paddingLeft: 2,
                }}
              >
                <Box>
                  <Typography sx={commonStyles}>
                    We reserve the right to change, modify, or remove the
                    contents of the Services at any time or for any reason at
                    our sole discretion without notice. However, we have no
                    obligation to update any information on our Services. We
                    will not be liable to you or any third party for any
                    modification, price change, suspension, or discontinuance of
                    the Services.
                    <br />
                    &nbsp;
                    <br />
                    We cannot guarantee the Services will be available at all
                    times. We may experience hardware, software, or other
                    problems or need to perform maintenance related to the
                    Services, resulting in interruptions, delays, or errors. We
                    reserve the right to change, revise, update, suspend,
                    discontinue, or otherwise modify the Services at any time or
                    for any reason without notice to you. You agree that we have
                    no liability whatsoever for any loss, damage, or
                    inconvenience caused by your inability to access or use the
                    Services during any downtime or discontinuance of the
                    Services. Nothing in these Legal Terms will be construed to
                    obligate us to maintain and support the Services or to
                    supply any corrections, updates, or releases in connection
                    therewith.
                    <br />
                    &nbsp;
                    <br />
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* section 21 */}

        {/* section 22 */}
        <Box
          ref={explanation19Ref}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "900px",
              marginTop: "80px",
              gap: "30px",
              opacity: "0px",
              "@media (max-width : 991px)": {
                marginTop: "40px",
                minWidth: "350px",
                padding: "0px 4.4% 0px 4.4%",
                marginBottom: "487px",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: "600px",
                  lineHeight: "30px",
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.9)",
                  "@media (max-width : 991px)": {
                    fontSize: "16px",
                  },
                }}
              >
                19. GOVERNING LAW
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                marginTop: "30px",
                "@media (max-width : 991px)": {
                  marginTop: "24px",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  paddingLeft: 2,
                }}
              >
                <Box>
                  <Typography sx={commonStyles}>
                    These Legal Terms and your use of the Services are governed
                    by and construed in accordance with the laws of the State of
                    Alberta applicable to agreements made and to be entirely
                    performed within the State of Alberta, without regard to its
                    conflict of law principles.
                    <br />
                    &nbsp;
                    <br />
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* section 22 */}

        {/* section 23 */}
        <Box
          ref={explanation20Ref}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "900px",
              marginTop: "80px",
              gap: "30px",
              opacity: "0px",
              "@media (max-width : 991px)": {
                marginTop: "40px",
                minWidth: "350px",
                padding: "0px 4.4% 0px 4.4%",
                marginBottom: "487px",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: "600px",
                  lineHeight: "30px",
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.9)",
                  "@media (max-width : 991px)": {
                    fontSize: "16px",
                  },
                }}
              >
                20. DISPUTE RESOLUTION
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                marginTop: "30px",
                "@media (max-width : 991px)": {
                  marginTop: "24px",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  paddingLeft: 2,
                }}
              >
                <Box>
                  <Typography sx={commonStyles}>
                    Informal Negotiations
                    <br />
                    &nbsp;
                    <br />
                    To expedite resolution and control the cost of any dispute,
                    controversy, or claim related to these Legal Terms (each a
                    "Dispute" and collectively, the "Disputes") brought by
                    either you or us (individually, a "Party" and collectively,
                    the "Parties"), the Parties agree to first attempt to
                    negotiate any Dispute (except those Disputes expressly
                    provided below) informally for at least thirty (30)days
                    before initiating arbitration. Such informal negotiations
                    commence upon written notice from one Party to the other
                    Party.
                    <br />
                    &nbsp;
                    <br />
                    Binding Arbitration
                    <br />
                    &nbsp;
                    <br />
                    If the Parties are unable to resolve a Dispute through
                    informal negotiations, the Dispute (except those Disputes
                    expressly excluded below) will be finally and exclusively
                    resolved by binding arbitration. YOU UNDERSTAND THAT WITHOUT
                    THIS PROVISION, YOU WOULD HAVE THE RIGHT TO SUE IN COURT AND
                    HAVE A JURY TRIAL. The arbitration shall be commenced and
                    conducted under the Commercial Arbitration Rules of the
                    American Arbitration Association ("AAA") and, where
                    appropriate, the AAA’s Supplementary Procedures for Consumer
                    Related Disputes ("AAA Consumer Rules"), both of which are
                    available at the American Arbitration Association (AAA)
                    website. Your arbitration fees and your share of arbitrator
                    compensation shall be governed by the AAA Consumer Rules
                    and, where appropriate, limited by the AAA Consumer Rules.
                    The arbitration may be conducted in person, through the
                    submission of documents, by phone, or online. The arbitrator
                    will make a decision in writing, but need not provide a
                    statement of reasons unless requested by either Party. The
                    arbitrator must follow applicable law, and any award may be
                    challenged if the arbitrator fails to do so. Except where
                    otherwise required by the applicable AAA rules or applicable
                    law, the arbitration will take place in Alberta. Except as
                    otherwise provided herein, the Parties may litigate in court
                    to compel arbitration, stay proceedings pending arbitration,
                    or to confirm, modify, vacate, or enter judgment on the
                    award entered by the arbitrator.
                    <br />
                    &nbsp;
                    <br />
                    If for any reason, a Dispute proceeds in court rather than
                    arbitration, the Dispute shall be commenced or prosecuted in
                    the state and federal courts located in Alberta, and the
                    Parties hereby consent to, and waive all defenses of lack of
                    personal jurisdiction, and forum non conveniens with respect
                    to venue and jurisdiction in such state and federal courts.
                    Application of the United Nations Convention on Contracts
                    for the International Sale of Goods and the Uniform Computer
                    Information Transaction Act (UCITA) are excluded from these
                    Legal Terms.
                    <br />
                    &nbsp;
                    <br />
                    In no event shall any Dispute brought by either Party
                    related in any way to the Services be commenced more than
                    one (1)years after the cause of action arose. If this
                    provision is found to be illegal or unenforceable, then
                    neither Party will elect to arbitrate any Dispute falling
                    within that portion of this provision found to be illegal or
                    unenforceable and such Dispute shall be decided by a court
                    of competent jurisdiction within the courts listed for
                    jurisdiction above, and the Parties agree to submit to the
                    personal jurisdiction of that court.
                    <br />
                    &nbsp;
                    <br />
                    Restrictions
                    <br />
                    &nbsp;
                    <br />
                    The Parties agree that any arbitration shall be limited to
                    the Dispute between the Parties individually. To the full
                    extent permitted by law, (a) no arbitration shall be joined
                    with any other proceeding; (b) there is no right or
                    authority for any Dispute to be arbitrated on a class-action
                    basis or to utilize class action procedures; and (c) there
                    is no right or authority for any Dispute to be brought in a
                    purported representative capacity on behalf of the general
                    public or any other persons.
                    <br />
                    &nbsp;
                    <br />
                    Exceptions to Informal Negotiations and Arbitration
                    <br />
                    &nbsp;
                    <br />
                    The Parties agree that the following Disputes are not
                    subject to the above provisions concerning informal
                    negotiations binding arbitration: (a) any Disputes seeking
                    to enforce or protect, or concerning the validity of, any of
                    the intellectual property rights of a Party; (b) any Dispute
                    related to, or arising from, allegations of theft, piracy,
                    invasion of privacy, or unauthorized use; and (c) any claim
                    for injunctive relief. If this provision is found to be
                    illegal or unenforceable, then neither Party will elect to
                    arbitrate any Dispute falling within that portion of this
                    provision found to be illegal or unenforceable and such
                    Dispute shall be decided by a court of competent
                    jurisdiction within the courts listed for jurisdiction
                    above, and the Parties agree to submit to the personal
                    jurisdiction of that court.
                    <br />
                    &nbsp;
                    <br />
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* section 23 */}

        {/* section 24 */}
        <Box
          ref={explanation21Ref}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "900px",
              marginTop: "80px",
              gap: "30px",
              opacity: "0px",
              "@media (max-width : 991px)": {
                marginTop: "40px",
                minWidth: "350px",
                padding: "0px 4.4% 0px 4.4%",
                marginBottom: "487px",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: "600px",
                  lineHeight: "30px",
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.9)",
                  "@media (max-width : 991px)": {
                    fontSize: "16px",
                  },
                }}
              >
                21. CORRECTIONS
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                marginTop: "30px",
                "@media (max-width : 991px)": {
                  marginTop: "24px",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  paddingLeft: 2,
                }}
              >
                <Box>
                  <Typography sx={commonStyles}>
                    There may be information on the Services that contains
                    typographical errors, inaccuracies, or omissions, including
                    descriptions, pricing, availability, and various other
                    information. We reserve the right to correct any errors,
                    inaccuracies, or omissions and to change or update the
                    information on the Services at any time, without prior
                    notice.
                    <br />
                    &nbsp;
                    <br />
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* section 24 */}

        {/* section 25 */}
        <Box
          ref={explanation22Ref}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "900px",
              marginTop: "80px",
              gap: "30px",
              opacity: "0px",
              "@media (max-width : 991px)": {
                marginTop: "40px",
                minWidth: "350px",
                padding: "0px 4.4% 0px 4.4%",
                marginBottom: "487px",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: "600px",
                  lineHeight: "30px",
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.9)",
                  "@media (max-width : 991px)": {
                    fontSize: "16px",
                  },
                }}
              >
                22. DISCLAIMER
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                marginTop: "30px",
                "@media (max-width : 991px)": {
                  marginTop: "24px",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  paddingLeft: 2,
                }}
              >
                <Box>
                  <Typography sx={commonStyles}>
                    THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE
                    BASIS. YOU AGREE THAT YOUR USE OF THE SERVICES WILL BE AT
                    YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE
                    DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION
                    WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT
                    LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY,
                    FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE
                    MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR
                    COMPLETENESS OF THE SERVICES' CONTENT OR THE CONTENT OF ANY
                    WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND
                    WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1)
                    ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS,
                    (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE
                    WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE
                    SERVICES, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR
                    SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION
                    AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY
                    INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE
                    SERVICES, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE
                    WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY
                    THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY
                    CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND
                    INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED,
                    TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES.
                    WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
                    RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR
                    OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY
                    HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION
                    FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT
                    BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING
                    ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF
                    PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR
                    SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD
                    USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE
                    APPROPRIATE.
                    <br />
                    &nbsp;
                    <br />
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* section 25 */}

        {/* section 26 */}
        <Box
          ref={explanation23Ref}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "900px",
              marginTop: "80px",
              gap: "30px",
              opacity: "0px",
              "@media (max-width : 991px)": {
                marginTop: "40px",
                minWidth: "350px",
                padding: "0px 4.4% 0px 4.4%",
                marginBottom: "487px",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: "600px",
                  lineHeight: "30px",
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.9)",
                  "@media (max-width : 991px)": {
                    fontSize: "16px",
                  },
                }}
              >
                23. LIMITATIONS OF LIABILITY
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                marginTop: "30px",
                "@media (max-width : 991px)": {
                  marginTop: "24px",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  paddingLeft: 2,
                }}
              >
                <Box>
                  <Typography sx={commonStyles}>
                    IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS
                    BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT,
                    INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR
                    PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS
                    OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE
                    SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF
                    SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY
                    CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE
                    WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT
                    ALL TIMES BE LIMITED TO THE LESSER OF THE AMOUNT PAID, IF
                    ANY, BY YOU TO USDURING THE SIX (6)MONTH PERIOD PRIOR TO ANY
                    CAUSE OF ACTION ARISINGOR $10,000.00 USD.CERTAIN US STATE
                    LAWS AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON
                    IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN
                    DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE
                    ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND
                    YOU MAY HAVE ADDITIONAL RIGHTS.
                    <br />
                    &nbsp;
                    <br />
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* section 26 */}

        {/* section 27 */}
        <Box
          ref={explanation24Ref}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "900px",
              marginTop: "80px",
              gap: "30px",
              opacity: "0px",
              "@media (max-width : 991px)": {
                marginTop: "40px",
                minWidth: "350px",
                padding: "0px 4.4% 0px 4.4%",
                marginBottom: "487px",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: "600px",
                  lineHeight: "30px",
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.9)",
                  "@media (max-width : 991px)": {
                    fontSize: "16px",
                  },
                }}
              >
                24. INDEMNIFICATION
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                marginTop: "30px",
                "@media (max-width : 991px)": {
                  marginTop: "24px",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  paddingLeft: 2,
                }}
              >
                <Box>
                  <Typography sx={commonStyles}>
                    You agree to defend, indemnify, and hold us harmless,
                    including our subsidiaries, affiliates, and all of our
                    respective officers, agents, partners, and employees, from
                    and against any loss, damage, liability, claim, or demand,
                    including reasonable attorneys’ fees and expenses, made by
                    any third party due to or arising out of: (1) your
                    Contributions; (2) use of the Services; (3) breach of these
                    Legal Terms; (4) any breach of your representations and
                    warranties set forth in these Legal Terms; (5) your
                    violation of the rights of a third party, including but not
                    limited to intellectual property rights; or (6) any overt
                    harmful act toward any other user of the Services with whom
                    you connected via the Services. Notwithstanding the
                    foregoing, we reserve the right, at your expense, to assume
                    the exclusive defense and control of any matter for which
                    you are required to indemnify us, and you agree to
                    cooperate, at your expense, with our defense of such claims.
                    We will use reasonable efforts to notify you of any such
                    claim, action, or proceeding which is subject to this
                    indemnification upon becoming aware of it.
                    <br />
                    &nbsp;
                    <br />
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* section 27 */}

        {/* section 28 */}
        <Box
          ref={explanation25Ref}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "900px",
              marginTop: "80px",
              gap: "30px",
              opacity: "0px",
              "@media (max-width : 991px)": {
                marginTop: "40px",
                minWidth: "350px",
                padding: "0px 4.4% 0px 4.4%",
                marginBottom: "487px",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: "600px",
                  lineHeight: "30px",
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.9)",
                  "@media (max-width : 991px)": {
                    fontSize: "16px",
                  },
                }}
              >
                25. USER DATA
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                marginTop: "30px",
                "@media (max-width : 991px)": {
                  marginTop: "24px",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  paddingLeft: 2,
                }}
              >
                <Box>
                  <Typography sx={commonStyles}>
                    We will maintain certain data that you transmit to the
                    Services for the purpose of managing the performance of the
                    Services, as well as data relating to your use of the
                    Services. Although we perform regular routine backups of
                    data, you are solely responsible for all data that you
                    transmit or that relates to any activity you have undertaken
                    using the Services. You agree that we shall have no
                    liability to you for any loss or corruption of any such
                    data, and you hereby waive any right of action against us
                    arising from any such loss or corruption of such data.
                    <br />
                    &nbsp;
                    <br />
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* section 28 */}

        {/* section 29 */}
        <Box
          ref={explanation26Ref}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "900px",
              marginTop: "80px",
              gap: "30px",
              opacity: "0px",
              "@media (max-width : 991px)": {
                marginTop: "40px",
                minWidth: "350px",
                padding: "0px 4.4% 0px 4.4%",
                marginBottom: "487px",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: "600px",
                  lineHeight: "30px",
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.9)",
                  "@media (max-width : 991px)": {
                    fontSize: "16px",
                  },
                }}
              >
                26. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                marginTop: "30px",
                "@media (max-width : 991px)": {
                  marginTop: "24px",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  paddingLeft: 2,
                }}
              >
                <Box>
                  <Typography sx={commonStyles}>
                    Visiting the Services, sending us emails, and completing
                    online forms constitute electronic communications. You
                    consent to receive electronic communications, and you agree
                    that all agreements, notices, disclosures, and other
                    communications we provide to you electronically, via email
                    and on the Services, satisfy any legal requirement that such
                    communication be in writing. YOU HEREBY AGREE TO THE USE OF
                    ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS,
                    AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS
                    OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE
                    SERVICES. You hereby waive any rights or requirements under
                    any statutes, regulations, rules, ordinances, or other laws
                    in any jurisdiction which require an original signature or
                    delivery or retention of non-electronic records, or to
                    payments or the granting of credits by any means other than
                    electronic means.
                    <br />
                    &nbsp;
                    <br />
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* section 29 */}

        {/* section 30 */}
        <Box
          ref={explanation27Ref}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "900px",
              marginTop: "80px",
              gap: "30px",
              opacity: "0px",
              "@media (max-width : 991px)": {
                marginTop: "40px",
                minWidth: "350px",
                padding: "0px 4.4% 0px 4.4%",
                marginBottom: "487px",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: "600px",
                  lineHeight: "30px",
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.9)",
                  "@media (max-width : 991px)": {
                    fontSize: "16px",
                  },
                }}
              >
                27. Alberta USERS AND RESIDENTS
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                marginTop: "30px",
                "@media (max-width : 991px)": {
                  marginTop: "24px",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  paddingLeft: 2,
                }}
              >
                <Box>
                  <Typography sx={commonStyles}>
                    If any complaint with us is not satisfactorily resolved, you
                    can contact the Complaint Assistance Unit of the Division of
                    Consumer Services of the Alberta Department of Consumer
                    Affairs in writing.
                    <br />
                    &nbsp;
                    <br />
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* section 30 */}

        {/* section 31 */}
        <Box
          ref={explanation28Ref}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "900px",
              marginTop: "80px",
              gap: "30px",
              opacity: "0px",
              "@media (max-width : 991px)": {
                marginTop: "40px",
                minWidth: "350px",
                padding: "0px 4.4% 0px 4.4%",
                marginBottom: "487px",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: "600px",
                  lineHeight: "30px",
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.9)",
                  "@media (max-width : 991px)": {
                    fontSize: "16px",
                  },
                }}
              >
                28. MISCELLANEOUS
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                marginTop: "30px",
                "@media (max-width : 991px)": {
                  marginTop: "24px",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  paddingLeft: 2,
                }}
              >
                <Box>
                  <Typography sx={commonStyles}>
                    These Legal Terms and any policies or operating rules posted
                    by us on the Services or in respect to the Services
                    constitute the entire agreement and understanding between
                    you and us. Our failure to exercise or enforce any right or
                    provision of these Legal Terms shall not operate as a waiver
                    of such right or provision. These Legal Terms operate to the
                    fullest extent permissible by law. We may assign any or all
                    of our rights and obligations to others at any time. We
                    shall not be responsible or liable for any loss, damage,
                    delay, or failure to act caused by any cause beyond our
                    reasonable control. If any provision or part of a provision
                    of these Legal Terms is determined to be unlawful, void, or
                    unenforceable, that provision or part of the provision is
                    deemed severable from these Legal Terms and does not affect
                    the validity and enforceability of any remaining provisions.
                    There is no joint venture, partnership, employment or agency
                    relationship created between you and us as a result of these
                    Legal Terms or use of the Services. You agree that these
                    Legal Terms will not be construed against us by virtue of
                    having drafted them. You hereby waive any and all defenses
                    you may have based on the electronic form of these Legal
                    Terms and the lack of signing by the parties hereto to
                    execute these Legal Terms.
                    <br />
                    &nbsp;
                    <br />
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* section 31 */}

        {/* section 32 */}
        <Box
          ref={explanation29Ref}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "900px",
              marginTop: "80px",
              gap: "30px",
              opacity: "0px",
              "@media (max-width : 991px)": {
                marginTop: "40px",
                minWidth: "350px",
                padding: "0px 4.4% 0px 4.4%",
                marginBottom: "487px",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: "600px",
                  lineHeight: "30px",
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.9)",
                  "@media (max-width : 991px)": {
                    fontSize: "16px",
                  },
                }}
              >
                29. RIGHT TO TERMINATE ACCOUNT
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                marginTop: "30px",
                "@media (max-width : 991px)": {
                  marginTop: "24px",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  paddingLeft: 2,
                }}
              >
                <Box>
                  <Typography sx={commonStyles}>
                    We reserve the right to terminate your account without
                    notice and without specific cause.
                    <br />
                    &nbsp;
                    <br />
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* section 32 */}

        {/* section 33 */}
        <Box
          ref={explanation30Ref}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "900px",
              marginTop: "80px",
              gap: "30px",
              opacity: "0px",
              "@media (max-width : 991px)": {
                marginTop: "40px",
                minWidth: "350px",
                padding: "0px 4.4% 0px 4.4%",
                marginBottom: "487px",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: "600px",
                  lineHeight: "30px",
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.9)",
                  "@media (max-width : 991px)": {
                    fontSize: "16px",
                  },
                }}
              >
                30. HOLD HARMLESS
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                marginTop: "30px",
                "@media (max-width : 991px)": {
                  marginTop: "24px",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  paddingLeft: 2,
                }}
              >
                <Box>
                  <Typography sx={commonStyles}>
                    ModalX agents are programmed to conduct sales-related
                    activities and communicate with individuals on your behalf.
                    While we strive to provide accurate and appropriate
                    responses, we cannot guarantee the accuracy, reliability, or
                    completeness of the information provided by ModalX' outbound
                    emails or messages. Furthermore, our platform is a machine
                    learning-based system and does not have the ability to form
                    independent beliefs, thoughts, or opinions. ModalX may
                    hallucinate and say things that are untrue or undesirable.
                    By using Artisan, you acknowledge and agree that: We are not
                    liable for any statements, representations, or claims made
                    by ModalX in outbound emails or messages. Any information
                    provided by ModalX's should be independently verified for
                    accuracy. Any language or expressions used by ModalX are
                    generated based on patterns in the data it was trained on
                    and do not reflect the views or opinions of our company. It
                    is your responsibility to review and approve the content
                    generated by ModalX before it is sent to any recipient. You
                    are solely responsible for the consequences of ModalX's
                    communications and any actions taken as a result of those
                    communications. If you turn on, or do not turn off, our
                    autopilot feature, you will be responsible for everything
                    your ModalX send. You agree to indemnify and hold us
                    harmless from any claims, damages, or losses arising out of
                    or in connection with ModalX's communications, including but
                    not limited to claims related to defamation, intellectual
                    property infringement, or any other legal rights. By using
                    ModalX's, you expressly acknowledge and accept these
                    limitations. We recommend that you carefully review all
                    communications generated by ModalX to ensure they align with
                    your intended message and comply with applicable laws and
                    regulations.
                    <br />
                    &nbsp;
                    <br />
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* section 33 */}

        {/* section 34 */}
        <Box
          ref={explanation31Ref}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "900px",
              marginTop: "80px",
              gap: "30px",
              opacity: "0px",
              "@media (max-width : 991px)": {
                marginTop: "40px",
                minWidth: "350px",
                padding: "0px 4.4% 0px 4.4%",
                marginBottom: "487px",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: "600px",
                  lineHeight: "30px",
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.9)",
                  "@media (max-width : 991px)": {
                    fontSize: "16px",
                  },
                }}
              >
                31. CONTACT US
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                marginTop: "30px",
                "@media (max-width : 991px)": {
                  marginTop: "24px",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  paddingLeft: 2,
                }}
              >
                <Box>
                  <Typography sx={commonStyles}>
                    In order to resolve a complaint regarding the Services or to
                    receive further information regarding use of the Services,
                    please contact us at:
                    <br />
                    &nbsp;
                    <br />
                    ModalX'sAI, Inc.
                    <br />
                    Suite 2700, Brookfield Place
                    <br />
                    225 - 6th Ave SW
                    <br />
                    Calgary AB T2P 1N2
                    <br />
                    support@ModalX's.ai
                    <br />
                    &nbsp;
                    <br />
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* section 34 */}
        <Box>
          <RequestDemoNearFooter />
        </Box>
      </PageLayout>
    </>
  );
}

export default TermsOfService;
