// import React, { useState,useEffect } from 'react';
// import { Button, Modal, Container, Row, Col, Card } from 'react-bootstrap';
// import { useWorkspace } from '../../../../contexts/WorkspaceContext';
// import sourceUrl from "../../../../api/Source.api";
// import { avatarVoiceLanguages } from "../../../../constants/avatarVoiceLanguages";

// // const avatarVoiceLanguages = [
// //   "Chinese",
// //   "Korean (Korea)",
// //   "Dutch (Netherlands)",
// //   "Turkish (Turkey)",
// //   "Swedish (Sweden)",
// //   "Indonesian (Indonesia)",
// //   "Filipino (Philippines)",
// //   "Japanese (Japan)",
// //   "Ukrainian (Ukraine)",
// //   "Greek (Greece)",
// //   "Czech (Czechia)",
// //   "Finnish (Finland)",
// //   "Romanian (Romania)",
// //   "Russian (Russia)",
// //   "Danish (Denmark)",
// //   "Bulgarian (Bulgaria)",
// //   "Malay (Malaysia)",
// //   "Slovak (Slovakia)",
// //   "Croatian (Croatia)",
// //   "Arabic",
// //   "Tamil",
// //   "English",
// //   "Polish (Poland)",
// //   "German (Germany)",
// //   "Spanish (Spain)",
// //   "French (France)",
// //   "Italian (Italy)",
// //   "Hindi (India)",
// //   "Portuguese (Portugal)",
// //   "Afrikaans (South Africa)",
// //   "Amharic (Ethiopia)",
// //   "Arabic (United Arab Emirates)",
// //   "Arabic (Bahrain)",
// //   "Arabic (Algeria)",
// //   "Arabic (Egypt)",
// //   "Arabic (Iraq)",
// //   "Arabic (Jordan)",
// //   "Arabic (Kuwait)",
// //   "Arabic (Lebanon)",
// //   "Arabic (Libya)",
// //   "Arabic (Morocco)",
// //   "Arabic (Oman)",
// //   "Arabic (Qatar)",
// //   "Arabic (Saudi Arabia)",
// //   "Arabic (Syria)",
// //   "Arabic (Tunisia)",
// //   "Arabic (Yemen)",
// //   "Azerbaijani (Latin, Azerbaijan)",
// //   "Bangla (Bangladesh)",
// //   "Bengali (India)",
// //   "Bosnian (Bosnia and Herzegovina)",
// //   "Catalan (Spain)",
// //   "Welsh (United Kingdom)",
// //   "German (Austria)",
// //   "German (Switzerland)",
// //   "English (Australia)",
// //   "English (Canada)",
// //   "English (United Kingdom)",
// //   "English (Hong Kong SAR)",
// //   "English (Ireland)",
// //   "English (India)",
// //   "English (Kenya)",
// //   "English (Nigeria)",
// //   "English (New Zealand)",
// //   "English (Philippines)",
// //   "English (Singapore)",
// //   "English (Tanzania)",
// //   "English (United States)",
// //   "English (South Africa)",
// //   "Spanish (Argentina)",
// //   "Spanish (Bolivia)",
// //   "Spanish (Chile)",
// //   "Spanish (Colombia)",
// //   "Spanish (Costa Rica)",
// //   "Spanish (Cuba)",
// //   "Spanish (Dominican Republic)",
// //   "Spanish (Ecuador)",
// //   "Spanish (Equatorial Guinea)",
// //   "Spanish (Guatemala)",
// //   "Spanish (Honduras)",
// //   "Spanish (Mexico)",
// //   "Spanish (Nicaragua)",
// //   "Spanish (Panama)",
// //   "Spanish (Peru)",
// //   "Spanish (Puerto Rico)",
// //   "Spanish (Paraguay)",
// //   "Spanish (El Salvador)",
// //   "Spanish (United States)",
// //   "Spanish (Uruguay)",
// //   "Spanish (Venezuela)",
// //   "Estonian (Estonia)",
// //   "Basque",
// //   "Persian (Iran)",
// //   "French (Belgium)",
// //   "French (Canada)",
// //   "French (Switzerland)",
// //   "Irish (Ireland)",
// //   "Galician",
// //   "Gujarati (India)",
// //   "Hebrew (Israel)",
// //   "Hungarian (Hungary)",
// //   "Armenian (Armenia)",
// //   "Icelandic (Iceland)",
// //   "Javanese (Latin, Indonesia)",
// //   "Georgian (Georgia)",
// //   "Kazakh (Kazakhstan)",
// //   "Khmer (Cambodia)",
// //   "Kannada (India)",
// //   "Lao (Laos)",
// //   "Lithuanian (Lithuania)",
// //   "Latvian (Latvia)",
// //   "Macedonian (North Macedonia)",
// //   "Malayalam (India)",
// //   "Mongolian (Mongolia)",
// //   "Marathi (India)",
// //   "Maltese (Malta)",
// //   "Burmese (Myanmar)",
// //   "Norwegian Bokmål (Norway)",
// //   "Nepali (Nepal)",
// //   "Dutch (Belgium)",
// //   "Pashto (Afghanistan)",
// //   "Portuguese (Brazil)",
// //   "Sinhala (Sri Lanka)",
// //   "Slovenian (Slovenia)",
// //   "Somali (Somalia)",
// //   "Albanian (Albania)",
// //   "Serbian (Latin, Serbia)",
// //   "Serbian (Cyrillic, Serbia)",
// //   "Sundanese (Indonesia)",
// //   "Swahili (Kenya)",
// //   "Swahili (Tanzania)",
// //   "Tamil (India)",
// //   "Tamil (Sri Lanka)",
// //   "Tamil (Malaysia)",
// //   "Tamil (Singapore)",
// //   "Telugu (India)",
// //   "Thai (Thailand)",
// //   "Urdu (India)",
// //   "Urdu (Pakistan)",
// //   "Uzbek (Latin, Uzbekistan)",
// //   "Vietnamese (Vietnam)",
// //   "Chinese (Wu, Simplified)",
// //   "Chinese (Cantonese, Simplified)",
// //   "Chinese (Mandarin, Simplified)",
// //   "Chinese (Zhongyuan Mandarin Henan, Simplified)",
// //   "Chinese (Northeastern Mandarin, Simplified)",
// //   "Chinese (Zhongyuan Mandarin Shaanxi, Simplified)",
// //   "Chinese (Jilu Mandarin, Simplified)",
// //   "Chinese (Southwestern Mandarin, Simplified)",
// //   "Chinese (Cantonese, Traditional)",
// //   "Chinese (Taiwanese Mandarin, Traditional)",
// //   "Zulu (South Africa)",
// //   "English (US)",
// //   "English (IN)",
// //   "English (IE)",
// //   "English (GB)",
// //   "English (AU)",
// //   "English (ZA)",
// //   "English (FI)",
// //   "English (FR)",
// //   "English (IT)",
// //   "English (MX)",
// //   "English (NZ)"
// // ];

// const VoiceSettings = () => {
//   const [openVoiceModel, setOpenVoiceModel] = useState(false);
//   const [openVoiceDetailModal, setOpenVoiceDetailModal] = useState(false);
//   const [selectedLanguage, setSelectedLanguage] = useState(null);
//   const [languages, setLanguages] = useState([]);
//   const [voices, setVoices] = useState([]);
//   const { threadSettings, setThreadSettings,videoVoice,setVideoVoice,setVideoAvatarLanguage } = useWorkspace();
//   const token = localStorage.getItem('token')
  
//   const handleVoiceClick = async () => {
//     try {
//       const response = await fetch(`${sourceUrl}/video/avatarVoice`,{
//         headers:{
//           "Authorization":`Bearer ${token}`
//         }
//       });
      
//       if (!response.ok) {
//         throw new Error("Failed to fetch voices");
//       }
//       const json = await response.json();
      
//       const voiceData = json.data.data;
//       const uniqueLanguages = [...new Set(voiceData.map(voice => voice.language))];
//       // setLanguages(uniqueLanguages);
      
//       setLanguages(avatarVoiceLanguages);
//       setVoices(voiceData);
//       setOpenVoiceModel(true);
//     } catch (error) {
//       console.error("Error fetching voices:", error.message);
//     }
//   };

//   // console.log("ppppppppppppppppppppppqqqqqqqqqqqqqqqqq",voices)
  
//   const handleLanguageSelect = (language) => {
//     setSelectedLanguage(language);
//     setVideoAvatarLanguage(language);
//     setOpenVoiceModel(false);
//     setOpenVoiceDetailModal(true);
//   };

//   const handleVoiceSelect = (voice) => {
//     const languageDetail = voice.languages.find(lang => lang.language === selectedLanguage);
//     setVideoVoice(voice.id);
//     setOpenVoiceDetailModal(false);
//     setThreadSettings((prevSettings) => ({
//       ...prevSettings,
//       synthesiaVoice: {
//         ...voice,
//         language: languageDetail.language,
//         accent: languageDetail.accent,
//       },
//     }));
//   };

//   const VoiceModal = () => (
//     <Modal
//       show={openVoiceModel}
//       onHide={() => setOpenVoiceModel(false)}
//       size="lg"
//       centered
//     >
//       <Modal.Header closeButton>
//         <Modal.Title>Availabe Languages</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <Container fluid>
//           <Row xs={3}>
//             {languages.map((language, i) => (
//               <Col key={i}>
//                 <Card
//                   className="mb-3"
//                   onClick={() => handleLanguageSelect(language)}
//                   style={{ cursor: "pointer" }}
//                 >
//                   <Card.Body>
//                     <Card.Text className="my-1 py-0">
//                       {language}
//                     </Card.Text>
//                   </Card.Body>
//                 </Card>
//               </Col>
//             ))}
//           </Row>
//         </Container>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button variant="secondary" onClick={() => setOpenVoiceModel(false)}>
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );

//   const VoiceDetailModal = () => {
//     const filteredVoices = voices.filter(voice =>
//       voice.languages.some(lang => lang.language === selectedLanguage)
//     );
//     return (
//       <Modal
//         show={openVoiceDetailModal}
//         onHide={() => setOpenVoiceDetailModal(false)}
//         size="lg"
//         centered
//       >
//         <Modal.Header closeButton>
//           <Modal.Title>Voice Details</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Container fluid>
//             <Row xs={1}>
//               {filteredVoices.map((voice, i) => (
//                 <Col key={i}>
//                   <Card
//                     className="mb-3"
//                     onClick={() => handleVoiceSelect(voice)}
//                     style={{ cursor: "pointer" }}
//                   >
//                     <Card.Body>
//                     <Card.Text className="my-1 py-0">
//                         Name: {voice.name}
//                       </Card.Text>
//                       <Card.Text className="my-1 py-0">
//                         Gender: {voice.gender}
//                       </Card.Text>
//                       <Card.Text className="my-1 py-0">
//                       Description: {voice.description}
//                       </Card.Text>
                      
//                     </Card.Body>
//                   </Card>
//                 </Col>
//               ))}
//             </Row>
//           </Container>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={() => setOpenVoiceDetailModal(false)}>
//             Close
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     );
//   };

//   return (
//     <>
//       <Button
//         variant="outline-secondary"
//         className="mb-2 d-flex align-items-center justify-content-start"
//         onClick={handleVoiceClick}
//       >
//         <i className="bi bi-mic pe-3" />
//         Voice{" "}
//         {threadSettings && threadSettings.synthesiaVoice
//           ? `(${threadSettings.synthesiaVoice.language}, ${
//               threadSettings.synthesiaVoice.gender === "male" ? "M" : "F"
//             })`
//           : ""}
//       </Button>
//       <VoiceModal />
//       <VoiceDetailModal />
//     </>
//   );
// };

// export default VoiceSettings;


import React, { useState, useEffect } from 'react';
import { Button, Container, Grid, Card, Typography, Box, TextField, InputAdornment, Input } from '@mui/material';
import { useWorkspace } from '../../../../contexts/WorkspaceContext';
import sourceUrl from '../../../../api/Source.api';
import { avatarVoiceLanguages } from "../../../../constants/avatarVoiceLanguages";
import { defaultSettings_button_bg_color, hoveredSettings_button_bg_color, selected_SettingsButton_bg_color } from '../../../../constants/colors';
import SearchIcon from '@mui/icons-material/Search';

const DIDVoiceSettings = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedVoice, setSelectedVoice] = useState(null);
  const [inputBoxValue, setInputBoxValue] = useState("");
  const [debouncedValue, setDebouncedValue] = useState("");
  const [filterLanguages, setFilterLanguages] = useState(null)
  // const [languages, setLanguages] = useState([]);
  // const [voices, setVoices] = useState([]);
  const { setThreadSettings, setVideoVoice, setVideoAvatarLanguage, languages,setLanguages,voices, setVoices, voiceloading, setVoiceLoading, showVideoSettings   } = useWorkspace();
  const token = localStorage.getItem('token');
  const theme = localStorage.getItem('theme');

  useEffect(() => {
    if(!voiceloading && (languages?.length === 0 || voices?.length === 0 ) ){
      setVoiceLoading(true)
      fetchVoices();
    }
  }, []);

  const fetchVoices = async () => {
    try {
      const response = await fetch(`${sourceUrl}/video/avatarVoice`, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error("Failed to fetch voices");
      }
      const json = await response.json();
      const voiceData = json.data.data;
      setLanguages(avatarVoiceLanguages);
      setVoices(voiceData);
    } catch (error) {
      console.error("Error fetching voices:", error.message);
    }
    setVoiceLoading(false)
  };

  const customTextFieldStyles = {
    "& .MuiInputLabel-root": {
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
      letterSpacing: "0.5px",
      color: "rgba(255, 255, 255, 0.80)",
      "&.Mui-focused": {
        color: "rgba(255, 255, 255, 0.80) !important",
      },
    },
    "& .MuiInputBase-input": {
      color: "#FFFFFF",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      backgroundColor: "#232746", 
      "& fieldset": {
        borderColor: "rgba(255, 255, 255, 0.80)",
      },
      "&:hover fieldset": {
        borderColor: "rgba(255, 255, 255, 0.80)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(255, 255, 255, 0.80) !important",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent) !important",
    },
  };

  const handleLanguageSelect = (language) => {
    setSelectedLanguage(language);
    setVideoAvatarLanguage(language);
    setSelectedVoice(null); // Reset selected voice when changing language
  };

  const handleVoiceSelect = (voice) => {
    const languageDetail = voice.languages.find(lang => lang.language === selectedLanguage);
    setVideoVoice(voice.id);
    setSelectedVoice(voice);
    setThreadSettings((prevSettings) => ({
      ...prevSettings,
      synthesiaVoice: {
        ...voice,
        language: languageDetail.language,
        accent: languageDetail.accent,
      },
    }));
  };

  // console.log(languages, "===================selectedLanguage=================")
      // Function to simulate filtering logic
      const handleFilter = (value) => {
        const filterValue = languages?.filter((item, i)=> item?.toLowerCase().includes(value.toLowerCase()))
        setFilterLanguages(filterValue)
  
      };
          // Debounce logic using useEffect
          useEffect(() => {
            if(showVideoSettings){
              const handler = setTimeout(() => {
                setDebouncedValue(inputBoxValue);
                handleFilter(inputBoxValue);
              }, []);
              return () => {
                clearTimeout(handler);
              };
            }
        
          }, [inputBoxValue]);


          const filterDataUI = (filterdata) =>{
            return (
              <Grid container spacing={2}>
              {filterdata?.map((language, i) => (
                <Grid item key={i} xs="auto">
                  <Card
                    onClick={() => handleLanguageSelect(language)}
                    sx={{
                      cursor: 'pointer',
                      height: '32px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '6px 12px',
                      boxSizing: 'border-box',
                      marginBottom: 1,
                      marginRight: 1,
                      bgcolor: selectedLanguage === language
                        ? selected_SettingsButton_bg_color
                        : theme === 'dark'
                          ? defaultSettings_button_bg_color
                          : '',
                      border: selectedLanguage === language ? '2px solid #CAC4D0' : '',
                      "&:hover": {
                                    backgroundColor: "#373E6D", 
                                    border:" 1px solid #373E6D"
                                  },
                    }}
                  >
                    <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: '600', color: theme === 'dark' ? '#CAC4D0' : '' }}>
                      {language}
                    </Typography>
                  </Card>
                </Grid>
              ))}
            </Grid>
            )
          }

  const renderVoiceDetails = () => {
    const filteredVoices = voices?.filter(voice =>
      voice.languages.some(lang => lang.language === selectedLanguage)
    );

    return (
      <Box>
        <Typography variant="h6" component="p" sx={{fontSize:'16px',fontWeight:'500', paddingTop:"20px"}}>
          Voice Details
        </Typography>
        <Grid container spacing={'8px'} >
          {filteredVoices?.map((voice, i) => (
            <Grid item key={i} xs="auto">
              <Card
                onClick={() => handleVoiceSelect(voice)}
                sx={{
                  width: '215px',
                  height: '88px',
                  cursor: 'pointer',
                  padding: '6px 12px',
                  marginBottom: 1,
                  marginRight: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  bgcolor: theme === 'dark' ? defaultSettings_button_bg_color : '',
                  border: selectedVoice?.id === voice.id ? `2px solid #CAC4D0` : '',
                  "&:hover": {
                                    backgroundColor: "#373E6D", 
                                    border:" 1px solid #373E6D"
                                  },
                  color:theme === 'dark'? '#CAC4D0' : ''
                }}
                
              >
                <Typography variant="body1" style={{fontSize:'14px'}}>Name: {voice.name}</Typography>
                <Typography variant="body1" style={{fontSize:'14px'}}>Gender: {voice.gender}</Typography>
                <Typography variant="body1" style={{fontSize:'14px'}}>Description: {voice.description}</Typography>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Button onClick={() => setSelectedLanguage(null)} variant="contained" sx={{ mt: 2 }}>
          Back to Languages
        </Button>
      </Box>
    );
  };

  const renderLanguageSelection = () => (
    <>
        {
          voiceloading ?  
          <Grid item xs={12} sx={{paddingTop:"20px"}}>
          <Typography>Loading Language</Typography>
        </Grid>
          :
        <div style={{paddingTop:"20px"}}>
      <Typography variant="h6" component="p" style={{ fontSize: '16px', fontWeight: '500' }}>
        Select Language
      </Typography>
      
      <Box sx={{ marginBottom: "20px", marginTop: "20px" }}>
      <Input
        type="text"
        disableUnderline={true}
        placeholder="Search language"
        variant="outlined"
        sx={customTextFieldStyles}
        onChange={(e)=>{setInputBoxValue(e.target.value)}}
        value={inputBoxValue}
        style={{
          width: "100%",
          maxWidth: "180px",
          height: "48px",
          color: "#FFFFFF",
          backgroundColor: "#2F355F",
          borderRadius: "8px",
          padding:"0px 12px",
          boxShadow: "0px 0px 80px 0px rgba(0, 0, 0, 0.40)",
          '&:focus': {
          outline: 'none', 
         },
        }}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon style={{ color: "rgba(255, 255, 255, 0.80)" }}  />
          </InputAdornment>
        }
      />
    </Box>

        {
          inputBoxValue == "" ? 
          <Grid container spacing={2}>
          {languages?.map((language, i) => (
            <Grid item key={i} xs="auto">
              <Card
                onClick={() => handleLanguageSelect(language)}
                sx={{
                  cursor: 'pointer',
                  height: '32px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '6px 12px',
                  boxSizing: 'border-box',
                  marginBottom: 1,
                  marginRight: 1,
                  bgcolor: selectedLanguage === language
                    ? selected_SettingsButton_bg_color
                    : theme === 'dark'
                      ? defaultSettings_button_bg_color
                      : '',
                  border: selectedLanguage === language ? '2px solid #CAC4D0' : '',
                  '&:hover': {
                    bgcolor: hoveredSettings_button_bg_color,
                  }
                }}
              >
                <Typography variant="body1" sx={{ fontSize: '14px', fontWeight: '600', color: theme === 'dark' ? '#CAC4D0' : '' }}>
                  {language}
                </Typography>
              </Card>
            </Grid>
          ))}
        </Grid>
          : filterDataUI(filterLanguages)
        }

       
       </div>
  }</>
  );

  return (
    <div>
      {selectedLanguage ? renderVoiceDetails() : renderLanguageSelection()}
    </div>
  );
};



export default DIDVoiceSettings;









// import React, { useState } from "react";
// import { Modal, Card, Button, Container, Row, Col } from "react-bootstrap";
// import { useWorkspace } from "../../../../contexts/WorkspaceContext";

// const VoiceSettings = () => {
//   const [openVoiceModel, setOpenVoiceModel] = useState(false);
//   const [voices, setVoices] = useState([]);
//   const { threadSettings, setThreadSettings } = useWorkspace();
//   const handleVoiceClick = async () => {
//     try {
//       const response = await fetch("https://www.modalx.ai/video/avatarVoice");
//       if (!response.ok) {
//         throw new Error("Failed to fetch voices");
//       }
//       const json = await response.json();
//       setVoices(json["data"]["data"]);
//       setOpenVoiceModel(true);
//     } catch (error) {
//       console.error("Error fetching voices:", error.message);
//       // Handle error (e.g., show error message)
//     }
//   };

//   const handleVoiceSelect = (voice) => {
    
//     setOpenVoiceModel(false);
//     // Store selected voice setting in threadSettings
//     setThreadSettings((prevSettings) => ({
//       ...prevSettings,
//       synthesiaVoice: voice, // Store the entire voice object
//     }));
//   };

//   const VoiceModal = () => (
//     <Modal
//       show={openVoiceModel}
//       onHide={() => setOpenVoiceModel(false)}
//       size="lg"
//       dialogClassName="modal-90w"
//       centered={false}
//     >
//       <Modal.Header closeButton>
//         <Modal.Title>Voice</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <Container fluid>
//           <Row xs={3}>
//             {voices.map((voice, i) => (
//               <Col>
//                 <Card
//                   className="mb-3"
//                   key={voice.id}
//                   onClick={() => handleVoiceSelect(voice)}
//                   style={{ cursor: "pointer" }}
//                 >
//                   <Card.Body>
//                     <Card.Text className="my-1 py-0">
//                       Type: {voice.name}
//                     </Card.Text>
//                     <Card.Text className="my-1 py-0">
//                       Gender: {voice.gender}
//                     </Card.Text>
//                     <Card.Text className="my-1 py-0">
//                       Language: {voice.language}
//                     </Card.Text>
//                   </Card.Body>
//                 </Card>
//               </Col>
//             ))}
//           </Row>
//         </Container>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button variant="secondary" onClick={() => setOpenVoiceModel(false)}>
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
  

//   return (
//     <>
//       <Button
//         variant="outline-secondary"
//         className="mb-2 d-flex align-items-center justify-content-start"
//         onClick={handleVoiceClick}
//       >
//         <i className="bi bi-mic pe-3" />
//         Voice{" "}
//         {threadSettings && threadSettings.synthesiaVoice
//           ? `(${threadSettings.synthesiaVoice.language}, ${
//               threadSettings.synthesiaVoice.gender === "Male" ? "M" : "F"
//             })`
//           : ""}
//       </Button>
//       <VoiceModal />
//     </>
//   );
// };

// export default VoiceSettings;



// import React, { useState } from "react";
// import { Modal, Card, Button, Container, Row, Col } from "react-bootstrap";
// import { useWorkspace } from "../../../../contexts/WorkspaceContext";

// const VoiceSettings = () => {
//   const [openVoiceModel, setOpenVoiceModel] = useState(false);
//   const [voices, setVoices] = useState([]);
//   const { threadSettings, setThreadSettings } = useWorkspace();
//   const handleVoiceClick = async () => {
//     try {
//       const response = await fetch("https://www.modalx.ai/video/avatarVoice");
//       if (!response.ok) {
//         throw new Error("Failed to fetch voices");
//       }
//       const json = await response.json();
//       setVoices(json["data"]["data"]);
//       setOpenVoiceModel(true);
//     } catch (error) {
//       console.error("Error fetching voices:", error.message);
//       // Handle error (e.g., show error message)
//     }
//   };

//   const handleVoiceSelect = (voice) => {
    
//     setOpenVoiceModel(false);
//     // Store selected voice setting in threadSettings
//     setThreadSettings((prevSettings) => ({
//       ...prevSettings,
//       synthesiaVoice: voice, // Store the entire voice object
//     }));
//   };

//   const VoiceModal = () => (
//     <div
//       className={`dropdown-menu ${openVoiceModel ? "show" : ""}`}
//       style={{
//         position: "fixed",
//         top: 0,
//         right: openVoiceModel ? 0 : "-320px",
//         width: "320px",
//         transition: "right 0.3s ease-in-out",
//         zIndex: 1050 // Ensure it appears above other content
//       }}
//     >
//       <Modal
//         show={openVoiceModel}
//         onHide={() => setOpenVoiceModel(false)}
//         dialogClassName="modal-320w"
//         size="lg"
//         centered={false}
//       >
//         <Modal.Header closeButton>
//           <Modal.Title>Voice</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Container fluid>
//             <Row xs={1}>
//               {voices.map((voice, i) => (
//                 <Col key={voice.id}>
//                   <Card
//                     className="mb-3"
//                     onClick={() => handleVoiceSelect(voice)}
//                     style={{ cursor: "pointer" }}
//                   >
//                     <Card.Body>
//                       <Card.Text className="my-1 py-0">
//                         Type: {voice.name}
//                       </Card.Text>
//                       <Card.Text className="my-1 py-0">
//                         Gender: {voice.gender}
//                       </Card.Text>
//                       <Card.Text className="my-1 py-0">
//                         Language: {voice.language}
//                       </Card.Text>
//                     </Card.Body>
//                   </Card>
//                 </Col>
//               ))}
//             </Row>
//           </Container>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={() => setOpenVoiceModel(false)}>
//             Close
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </div>
//   );
  
  
  
  

//   return (
//     <>
//       <Button
//         variant="outline-secondary"
//         className="mb-2 d-flex align-items-center justify-content-start"
//         onClick={handleVoiceClick}
//       >
//         <i className="bi bi-mic pe-3" />
//         Voice{" "}
//         {threadSettings && threadSettings.synthesiaVoice
//           ? `(${threadSettings.synthesiaVoice.language}, ${
//               threadSettings.synthesiaVoice.gender === "Male" ? "M" : "F"
//             })`
//           : ""}
//       </Button>
//       <VoiceModal />
//     </>
//   );
// };

// export default VoiceSettings;




// import React, { useState } from "react";
// import { Button, Container, Dropdown, Row, Col } from "react-bootstrap";
// import { useWorkspace } from "../../../../contexts/WorkspaceContext";

// const VoiceSettings = () => {
//   const [selectedLanguage, setSelectedLanguage] = useState("");
//   const [voices, setVoices] = useState([]);
//   const { threadSettings, setThreadSettings } = useWorkspace();

//   const handleLanguageSelect = (language) => {
//     setSelectedLanguage(language);
//     const languageVoices = voices.filter(
//       (voice) => voice.language === language
//     );
//     setVoices(languageVoices);
//   };

//   const handleVoiceSelect = (voice) => {
//     // Store selected voice setting in threadSettings
//     setThreadSettings((prevSettings) => ({
//       ...prevSettings,
//       synthesiaVoice: voice, // Store the entire voice object
//     }));
//   };

//   const renderVoiceOptions = () => {
//     return voices.map((voice) => (
//       <Col key={voice.id}>
//         <Button
//           className="mb-3"
//           onClick={() => handleVoiceSelect(voice)}
//           style={{ cursor: "pointer" }}
//         >
//           {voice.name}, {voice.gender === "Male" ? "M" : "F"}
//         </Button>
//       </Col>
//     ));
//   };

//   return (
//     <>
//       <Dropdown className="mb-2">
//         <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
//           <i className="bi bi-mic pe-3" />
//           Voice{" "}
//           {threadSettings && threadSettings.synthesiaVoice
//             ? `(${threadSettings.synthesiaVoice.language}, ${
//                 threadSettings.synthesiaVoice.gender === "Male" ? "M" : "F"
//               })`
//             : ""}

//         </Dropdown.Toggle>

//         <Dropdown.Menu>
//           {Array.from(new Set(voices.map((voice) => voice.language))).map(
//             (language, index) => (
//               <Dropdown.Item
//                 key={index}
//                 onClick={() => handleLanguageSelect(language)}
//               >
//                 {language}
//               </Dropdown.Item>
//             )
//           )}
//         </Dropdown.Menu>
//       </Dropdown>

//       <Container fluid>
//         <Row xs={3}>{renderVoiceOptions()}</Row>
//       </Container>
//     </>
//   );
// };

// export default VoiceSettings;


