// import React, { useState } from "react";
// import { Col, Container, Row, Modal, Card } from "react-bootstrap";
// import Button from "react-bootstrap/Button";
// import { useWorkspace } from "../../../../contexts/WorkspaceContext";
// import sourceUrl from "../../../../api/Source.api";

// const AvatarSettings = () => {
//   const [openAvatarModel, setOpenAvatarModel] = useState(false);
//   const [avatars, setAvatars] = useState([]);
//   const { threadSettings, setThreadSettings,videoAvatar,setVideoAvatar } = useWorkspace();
//   const token = localStorage.getItem('token')

//   const handleAvatarClick = async () => {
//     try {
//       const response = await fetch(`${sourceUrl}/video/avatar`,{
//         headers:{
//           "Authorization":`Bearer ${token}`
//         }
//       });
//       if (!response.ok) {
//         throw new Error("Failed to fetch avatars");
//       }
//       const json = await response.json();
//       setAvatars(json["data"]["data"]);
//       setOpenAvatarModel(true);
//     } catch (error) {
//       console.error("Error fetching avatars:", error.message);
//       // Handle error (e.g., show error message)
//     }
//   };

//   const handleAvatarSelect = (avatar) => {
//     setOpenAvatarModel(false);
//     // Store selected avatar setting in threadSettings
//     setVideoAvatar(avatar.id);
//     setThreadSettings((prevSettings) => ({
//       ...prevSettings,
//       synthesiaAvatar: avatar, // Assuming 'name' is the property that should be stored
//     }));
//   };

//   const AvatarModel = () => (
//     <Modal
//       show={openAvatarModel}
//       onHide={() => setOpenAvatarModel(false)}
//       size="lg"
//       centered
//     >
//       <Modal.Header closeButton>
//         <Modal.Title>Avatars</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <Container fluid>
//           {avatars && avatars.length === 0 ? (
//             <p>No data available!</p>
//           ) : (
//             <Row xs={4}>
//               {avatars && avatars.map((avatar) => {
//                 return (
//                   <Col key={avatar.id}>
//                     <Card
//                       className="mb-3"
//                       onClick={() => handleAvatarSelect(avatar)}
//                       style={{ cursor: "pointer",backgroundColor:'#e3b966'}}
//                     >
//                       <Card.Img variant="top" src={avatar.image_url} />
//                       <Card.Body className="d-flex align-items-center justify-content-center">
//                         <Card.Title style={{color:'black'}}>{avatar.name}</Card.Title>
//                       </Card.Body>
//                     </Card>
//                   </Col>
//                 );
//               })}
//             </Row>
//           )}
//         </Container>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button variant="secondary" onClick={() => setOpenAvatarModel(false)}>
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );

//   return (
//     <>
//       <Button
//         variant="outline-secondary"
//         className="mb-2 d-flex align-items-center justify-content-start"
//         onClick={handleAvatarClick}
//       >
//         <i className="bi bi-person-bounding-box pe-3" />
//         Avatar{" "}
//         {threadSettings &&
//         threadSettings.synthesiaAvatar &&
//         threadSettings.synthesiaAvatar.name
//           ? `(${threadSettings.synthesiaAvatar.name})`
//           : ""}
//       </Button>
//       <AvatarModel />
//     </>
//   );
// };

// export default AvatarSettings;

import React, { useState, useEffect } from "react";
import { Grid, Card, CardContent, CardMedia, Typography, CircularProgress, Box, Input, InputAdornment } from "@mui/material";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import sourceUrl from "../../../../api/Source.api";
import { defaultSettings_button_bg_color } from "../../../../constants/colors";
import SearchIcon from '@mui/icons-material/Search';

const AvatarSettings = () => {
  // const [avatars, setAvatars] = useState([]);
  const [selectedAvatar, setSelectedAvatar] = useState(null); // Track selected avatar
  const [inputBoxValue, setInputBoxValue] = useState("");
  const [filterAvatars, setFilterAvatars] = useState(null)
  const [debouncedValue, setDebouncedValue] = useState("");
  const { threadSettings, setThreadSettings, setVideoAvatar, avatars, setAvatars, avatarsloading, setAvatarsLoading, showVideoSettings  } = useWorkspace();
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchAvatars = async () => {
      setAvatarsLoading(true)
      try {
        const response = await fetch(`${sourceUrl}/video/avatar`, {
          headers: { "Authorization": `Bearer ${token}` }
        });
        if (!response.ok) {
          throw new Error("Failed to fetch avatars");
        }
        const json = await response.json();
        setAvatars(json["data"]["data"]);

      } catch (error) {
        console.error("Error fetching avatars:", error.message);
      }
      setAvatarsLoading(false)
    };
    if(avatars?.length === 0) {
      fetchAvatars();
    }
  }, [token]);

  const handleAvatarSelect = (avatar) => {
    setSelectedAvatar(avatar); // Update selected avatar
    setVideoAvatar(avatar.id);
    setThreadSettings((prevSettings) => ({
      ...prevSettings,
      synthesiaAvatar: avatar,
    }));
  };

  const customTextFieldStyles = {
    "& .MuiInputLabel-root": {
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
      letterSpacing: "0.5px",
      color: "rgba(255, 255, 255, 0.80)",
      "&.Mui-focused": {
        color: "rgba(255, 255, 255, 0.80) !important",
      },
    },
    "& .MuiInputBase-input": {
      color: "#FFFFFF",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      backgroundColor: "#232746", 
      "& fieldset": {
        borderColor: "rgba(255, 255, 255, 0.80)",
      },
      "&:hover fieldset": {
        borderColor: "rgba(255, 255, 255, 0.80)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(255, 255, 255, 0.80) !important",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent) !important",
    },
  };

  

  const handleFilter = (value) => {
    const filterValue = avatars?.filter((item, i)=> item?.name?.toLowerCase().includes(value.toLowerCase()))
    setFilterAvatars(filterValue)
  };
      // Debounce logic using useEffect
      useEffect(() => {
        if(showVideoSettings){
          const handler = setTimeout(() => {
            setDebouncedValue(inputBoxValue);
            handleFilter(inputBoxValue);
          }, []);
          return () => {
            clearTimeout(handler);
          };
        }
    
}, [inputBoxValue]);

const renderFilterData = (filterAvatars) =>{
  return (
    filterAvatars?.map((avatar) => (
      <Grid
        item
        key={avatar.id}
        sx={{
          width: '140px',
          height: '174px',
          margin: 1, // Adds a margin around each grid item
          boxSizing: 'border-box', // Ensures padding is included in the width/height
        }}
      >
        <Card
          onClick={() => handleAvatarSelect(avatar)}
          sx={{
            cursor: "pointer",
            backgroundColor: defaultSettings_button_bg_color,
            border: selectedAvatar?.id === avatar.id ? "5px solid orange" : "none", // Apply border if selected
            height: '100%', // Ensure the card takes the full height
            padding:'10px',
          }}
        >
          <CardMedia
            component="img"
            alt={avatar.name}
            height="100" // Adjust the image height within the card
            image={avatar.image_url}
          />
          <CardContent sx={{ padding: '8px', textAlign: 'center'}}>
            <Typography variant="body2" component="div" color='white' fontSize='14px' fontWeight='600'>
              {avatar.name}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    ))
  )
}

  return (
    <Grid>
      {avatarsloading && (
        <Grid item xs={12}>
          <Typography>Loading avatars</Typography>
        </Grid>
      )}
      {avatarsloading === false && (avatars?.length === undefined || avatars?.length === 0 || avatars?.length === null)  ? (
        <Grid item xs={12}>
          <Typography>No data available!</Typography>
        </Grid>
      ) : 
        <>
        <Box sx={{paddingTop:"20px"}}>
        <Typography variant="h6" component="p" style={{ fontSize: '16px', fontWeight: '500' }}>
        Select Avatar
      </Typography>
        <Box sx={{ marginBottom: "20px", marginTop: "20px", display:"flex", flexDirection:"row" }}>
     { avatars.length > 0 && <Input
        type="text"
        disableUnderline={true}
        placeholder="Search Avatar"
        variant="outlined"
        sx={customTextFieldStyles}
        onChange={(e)=>{setInputBoxValue(e.target.value)}}
        value={inputBoxValue}
        style={{
          width: "100%",
          maxWidth: "180px",
          height: "48px",
          color: "#FFFFFF",
          backgroundColor: "#2F355F",
          borderRadius: "8px",
          padding:"0px 12px",
          boxShadow: "0px 0px 80px 0px rgba(0, 0, 0, 0.40)",
          '&:focus': {
          outline: 'none', 
         },
        }}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon style={{ color: "rgba(255, 255, 255, 0.80)" }}  />
          </InputAdornment>
        }
      />}
        </Box>
        <Grid container spacing={2}>
        {
        inputBoxValue == "" ?
        avatars?.map((avatar) => (
          <Grid
            item
            key={avatar.id}
            sx={{
              width: '140px',
              height: '174px',
              margin: 1, // Adds a margin around each grid item
              boxSizing: 'border-box', // Ensures padding is included in the width/height
            }}
          >
            <Card
              onClick={() => handleAvatarSelect(avatar)}
              sx={{
                cursor: "pointer",
                backgroundColor: defaultSettings_button_bg_color,
                border: selectedAvatar?.id === avatar.id ? "5px solid orange" : "none", // Apply border if selected
                height: '100%', // Ensure the card takes the full height
                padding:'10px',
              }}
            >
              <CardMedia
                component="img"
                alt={avatar.name}
                height="100" // Adjust the image height within the card
                image={avatar.image_url}
              />
              <CardContent sx={{ padding: '8px', textAlign: 'center'}}>
                <Typography variant="body2" component="div" color='white' fontSize='14px' fontWeight='600'>
                  {avatar.name}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        )) : renderFilterData(filterAvatars)
        }
      </Grid>
      </Box> </>
      }
    </Grid>
    
  );
  
};

export default AvatarSettings;


