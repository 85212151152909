// import React, { useState, useEffect } from "react";
// import {
//   Modal,
//   Card,
//   Button,
//   Breadcrumb,
//   Row,
//   Col,
//   Image,
// } from "react-bootstrap";
// import { useWorkspace } from "../../../../contexts/WorkspaceContext";
// import sourceUrl from "../../../../api/Source.api";
// const BackgroundSettings = () => {
//   const [openBackgroundModel, setOpenBackgroundModel] = useState(false);
//   const [categories, setCategories] = useState([]);
//   const [backgrounds, setBackgrounds] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState(null);
//   const { threadSettings, setThreadSettings,videoBackground,setVideoBackground } = useWorkspace();
//   const token = localStorage.getItem('token')

//   useEffect(() => {
//     fetchCategories();
//     fetchBackgrounds();
//   }, []);

//   const fetchCategories = async () => {
//     try {
//       const response = await fetch(`${sourceUrl}/video/backgroundCategory`,{
//         headers:{
//           "Authorization":`Bearer ${token}`
//         }
//       });
//       if (!response.ok) {
//         throw new Error("Failed to fetch background categories");
//       }
//       const json = await response.json();
//       setCategories(json.data);
//       setSelectedCategory(json.data[0]);
//     } catch (error) {
//       console.error("Error fetching background categories:", error.message);
//     }
//   };

//   const fetchBackgrounds = async () => {
//     try {
//       const response = await fetch(`${sourceUrl}/video/backgroundImage`,{
//         headers:{
//           "Authorization":`Bearer ${token}`
//         }
//       });
//       if (!response.ok) {
//         throw new Error("Failed to fetch background images");
//       }
//       const json = await response.json();
//       setBackgrounds(json.data.data);
//     } catch (error) {
//       console.error("Error fetching background images:", error.message);
//     }
//   };

//   const handleCategorySelect = (category) => {
//     setSelectedCategory(category);
//   };

//   const handleBackgroundSelect = (background) => {
//     setOpenBackgroundModel(false);
//     setVideoBackground(background.url)
//     // Store selected background setting in threadSettings
//     setThreadSettings((prevSettings) => ({
//       ...prevSettings,
//       synthesiaBackground: background,
//     }));
//   };

//   const renderBreadcrumbs = () => (
//     <Breadcrumb>
//       {categories.map((category, index) => (
//         <Breadcrumb.Item
//           key={index}
//           active={category === selectedCategory}
//           onClick={() => handleCategorySelect(category)}
//         >
//           {category}
//         </Breadcrumb.Item>
//       ))}
//     </Breadcrumb>
//   );

//   const normalizeString = (str) => {
//     return str.trim().toLowerCase().replace(/\s+/g, "-");
//   };

//   const renderBackgrounds = () => {
//     if (!backgrounds || !selectedCategory) return null;
//     const filteredBackgrounds = backgrounds.filter(
//       (background) =>
//         normalizeString(background.category) ===
//         normalizeString(selectedCategory)
//     );

//     return (
//       <Row xs={3}>
//         {filteredBackgrounds.map((background) => (
//           <Col>
//             <Image
//               fluid
//               className="mb-3"
//               key={background.id}
//               onClick={() => handleBackgroundSelect(background)}
//               style={{ cursor: "pointer" }}
//               src={background.url}
//               rounded
//             />
//           </Col>
//         ))}
//       </Row>
//     );
//   };

//   return (
//     <>
//       <Button
//         variant="outline-secondary"
//         className="mb-2 d-flex align-items-center justify-content-start"
//         onClick={() => setOpenBackgroundModel(true)}
//       >
//         <i className="bi bi-image pe-3" />
//         Background{" "}
//         {threadSettings.synthesiaBackground &&
//         threadSettings.synthesiaBackground.category
//           ? `(${threadSettings.synthesiaBackground.category})`
//           : ""}
//       </Button>
//       <Modal
//         show={openBackgroundModel}
//         onHide={() => setOpenBackgroundModel(false)}
//         size="lg"
//         centered
//       >
//         <Modal.Header closeButton>
//           <Modal.Title>Background</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           {renderBreadcrumbs()}
//           {renderBackgrounds()}
//         </Modal.Body>
//         <Modal.Footer>
//           <Button
//             variant="secondary"
//             onClick={() => setOpenBackgroundModel(false)}
//           >
//             Close
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// };

// export default BackgroundSettings;

import React, { useState, useEffect } from "react";
import {
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Typography,
  Box,
} from "@mui/material";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import sourceUrl from "../../../../api/Source.api";
import {
  defaultSettings_button_bg_color,
  hoveredSettings_Nav_bg_dm,
  hoveredSettings_button_bg_color,
  selected_SettingsButton_bg_color,
} from "../../../../constants/colors";

const BackgroundSettings = () => {
  // const [categories, setCategories] = useState([]);
  // const [backgrounds, setBackgrounds] = useState([]);
  // const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedBackground, setSelectedBackground] = useState(null); // Track selected background
  const { threadSettings, setThreadSettings, setVideoBackground,categories,setCategories,backgrounds,setBackgrounds,selectedCategory,setSelectedCategory } =
    useWorkspace();
  const token = localStorage.getItem("token");
  const theme = localStorage.getItem("theme");

  useEffect(() => {
    if(categories?.length ===0 || selectedCategory?.length === 0 || backgrounds?.length ===0){
      fetchCategories();
      fetchBackgrounds();
    }
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await fetch(`${sourceUrl}/video/backgroundCategory`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok)
        throw new Error("Failed to fetch background categories");
      const json = await response.json();
      setCategories(json.data);
      setSelectedCategory(json.data[0]);
    } catch (error) {
      console.error("Error fetching background categories:", error.message);
    }
  };

  const fetchBackgrounds = async () => {
    try {
      const response = await fetch(`${sourceUrl}/video/backgroundImage`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok) throw new Error("Failed to fetch background images");
      const json = await response.json();
      setBackgrounds(json.data.data);
    } catch (error) {
      console.error("Error fetching background images:", error.message);
    }
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  const handleBackgroundSelect = (background) => {
    setSelectedBackground(background); // Update selected background
    setVideoBackground(background.url);
    setThreadSettings((prevSettings) => ({
      ...prevSettings,
      synthesiaBackground: background,
    }));
  };

  const renderBreadcrumbs = () => (
    // <Breadcrumbs aria-label="breadcrumb" separator="">
    //   {categories.map((category, index) => (
    //     <Button
    //       key={index}
    //       onClick={() => handleCategorySelect(category)}
    //       variant="text"
    //       color={category === selectedCategory ? "primary" : "inherit"}
    //       style={{color: category !== selectedCategory? 'white' : ''}}
    //       sx={{
    //         textTransform: 'none',
    //         backgroundColor: category === selectedCategory ? selected_SettingsButton_bg_color : defaultSettings_button_bg_color,
    //         color: category === selectedCategory ? 'white' : 'inherit',
    //         '&:hover': {
    //           backgroundColor: hoveredSettings_button_bg_color,
    //         },
    //         marginBottom: '16px',
    //       }}
    //     >
    //       {category}
    //     </Button>
    //   ))}
    // </Breadcrumbs>
    <div style={{paddingTop:"20px"}}>
      <Typography
        variant="h6"
        gutterBottom
        style={{
          fontSize: "16px",
          fontWeight: "500",
          width: "193px",
          height: "20px",
          marginBottom: "20px",
        }}
      >
        Select Video Background
      </Typography>
      <Grid container spacing={2}>
        {categories.map((category, index) => (
          <Grid item key={index} xs="auto" style={{ gap: "12px" }}>
            <Button
              style={{ margin: "0px", padding: "6px 12px" }}
              variant="outlined"
              onClick={() => handleCategorySelect(category)}
              sx={{
                textTransform: "none",
                backgroundColor:
                  selectedCategory === category ? "#3a3f69" : "#30355f",
                color: theme === "dark" ? "white" : "",
                "&:hover": {
                  backgroundColor: "#373E6D", 
                  border:" 1px solid #373E6D"
                },
                borderColor:
                  selectedCategory === category ? "#cac4d0" : "transparent",
                m: 0.5, // Add margin for spacing
              }}
            >
              {category}
            </Button>
          </Grid>
        ))}
      </Grid>
    </div>
  );

  const renderBackgrounds = () => {
    if (!backgrounds || !selectedCategory) return null;
    const filteredBackgrounds = backgrounds.filter(
      (background) =>
        normalizeString(background.category) ===
        normalizeString(selectedCategory)
    );

    return (
      <Grid container spacing={2}>
        {filteredBackgrounds.map((background) => (
          <Grid item xs={4} key={background.id}>
            <Box
              component="img"
              sx={{
                width: "100%",
                cursor: "pointer",
                border:
                  selectedBackground?.id === background.id
                    ? "5px solid orange"
                    : "none",
              }}
              onClick={() => handleBackgroundSelect(background)}
              src={background.url}
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  const normalizeString = (str) =>
    str.trim().toLowerCase().replace(/\s+/g, "-");

  return (
    <Box
      style={{
        color: theme === "dark" ? "white" : "black",
      }}
    >
      {renderBreadcrumbs()}
      <Box mt={2}>{renderBackgrounds()}</Box>
    </Box>
  );
};

export default BackgroundSettings;
