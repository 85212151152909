import React,{useEffect, useState} from 'react'
import WebsiteListingPage from './WebsiteListingPage'
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import MessageWindowForTrackWebsite from './MessageWindowForTrackWebsite';
import axios from 'axios';
import sourceUrl from '../../../../api/Source.api';
import TrackWebsiteModal from './TrackWebsiteModal';

const WebTrackHome = () => {
    const {iswebTrackClicked,currentWebsiteLinkedId, updateWebList,setUpdateWebList} = useWorkspace();
    const token = localStorage.getItem('token');
    const [currentWebs,setCurrentWebs] = useState([]);
    const [addWebClicked, setAddWebClicked] = useState(false);
    const [refetchClicked,setRefetchClicked] = useState(false);
    // const [updateWebList,setUpdateWebList] = useState(false);

    const handleUpdateList = () => {
      setUpdateWebList(!updateWebList);
    };

    useEffect(() => {
      fetchWebsites();
    }, [addWebClicked,updateWebList]);

    const handleAddWebClicked = () => {
      setAddWebClicked(!addWebClicked);
    };

    const handleRefetchClicked = () => {
      setRefetchClicked(!refetchClicked);
    };
  
    const fetchWebsites = async () => {
      try {
        const res = await axios.get(`${sourceUrl}/websiteTracking/listAll`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        // const sortedData = res.data.data.sort((a, b) => {
        //   if (a.notifications === b.notifications) return 0;
        //   return a.notifications ? -1 : 1;
        // });
        const sortedData = res?.data?.data?.sort((a, b) => {
          // Priority 1: isDefault: true, status: "enabled", notifications: true
          const aPriority1 = a?.isDefault === true && a?.status === "enabled" && a?.notifications === true;
          const bPriority1 = b?.isDefault === true && b?.status === "enabled" && b?.notifications === true;
          if (aPriority1 && !bPriority1) return -1;
          if (!aPriority1 && bPriority1) return 1;
    
          // Priority 2: notifications: true, status: "", isDefault: false
          const aPriority2 = a?.notifications === true && a?.status === "" && a?.isDefault === false;
          const bPriority2 = b?.notifications === true && b?.status === "" && b?.isDefault === false;
          if (aPriority2 && !bPriority2) return -1;
          if (!aPriority2 && bPriority2) return 1;
    
          // Priority 3: isDefault: true, status: "enabled", notifications: false
          const aPriority3 = a?.isDefault === true && a?.status === "enabled" && a?.notifications === false;
          const bPriority3 = b?.isDefault === true && b?.status === "enabled" && b?.notifications === false;
          if (aPriority3 && !bPriority3) return -1;
          if (!aPriority3 && bPriority3) return 1;
    
          // Priority 4: isDefault: false, notifications: false, status: ""
          const aPriority4 = a?.isDefault === false && a?.notifications === false && a?.status === "";
          const bPriority4 = b?.isDefault === false && b?.notifications === false && b?.status === "";
          if (aPriority4 && !bPriority4) return -1;
          if (!aPriority4 && bPriority4) return 1;
    
          return 0; // No changes if they have the same priority
        });

        setCurrentWebs(sortedData);
      } catch (error) {
        console.error(error);
      }
    };

  return (
    <div>
        {iswebTrackClicked && currentWebsiteLinkedId ? <MessageWindowForTrackWebsite website={currentWebs} onHandleRefetch={handleRefetchClicked} refetchClicked={refetchClicked} updateWebList={handleUpdateList} /> : <WebsiteListingPage website={currentWebs} updateWebList={handleUpdateList} />}
        <TrackWebsiteModal
          onAddWebClicked={handleAddWebClicked}
        />
    </div>
  )
}

export default WebTrackHome