// import React, { useState } from 'react';
// import { Modal, Box, Typography, Button, TextField } from '@mui/material';
// import { 
//   button_bg_color_for_New_Project, 
//   uploadFiles_button_default_bg_color, 
//   uploadFiles_button_hovered_bg_color,
//   settingsModal_bg_color_dm
// } from '../../../../constants/colors';
// import sourceUrl from '../../../../api/Source.api';
// import { useWorkspace } from "../../../../contexts/WorkspaceContext";

// const EditUserDetails = ({ open, onClose, firstName: initialFirstName, lastName: initialLastName, linkedIn: initialLinkedIn, role: initialRole, companyWebsite: initialCompanyWebsite }) => {
//   const [firstName, setFirstName] = useState(initialFirstName);
//   const [lastName, setLastName] = useState(initialLastName);
//   const [linkedIn, setLinkedIn] = useState(initialLinkedIn);
//   const [role, setRole] = useState(initialRole);
//   const [companyWebsite, setCompanyWebsite] = useState(initialCompanyWebsite);
//   const {setWaitThread,setSending,setCurrentThread,sendMessage} = useWorkspace();
//   const [validationErrors, setValidationErrors] = useState([]);
//   const hasError = (fieldName) => validationErrors.includes(fieldName);

//   const sendEditMessage = async (prompt) => {
//     setWaitThread(true);
//     setSending(true);
//     try {
//       const latestEntry = { sender: "user", content: prompt };
//       setCurrentThread((prevThread) => [...prevThread, latestEntry]);

//       const entryResponse = await sendMessage(latestEntry,{isValidDetails:false,initialOnboardingStep:true});
//       if (entryResponse) {
//         setCurrentThread((prevThread) => [...prevThread, entryResponse]);
//       } else {
//         setCurrentThread((prevThread) => [
//           ...prevThread, 
//           { content: "Unable to process your query!", sender: "modalX" }
//         ]);
//       }
//     } catch (error) {
//       console.error("Error sending initial message:", error);
//     } finally {
//       setWaitThread(false);
//       setSending(false);
//     }
//   };

//   // const handleSave = async () => {
//   //   const userInfo = {
//   //     firstName,
//   //     lastName,
//   //     linkedinProfile: linkedIn,
//   //     role,
//   //     companyWebsite
//   //   };

//   //   const token = localStorage.getItem('token');

//   //   try {
//   //     const response = await fetch(`${sourceUrl}/userInfo`, {
//   //       method: 'POST',
//   //       headers: {
//   //         'Content-Type': 'text/plain',
//   //         'Authorization': `Bearer ${token}`,
//   //       },
//   //       body: JSON.stringify(userInfo),
//   //     });

//   //     if (response.ok) {
//   //       console.log('User details saved successfully');
//   //       sendEditMessage("No, edit details")
//   //       onClose(); // Close the modal after saving
//   //     } else {
//   //       console.error('Failed to save user details');
//   //     }
//   //   } catch (error) {
//   //     console.error('Error:', error);
//   //   }
//   // };

//   const handleSave = async () => {
//     // Check for empty fields
//     const missingFields = [];
//     if (!firstName?.trim()) missingFields.push('firstName');
//     if (!lastName?.trim()) missingFields.push('lastName');
//     if (!role?.trim()) missingFields.push('role');
//     if (!companyWebsite?.trim()) missingFields.push('companyWebsite');
  
//     // Highlight empty fields and prevent save
//     if (missingFields.length > 0) {
//       setValidationErrors(missingFields);
//       return; // Do not proceed with saving
//     }
  
//     const userInfo = {
//       firstName,
//       lastName,
//       linkedinProfile: linkedIn,
//       role,
//       companyWebsite,
//     };
  
//     const token = localStorage.getItem('token');
  
//     try {
//       const response = await fetch(`${sourceUrl}/userInfo`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'text/plain',
//           'Authorization': `Bearer ${token}`,
//         },
//         body: JSON.stringify(userInfo),
//       });
  
//       if (response.ok) {
//         console.log('User details saved successfully');
//         sendEditMessage("No, edit details");
//         onClose(); // Close the modal after saving
//       } else {
//         console.error('Failed to save user details');
//       }
//     } catch (error) {
//       console.error('Error:', error);
//     }
//   };

//   return (
//     <Modal
//       open={open}
//       onClose={onClose}
//       aria-labelledby="edit-details-title"
//       aria-describedby="edit-details-description"
//       sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
//     >
//       <Box
//         sx={{
//           bgcolor: settingsModal_bg_color_dm,
//           boxShadow: 24,
//           borderRadius: '8px',
//           maxWidth: '720px',
//           position: 'relative',
//           borderBottom: '1px solid #fff',
//         }}
//       >
//         <Box
//           sx={{
//             display: 'flex',
//             justifyContent: 'space-between',
//             alignItems: 'center',
//             position: 'relative',
//             pb: 1,
//             borderBottom: '1px solid #fff',
//             padding: '20px'
//           }}
//         >
//           <Typography id="edit-details-title" variant="h6" component="h2">
//             Edit Personal Details
//           </Typography>
//           <Button
//             onClick={onClose}
//             sx={{
//               position: 'absolute',
//               right: '20px',
//               top: '20px',
//               padding: '8px',
//               minWidth: 'auto',
//               color: '#fff',
//             }}
//           >
//             X
//           </Button>
//         </Box>

//         <Box component="form" sx={{ mt: 2, padding: '20px' }}>
//           <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '40px' }}>
//             <TextField
//               label="First Name*"
//               value={firstName}
//               onChange={(e) => {
//                 setFirstName(e.target.value);
//                 setValidationErrors((prev) => prev.filter((field) => field !== 'firstName'));
//               }}
//               fullWidth
//               margin="normal"
//               variant="outlined"
//               InputLabelProps={{ sx: 
//                 { 
//                 color: 'rgba(255,255,255,0.8)',
//                 '&.Mui-focused': {
//                   color: '#CAC4D0 !important', // Focused placeholder color
//                 },
//                } }}
//               InputProps={{
//                 sx: {
//                   color: '#FFFFFF',
//                   '& .MuiOutlinedInput-notchedOutline': {
//                     borderColor: hasError('firstName') ? '#F2B8B5' : '#FFFFFF', // Error or default border color
//                     borderWidth: hasError('firstName') ?'2px !important':'1px !important', // Default width
//                   },
//                   '&:hover .MuiOutlinedInput-notchedOutline': {
//                     borderColor: hasError('firstName') ? '#F2B8B5' : '#FFFFFF', // Error or hover color
//                     borderWidth: '2px !important', // Hover width
//                   },
//                   '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
//                     borderColor: hasError('firstName') ? '#F2B8B5' : '#FFFFFF', // Focus or error border
//                     borderWidth: '2px !important', // Focus width
//                   },
//                 },
//               }}
//             />
//             <TextField
//               label="Last Name*"
//               value={lastName}
//               onChange={(e) => {
//                 setFirstName(e.target.value);
//                 setValidationErrors((prev) => prev.filter((field) => field !== 'lastName'));
//               }}
//               fullWidth
//               margin="normal"
//               variant="outlined"
//               InputLabelProps={{ sx: 
//                 { 
//                   color: 'rgba(255,255,255,0.8)',
//                  '&.Mui-focused': {
//                    color: '#CAC4D0 !important', // Focused placeholder color
//                  },
//                 }
//               }}
//               InputProps={{
//                 sx: {
//                   color: '#FFFFFF',
//                   '& .MuiOutlinedInput-notchedOutline': {
//                     borderColor: hasError('lastName') ? '#F2B8B5' : '#FFFFFF', // Error or default border color
//                     borderWidth: '1px !important', // Default width
//                   },
//                   '&:hover .MuiOutlinedInput-notchedOutline': {
//                     borderColor: hasError('lastName') ? '#F2B8B5' : '#FFFFFF', // Error or hover color
//                     borderWidth: '2px !important', // Hover width
//                   },
//                   '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
//                     borderColor: hasError('lastName') ? '#F2B8B5' : '#FFFFFF', // Focus or error border
//                     borderWidth: '2px !important', // Focus width
//                   },
//                 },
//               }}
//             />
//           </Box>
//           <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '40px', mt: 2 }}>
//             <TextField
//               label="LinkedIn Profile"
//               value={linkedIn}
//               onChange={(e) => setLinkedIn(e.target.value)}
//               fullWidth
//               margin="normal"
//               variant="outlined"
//               InputLabelProps={{ sx: 
//                 { 
//                   color: 'rgba(255,255,255,0.8)',
//                  '&.Mui-focused': {
//                    color: '#CAC4D0 !important', // Focused placeholder color
//                  },
//                 }
//               }}
//               InputProps={{
//                 sx: {
//                   color: '#FFFFFF',
//                   '& .MuiOutlinedInput-notchedOutline': {
//                     borderColor: '#FFFFFF !important', // Default border
//                     borderWidth: '1px !important', // Default width
//                   },
//                   '&:hover .MuiOutlinedInput-notchedOutline': {
//                     borderColor: '#FFFFFF !important', // Hover border
//                     borderWidth: '2px !important', // Hover width
//                   },
//                   '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
//                     borderColor: '#FFFFFF !important', // Focus border
//                     borderWidth: '2px !important', // Focus width
//                   },
//                 },
//               }}
//             />
//             <TextField
//               label="Role*"
//               value={role}
//               onChange={(e) => {
//                 setFirstName(e.target.value);
//                 setValidationErrors((prev) => prev.filter((field) => field !== 'role'));
//               }}
//               fullWidth
//               margin="normal"
//               variant="outlined"
//               InputLabelProps={{ sx: 
//                 { 
//                   color: 'rgba(255,255,255,0.8)',
//                  '&.Mui-focused': {
//                    color: '#CAC4D0 !important', // Focused placeholder color
//                  },
//                 }
//               }}
//               InputProps={{
//                 sx: {
//                   color: '#FFFFFF',
//                   '& .MuiOutlinedInput-notchedOutline': {
//                     borderColor: hasError('role') ? '#F2B8B5' : '#FFFFFF', // Error or default border color
//                     borderWidth: '1px !important', // Default width
//                   },
//                   '&:hover .MuiOutlinedInput-notchedOutline': {
//                     borderColor: hasError('role') ? '#F2B8B5' : '#FFFFFF', // Error or hover color
//                     borderWidth: '2px !important', // Hover width
//                   },
//                   '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
//                     borderColor: hasError('role') ? '#F2B8B5' : '#FFFFFF', // Focus or error border
//                     borderWidth: '2px !important', // Focus width
//                   },
//                 },
//               }}
//             />
//           </Box>
//           <Box sx={{ display: 'grid', gridTemplateColumns: '1fr', gap: '40px', mt: 2 }}>
//             <TextField
//               label="Company Website*"
//               value={companyWebsite}
//               onChange={(e) => {
//                 setFirstName(e.target.value);
//                 setValidationErrors((prev) => prev.filter((field) => field !== 'companyWebsite'));
//               }}
//               fullWidth
//               margin="normal"
//               variant="outlined"
//               InputLabelProps={{ sx: 
//                 { 
//                   color: 'rgba(255,255,255,0.8)',
//                  '&.Mui-focused': {
//                    color: '#CAC4D0 !important', // Focused placeholder color
//                  },
//                 }
//               }}
//               InputProps={{
//                 sx: {
//                   color: '#FFFFFF',
//                   '& .MuiOutlinedInput-notchedOutline': {
//                     borderColor: hasError('companyWebsite') ? '#F2B8B5' : '#FFFFFF', // Error or default border color
//                     borderWidth: '1px !important', // Default width
//                   },
//                   '&:hover .MuiOutlinedInput-notchedOutline': {
//                     borderColor: hasError('companyWebsite') ? '#F2B8B5' : '#FFFFFF', // Error or hover color
//                     borderWidth: '2px !important', // Hover width
//                   },
//                   '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
//                     borderColor: hasError('companyWebsite') ? '#F2B8B5' : '#FFFFFF', // Focus or error border
//                     borderWidth: '2px !important', // Focus width
//                   },
//                 },
//               }}
//             />
//           </Box>
//         </Box>

//         <Box sx={{
//           mt: 3,
//           display: 'flex',
//           justifyContent: 'flex-end',
//           borderTop: '1px solid rgba(255, 255, 255, 0.2)',
//           padding: '20px'
//         }}>
//           <Button
//             variant="contained"
//             onClick={onClose}
//             style={{ marginRight: '10px', color: '#17182C', fontSize: '16px', fontWeight: '600', fontFamily: 'inter' }}
//             sx={{ textTransform: 'none', bgcolor: uploadFiles_button_default_bg_color,
//               '&:hover': {
//                 bgcolor: uploadFiles_button_hovered_bg_color,
//               }
//             }}
//           >
//             Close
//           </Button>
//           <Button
//             variant="contained"
//             onClick={handleSave}
//             style={{ marginRight: '10px', color: '#17182C', fontSize: '16px', fontWeight: '600', fontFamily: 'inter' }}
//             sx={{ textTransform: 'none', bgcolor: button_bg_color_for_New_Project,
//               '&:hover': {
//                 bgcolor: '#FFD9AA',
//               }
//             }}
//           >
//             Save
//           </Button>
//         </Box>
//       </Box>
//     </Modal>
//   );
// };

// export default EditUserDetails;


import React, { useState } from 'react';
import { Modal, Box, Typography, Button, TextField } from '@mui/material';
import { 
  button_bg_color_for_New_Project, 
  uploadFiles_button_default_bg_color, 
  uploadFiles_button_hovered_bg_color,
  settingsModal_bg_color_dm
} from '../../../../constants/colors';
import sourceUrl from '../../../../api/Source.api';
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import { InvokeOnBoardingStatusAPI } from '../../../../contexts/WorkspaceUtils';

const EditUserDetails = ({ open, onClose, firstName: initialFirstName, lastName: initialLastName, linkedIn: initialLinkedIn, role: initialRole, companyWebsite: initialCompanyWebsite }) => {
  const [firstName, setFirstName] = useState(initialFirstName || '');
  const [lastName, setLastName] = useState(initialLastName || '');
  const [linkedIn, setLinkedIn] = useState('');
  const [role, setRole] = useState('');
  const [companyWebsite, setCompanyWebsite] = useState('');
  const [errors, setErrors] = useState({}); // Track validation errors
  const { setWaitThread, setSending, setCurrentThread, getChallenges,setOnBoardingData } = useWorkspace();
  const email = localStorage.getItem("userEmail");

  const validateFields = () => {
    const newErrors = {};
    if (!firstName.trim()) newErrors.firstName = 'This is a required field';
    if (!lastName.trim()) newErrors.lastName = 'This is a required field';
    if (!role.trim()) newErrors.role = 'This is a required field';
    if (!companyWebsite.trim()) newErrors.companyWebsite = 'This is a required field';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const sendEditMessage = async (prompt) => {
    setWaitThread(true);
    setSending(true);
    try {
      const latestEntry = { sender: "user", content: prompt };
      setCurrentThread((prevThread) => [...prevThread, latestEntry]);

      const entryResponse = await getChallenges(latestEntry,false,{email:email,firstName:firstName,lastName:lastName,linkedinProfile:linkedIn,role:role,companyWebsite:companyWebsite});
      if (entryResponse) {
        setCurrentThread((prevThread) => [...prevThread, entryResponse]);
      } else {
        setCurrentThread((prevThread) => [
          ...prevThread, 
          { content: "Unable to process your query!", sender: "modalX" }
        ]);
      }
      const res = await InvokeOnBoardingStatusAPI();
      setOnBoardingData(res);
    } catch (error) {
      console.error("Error sending initial message:", error);
    } finally {
      setWaitThread(false);
      setSending(false);
    }
  };

  const handleSave = async () => {
    if (!validateFields()) return; // Prevent save if validation fails

    const userInfo = {
      firstName,
      lastName,
      linkedinProfile: linkedIn,
      role,
      companyWebsite,
    };

    const token = localStorage.getItem('token');

    // try {
    //   const response = await fetch(`${sourceUrl}/userInfo`, {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //       'Authorization': `Bearer ${token}`,
    //     },
    //     body: JSON.stringify(userInfo),
    //   });

    //   if (response.ok) {
    //     console.log('User details saved successfully');
    //     sendEditMessage("No, edit details");
    //     onClose(); // Close the modal after saving
    //   } else {
    //     console.error('Failed to save user details');
    //   }
    // } catch (error) {
    //   console.error('Error:', error);
    // }

    sendEditMessage("Thank you for providing details. Let me set things up for you");
    onClose();
  };

  const hasError = (field) => !!errors[field];

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="edit-details-title"
      aria-describedby="edit-details-description"
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Box
        sx={{
          bgcolor: settingsModal_bg_color_dm,
          boxShadow: 24,
          borderRadius: '8px',
          width: '720px',
          position: 'relative',
          // borderBottom: '1px solid #fff',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            pb: 1,
            borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
            padding: '20px',
          }}
        >
          <Typography id="edit-details-title" variant="h6" component="h2">
            Edit Personal Details
          </Typography>
          <Button
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: '20px',
              top: '20px',
              padding: '8px',
              minWidth: 'auto',
              color: '#fff',
            }}
          >
            X
          </Button>
        </Box>

        <Box component="form" sx={{ mt: 2, padding: '20px 40px' }}>
          <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '40px' }}>
          <TextField
              label="First Name*"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              fullWidth
              // error={hasError('firstName')}
              helperText={hasError('firstName') ? <span style={{ color: '#F2B8B5' }}>This is a required field</span> : ''}
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                sx: {
                  color: 'rgba(255,255,255,0.8)',
                  '&.Mui-focused': { color: '#CAC4D0 !important' },
                },
              }}
              InputProps={{
                sx: {
                  color: '#FFFFFF',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: hasError('firstName') ? '#F2B8B5 !important' : '#FFFFFF !important',
                    borderWidth: hasError('firstName') ? '2px !important' : '1px !important',
                  },
                },
              }}
            />

            <TextField
              label="Last Name*"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              fullWidth
              // error={hasError('lastName')}
              helperText={hasError('lastName') ? <span style={{ color: '#F2B8B5' }}>This is a required field</span> : ''}
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                sx: {
                  color: 'rgba(255,255,255,0.8)',
                  '&.Mui-focused': { color: '#CAC4D0 !important' },
                },
              }}
              InputProps={{
                sx: {
                  color: '#FFFFFF',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: hasError('lastName') ? '#F2B8B5 !important' : '#FFFFFF !important',
                    borderWidth: hasError('lastName') ? '2px !important' : '1px !important',
                  },
                },
              }}
            />
          </Box>
          <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '40px', mt: 2 }}>
            <TextField
              label="LinkedIn Profile"
              value={linkedIn}
              onChange={(e) => setLinkedIn(e.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                sx: {
                  color: 'rgba(255,255,255,0.8)',
                  '&.Mui-focused': { color: '#CAC4D0 !important' },
                },
              }}
              InputProps={{
                sx: {
                  color: '#FFFFFF',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#FFFFFF !important',
                  },
                },
              }}
            />
            <TextField
              label="Role*"
              value={role}
              onChange={(e) => setRole(e.target.value)}
              fullWidth
              // error={hasError('role')}
              helperText={hasError('role') ? <span style={{ color: '#F2B8B5' }}>This is a required field</span> : ''}
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                sx: {
                  color: 'rgba(255,255,255,0.8)',
                  '&.Mui-focused': { color: '#CAC4D0 !important' },
                },
              }}
              InputProps={{
                sx: {
                  color: '#FFFFFF',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: hasError('role') ? '#F2B8B5 !important' : '#FFFFFF !important',
                    borderWidth: hasError('role') ? '2px !important' : '1px !important',
                  },
                },
              }}
            />
          </Box>
          <Box sx={{ display: 'grid', gridTemplateColumns: '1fr', gap: '40px', mt: 2 }}>
          <TextField
              label="Company Website*"
              value={companyWebsite}
              onChange={(e) => setCompanyWebsite(e.target.value)}
              fullWidth
              // error={hasError('companyWebsite')}
              helperText={hasError('companyWebsite') ? <span style={{ color: '#F2B8B5' }}>This is a required field</span> : ''}
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                sx: {
                  color: 'rgba(255,255,255,0.8)',
                  '&.Mui-focused': { color: '#CAC4D0 !important' },
                },
              }}
              InputProps={{
                sx: {
                  color: '#FFFFFF',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: hasError('companyWebsite') ? '#F2B8B5 !important' : '#FFFFFF !important',
                    borderWidth: hasError('companyWebsite') ? '2px !important' : '1px !important',
                  },
                },
              }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            mt: 3,
            display: 'flex',
            justifyContent: 'flex-end',
            borderTop: '1px solid rgba(255, 255, 255, 0.2)',
            padding: '20px',
          }}
        >
          <Button
            variant="contained"
            onClick={onClose}
            style={{ marginRight: '10px', color: '#17182C', fontSize: '16px', fontWeight: '600', fontFamily: 'inter' }}
            sx={{
              textTransform: 'none',
              bgcolor: uploadFiles_button_default_bg_color,
              '&:hover': { bgcolor: uploadFiles_button_hovered_bg_color },
            }}
          >
            Close
          </Button>
          <Button
            variant="contained"
            onClick={handleSave}
            style={{ marginRight: '10px', color: '#17182C', fontSize: '16px', fontWeight: '600', fontFamily: 'inter' }}
            sx={{
              textTransform: 'none',
              bgcolor: button_bg_color_for_New_Project,
              '&:hover': { bgcolor: '#FFD9AA' },
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditUserDetails;


// import React, { useState } from "react";
// import axios from "axios";
// import {useWorkspace} from "../../../../contexts/WorkspaceContext";


// const EditUserDetails = ({ closeModal }) => {
//   const [firstName, setFirstName] = useState("");
//   const [lastName, setLastName] = useState("");
//   const [linkedInProfile, setLinkedInProfile] = useState("");
//   const [role, setRole] = useState("");
//   const [companyWebsite, setCompanyWebsite] = useState("");
//   const { sendMessage, setCurrentThread, setWaitThread, setSending } = useWorkspace();

//   const handleSave = async () => {
//     const userDetails = {
//       firstName,
//       lastName,
//       linkedInProfile,
//       role,
//       companyWebsite,
//     };

//     try {
//       const response = await axios.post("/userInfo", userDetails, {
//         headers: {
//           "Content-Type": "application/json",
//         },
//       });

//       if (response.status === 200) {
//         const message = "No, edit details";
        
//         // Call sendMessage and update setCurrentThread
//         setWaitThread(true);
//         setSending(true);
        
//         const latestEntry = { sender: "user", content: message };
//         setCurrentThread((prevThread) => [...prevThread, latestEntry]);
        
//         const entryResponse = await sendMessage(latestEntry);
//         if (entryResponse) {
//           setCurrentThread((prevThread) => [...prevThread, entryResponse]);
//         } else {
//           setCurrentThread((prevThread) => [
//             ...prevThread, 
//             { content: "Unable to process your query!", sender: "modalX" }
//           ]);
//         }
//       } else {
//         console.error("Error saving user details:", response.statusText);
//       }
//     } catch (error) {
//       console.error("Error saving user details:", error);
//     } finally {
//       setWaitThread(false);
//       setSending(false);
//     }

//     // Close the modal after saving
//     closeModal();
//   };

//   return (
//     <div className="edit-user-details-modal">
//       <h2>Edit Details</h2>
//       <button onClick={closeModal} className="close-button">X</button>
//       <div className="input-row">
//         <input
//           type="text"
//           placeholder="First Name"
//           value={firstName}
//           onChange={(e) => setFirstName(e.target.value)}
//         />
//         <input
//           type="text"
//           placeholder="Last Name"
//           value={lastName}
//           onChange={(e) => setLastName(e.target.value)}
//         />
//       </div>
//       <div className="input-row">
//         <input
//           type="text"
//           placeholder="LinkedIn Profile"
//           value={linkedInProfile}
//           onChange={(e) => setLinkedInProfile(e.target.value)}
//         />
//         <input
//           type="text"
//           placeholder="Role"
//           value={role}
//           onChange={(e) => setRole(e.target.value)}
//         />
//       </div>
//       <div className="input-row">
//         <input
//           type="text"
//           placeholder="Company Website"
//           value={companyWebsite}
//           onChange={(e) => setCompanyWebsite(e.target.value)}
//         />
//       </div>
//       <div className="modal-footer">
//         <button onClick={closeModal}>Close</button>
//         <button onClick={handleSave}>Save</button>
//       </div>
//     </div>
//   );
// };

// export default EditUserDetails;




