import React, { useState } from "react";
import { Box, Button, Grid, Typography, IconButton } from "@mui/material";
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import sampleImage from '../../../../assets/homeSlide6.webp'

const VideoRatioSettings = () => {
  const {setSelectedHeightForVideoGen,  setSelectedWidthForVideoGen, selectedaspectRatioForVideoGen, setSelectedaspectRatioForVideoGen} = useWorkspace()
  const theme = localStorage.getItem("theme");


  const handleRatioSelect = (ratio) =>{
    setSelectedaspectRatioForVideoGen(ratio)
    const [widthRatio, heightRatio] = ratio.split(':').map(Number);
    // console.log(widthRatio, '==========widthRatio', heightRatio)
    setSelectedHeightForVideoGen(heightRatio)
    setSelectedWidthForVideoGen(widthRatio)
  }

  const getRatioStyle = (ratio) => {
    if(ratio){
      const [widthRatio, heightRatio] = ratio?.split(':').map(Number);
      
      return {
        width: '100%',
        paddingTop: `${(heightRatio / widthRatio) * 100}%`,
        position: 'relative',
        overflow: 'hidden'
      };
    }
  };

  const ImageRatios = ['1:1', '2:3', '3:2', '4:5', '5:4', '9:16' , '16:9'];

  return (
    <>
      <Box>
        <Box sx={{ p: 2 }}>
          {/* <Typography
            variant="h6"
            gutterBottom
            style={{
              fontSize: "16px",
              fontWeight: "500",
              height: "20px",
              marginBottom: "20px",
            }}
          >
            Select Video Ratio
          </Typography>
          <Grid container spacing={1} justifyContent="start">
            {VideoRation.map((videoratio) => (
              <Grid item key={videoratio}>
                <Button
                  variant="outlined"
                  onClick={() => handleVideoRatio(videoratio)}
                  sx={{
                    // width: "54px",
                    // height: "32px",
                    textTransform:"none",
                    backgroundColor:
                    selectedAspectRatioForVideoGen === videoratio ? "#3a3f69" : "#30355f",
                    // backgroundColor: theme === 'dark'? '#30355f' : '',
                    color: theme === "dark" ? "white" : "black",
                    "&:hover": {
                      backgroundColor:
                      selectedAspectRatioForVideoGen === videoratio ? "#3a3f69" : "",
                    },
                    borderColor:
                    selectedAspectRatioForVideoGen === videoratio
                        ? "#cac4d0"
                        : "transparent",
                    mx: 0.5, // Add horizontal margin for spacing
                  }}
                >
                  {videoratio}
                </Button>
              </Grid>
            ))}
          </Grid> */}
          <Typography variant="h6" gutterBottom style={{fontSize:'16px',fontWeight:'500',height:'20px',marginBottom:'20px'}}>
        Select Video Aspect Ratio
      </Typography>
      <Grid container spacing={1} justifyContent="start">
        {ImageRatios.map((ratio) => (
          <Grid item key={ratio}>
            <Button
              variant="outlined"
              onClick={() => handleRatioSelect(ratio)}
              sx={{
                width: '54px',
                height: '32px',
                backgroundColor: selectedaspectRatioForVideoGen === ratio ? '#3a3f69' : '#30355f',
                // backgroundColor: theme === 'dark'? '#30355f' : '',
                color: theme === 'dark' ? 'white' : 'black',
                "&:hover": {
                    backgroundColor: "#373E6D", 
                    border:" 1px solid #373E6D"
                  },
                borderColor: selectedaspectRatioForVideoGen === ratio ? '#cac4d0' : 'transparent',
                mx: 0.5, // Add horizontal margin for spacing
              }}
            >
              {ratio}
            </Button>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ mt: 2,padding:"10px" ,position: 'relative', width: '100%', height: 'auto', display: 'flex', justifyContent: 'center' }}>
        <Box sx={getRatioStyle(selectedaspectRatioForVideoGen)}>
          <img
            src={sampleImage}
            alt="Sample"
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover' }}
          />
        </Box>
      </Box>
        </Box>
      </Box>
    </>
  );
};

export default VideoRatioSettings;
