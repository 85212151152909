// const WebTrackingPagination = ({ totalPages, currentPage, onPageChange }) => {
//     const maxVisibleButtons = 6;

//     const getPagination = () => {
//         const pages = [];
//         const leftBound = Math.max(2, currentPage - 2);
//         const rightBound = Math.min(totalPages - 1, currentPage + 2);

//         // Add the first page
//         pages.push(1);

//         // Add left ellipsis if necessary
//         if (leftBound > 2) {
//             pages.push("...");
//         }

//         // Add the middle range
//         for (let i = leftBound; i <= rightBound; i++) {
//             pages.push(i);
//         }

//         // Add right ellipsis if necessary
//         if (rightBound < totalPages - 1) {
//             pages.push("...");
//         }

//         // Add the last page
//         pages.push(totalPages);

//         return pages;
//     };

//     const pagination = getPagination();

//     return (
//         <div style={{ display: "flex", gap: "8px", alignItems: "center"}}>
//             {/* Previous Button */}
//             <button
//                 style={{
//                     padding: "8px 12px",
//                     border: "1px solid #ccc",
//                     background: currentPage === 1 ? "#f0f0f0" : "#fff",
//                     color: currentPage === 1 ? "#aaa" : "#000",
//                     cursor: currentPage === 1 ? "not-allowed" : "pointer",
//                 }}
//                 disabled={currentPage === 1}
//                 onClick={() => currentPage > 1 && onPageChange(currentPage - 1)}
//             >
//                 Prev
//             </button>

//             {/* Pagination Buttons */}
//             {pagination.map((page, index) =>
//                 page === "..." ? (
//                     <span key={index} style={{ padding: "8px 12px", color: "#aaa" }}>
//                         ...
//                     </span>
//                 ) : (
//                     <button
//                         key={index}
//                         style={{
//                             padding: "8px 12px",
//                             border: "1px solid #ccc",
//                             background: page === currentPage ? "#007bff" : "#fff",
//                             color: page === currentPage ? "#fff" : "#000",
//                             cursor: "pointer",
//                             fontWeight: page === currentPage ? "bold" : "normal",
//                         }}
//                         onClick={() => onPageChange(page)}
//                     >
//                         {page}
//                     </button>
//                 )
//             )}

//             {/* Next Button */}
//             <button
//                 style={{
//                     padding: "8px 12px",
//                     border: "1px solid #ccc",
//                     background: currentPage === totalPages ? "#f0f0f0" : "#fff",
//                     color: currentPage === totalPages ? "#aaa" : "#000",
//                     cursor: currentPage === totalPages ? "not-allowed" : "pointer",
//                 }}
//                 disabled={currentPage === totalPages}
//                 onClick={() =>
//                     currentPage < totalPages && onPageChange(currentPage + 1)
//                 }
//             >
//                 Next
//             </button>
//         </div>
//     );
// };

// export default WebTrackingPagination;

import React from "react";
import Pagination from "@mui/material/Pagination";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const WebTrackingPagination = ({ totalPages, currentPage, onPageChange }) => {
  const handlePageChange = (event, value) => {
    onPageChange(value);
  };

  // Custom theme to set button text color to white and make buttons rounded
  const theme = createTheme({
    typography: {
        fontFamily: "Inter", // Set Inter as the default font
    },
    components: {
      MuiPagination: {
        styleOverrides: {
          root: {
            "& .MuiPaginationItem-root": {
              color: "#ffffff", // Default text color
              borderRadius: "20px", // Make buttons rounded
              "&:hover": {
                backgroundColor: "rgba(179, 168, 183, 0.26)" // Slightly darker shade on hover
              },
              fontSize: "14px", 
            },
            "& .MuiPaginationItem-page.Mui-selected": {
              backgroundColor: "#CAC4D0", // Background color for selected page
              color: "black", // Text color for selected page
              "&:hover": {
                backgroundColor: "#CAC4D0", // Keep the same background color on hover
              },
            },
            "& .MuiPaginationItem-ellipsis": {
              color: "#ffffff", // Text color for ellipsis
            },
            "& .MuiPaginationItem-previousNext": {
              color: "#ffffff", // Text color for Prev/Next buttons
              borderRadius: "20px", // Make Prev/Next buttons rounded
            },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div style={{ display: "flex", justifyContent: "center", margin: "16px 0" }}>
        <Pagination
          count={totalPages} // Total number of pages
          page={currentPage} // Current page
          siblingCount={1} // Show one previous and one upcoming page
          boundaryCount={1} // Show first and last page buttons
          onChange={handlePageChange} // Handle page change
          shape="rounded" // Rounded buttons for better UI
          size="large" // Larger size for better visibility
        //   color="primary" // Color theme
        />
      </div>
    </ThemeProvider>
  );
};

export default WebTrackingPagination;


