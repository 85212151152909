// import React,{useState} from "react";
// import { Box, Button, Typography } from "@mui/material";
// import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
// import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
// import AddIcon from '@mui/icons-material/Add';
// import ContentCopyIcon from '@mui/icons-material/ContentCopy';
// import ShareIcon from '@mui/icons-material/Share';
// import { useWorkspace } from "../../../../contexts/WorkspaceContext";
// import sourceUrl from "../../../../api/Source.api";

// function AdditionalOptionForLastConversationForTrackWebsite({prompt}) {
//   const {setIswebTrackClicked,setCurrentWebsiteLinkedId,setShowSuggested,clearThread,setThreadFiles,setHistoryFiles,setThreadId,setCurrentThread,setEditClick} = useWorkspace();
//   const token = localStorage.getItem("token")

//   const decodeUnicode = (str) => {
//     // Ensure str is a valid string, if not, return an empty string or handle it as needed
//     if (!str || typeof str !== "string") return str || "";

//     // Handle new line replacements
//     const stringNewLine = str.split("\\n").join("\n");

//     // Handle unicode characters
//     let stringNewLineWithAnchorTag = stringNewLine.replace(/\\u[\dA-F]{4}|u[\dA-F]{4}/gi, (match) => {
//       const hexCode = match.replace(/\\?u/g, ''); // Remove '\u' or 'u'
//       return String.fromCharCode(parseInt(hexCode, 16)); // Convert hex code to character
//     });

//     // Remove any remaining backslashes
//     const finalString = stringNewLineWithAnchorTag.replace(/\\/g, '');

//     return finalString;
//   };


//   // Function to decode HTML entities
//   const decodeContent = (content) => {
//     return decodeUnicode((content));
//   };

//   const decodedContent = decodeContent(prompt);


//   const [copy, setCopy] = useState(false);

//   const copyFunc = () => {
//     // Copy decodedContent to clipboard
//     navigator.clipboard.writeText(decodedContent).then(() => {
//       setCopy(true); // Set copy to true to indicate the content has been copied
//       setTimeout(() => setCopy(false), 2000); // Reset after 2 seconds
//     });
//   };

//   const createThreadId = async () => {
//     try {
//       const response = await fetch(`${sourceUrl}/threads`, {
//         method: "POST",
//         credentials: "include",
//         headers: {
//           "Content-Type": "application/json",
//           "Authorization": `Bearer ${token}`
//         },
//       });

//       const responseData = await response.json();

//       if (responseData.status) {
//         const newThreadId = responseData.data.match(/(.*)/)[1];
//         setThreadId(newThreadId);
//         localStorage.setItem('threadId', newThreadId); // Store the new threadId in localStorage
//         return newThreadId;
//       } else {
//         throw new Error("Thread creation failed");
//       }
//     } catch (error) {
//       console.error("Thread creation error:", error);
//       return null;
//     }
//   }

//   const ContinueChat = async(prompt) => {
//     setIswebTrackClicked(false);
//     setCurrentWebsiteLinkedId(null);
//     setShowSuggested(true)
//     clearThread();
//     setThreadFiles([]);
//     setHistoryFiles([]);
//     localStorage.removeItem("current_thread_id");
//     localStorage.removeItem("current_history_id");
//     const newThreadId = await createThreadId();
//     setThreadId(newThreadId);
//     const latestEntry = { sender: "user", content: prompt };
//     setCurrentThread((prevThread) => [...prevThread, latestEntry]);
//     setEditClick(true);
//   }

//   return (
//     <div>
//       <Box
//         sx={{
//           width: "600px",
//           height: "48px",
//           marginTop: "12px",
//           display: "flex",
//           justifyContent: "space-between",
//           alignItems: "center",
//         }}
//       >
//         {/* <Box
//           sx={{
//             width: "108px",
//             height: "48px",
//             display: "flex",
//             justifyContent: "space-between",
//             alignItems: "center",
//           }}
//         >
//           <Box
//             sx={{
//               width: "48px",
//               height: "48px",
//               borderRadius: "50%",
//               bgcolor: "#1F233E",
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               "&:hover": {
//                 backgroundColor:  "#282D51",
//                 cursor: "pointer"
//               },
//             }}
//           ><ThumbUpOffAltIcon/>

//           </Box>

//           <Box
//             sx={{
//               width: "48px",
//               height: "48px",
//               borderRadius: "50%",
//               bgcolor: "#1F233E",
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               "&:hover": {
//                 backgroundColor:  "#282D51",
//                 cursor: "pointer"
//               },
//             }}
//           >
//             <ThumbDownOffAltIcon/>
//           </Box>
//         </Box> */}
//         <Box
//           sx={{
//             width: "276px",
//             height: "48px",
//             display: "flex",
//             justifyContent: "space-between",
//             alignItems: "center",
//           }}
//         >
//           <Button
//             sx={{
//               width: "156px",
//               height: "48px",
//               borderRadius: "24px",
//               bgcolor: "#1F233E",
//               display: "flex",
//               justifyContent: 'space-evenly',
//               alignItems: "center",
//               "&:hover": {
//                 backgroundColor:  "#282D51",
//                 cursor: "pointer"
//               },
//               textTransform:'none'
//             }}
//             onClick={() => ContinueChat(prompt)}
//           ><AddIcon sx={{color: "#CAC4D0"}}/>
//           <Typography sx={{
//             fontFamily: "Inter",
//             fontSize: "14px",
//             fontWeight: "500",
//             textAlign: "left",
//             color: "#CAC4D0",
//           }}>Create project</Typography>

//           </Button>

//           <Box
//            onClick={() => copyFunc()}
//             sx={{
//               width: "48px",
//               height: "48px",
//               borderRadius : '50%',
//               bgcolor: "#1F233E",
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               "&:hover": {
//                 backgroundColor:  "#282D51",
//                 cursor: "pointer"
//               },
//             }}
//           >
//             <ContentCopyIcon/>
//           </Box>
//           <Box
//             onClick={() => alert("Coming soon!..")}
//             sx={{
//               width: "48px",
//               height: "48px",
//               borderRadius : '50%',
//               bgcolor: "#1F233E",
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               "&:hover": {
//                 backgroundColor:  "#282D51",
//                 cursor: "pointer"
//               },
//             }}
//           >
//             <ShareIcon/>
//           </Box>
//         </Box>
//       </Box>
//     </div>
//   );
// }

// export default AdditionalOptionForLastConversationForTrackWebsite;


import React, { useState } from "react";
import { Box, Button, Typography, Tooltip } from "@mui/material";
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShareIcon from '@mui/icons-material/Share';
import { useWorkspace } from "../../../../contexts/WorkspaceContext";
import sourceUrl from "../../../../api/Source.api";
import { useMediaQuery } from "react-responsive";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AdditionalOptionForLastConversationForTrackWebsiteForSmallScreen from './AdditionalOptionForLastConversationForTrackWebsiteForSmallScreen'
import videoIcon from "../../../../assets/Button/videoIcon.png";
import pictureIcon from "../../../../assets/Button/Picture.png";
import musicIcon from "../../../../assets/Button/Music Library.png";
import presentationIcon from "../../../../assets/Button/presentation.png";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { trackSearchInteraction } from "../../../../config/analytics";

function AdditionalOptionForLastConversationForTrackWebsite({ prompt }) {
  const { setIswebTrackClicked, setCurrentWebsiteLinkedId, setShowSuggested, clearThread, setThreadFiles, setHistoryFiles, setThreadId, setCurrentThread, setEditClick,setSending,setWaitThread,sendMessage,setShowCreditRecharge,currentThread,setShowAudioSettings,setShowImageSettings,setShowPptSettings,setShowVideoSettings,setSelectedOneClickButtonFromWebtrackNotificationMsg,notificationTextFromWebTracking,setNotificationTextFromWebTracking } = useWorkspace();
  const token = localStorage.getItem("token");
  const [moreoption, setMoreOption] = useState(false);
  const [selectedAction, setSelectedAction] = useState()
  const isUnder992px = useMediaQuery({ maxWidth:992});
  const [input,setInput] = useState("");

  const decodeUnicode = (str) => {
    if (!str || typeof str !== "string") return str || "";
    const stringNewLine = str.split("\\n").join("\n");
    let stringNewLineWithAnchorTag = stringNewLine.replace(/\\u[\dA-F]{4}|u[\dA-F]{4}/gi, (match) => {
      const hexCode = match.replace(/\\?u/g, ''); 
      return String.fromCharCode(parseInt(hexCode, 16));
    });
    return stringNewLineWithAnchorTag.replace(/\\/g, '');
  };

  const decodeContent = (content) => decodeUnicode(content);
  const decodedContent = decodeContent(prompt);

  const [copy, setCopy] = useState(false);
  const [projectTooltip, setProjectTooltip] = useState("Create a new project based on this information");
  const [pptTooltip, setPptTooltip] = useState("Generate a ppt about this info");
  const [videoTooltip, setVideoTooltip] = useState("Generate a video about this info");
  const [imageTooltip, setImageTooltip] = useState("Generate a image about this info");
  const [audioTooltip, setaudioTooltip] = useState("Generate a audio about this info");
  const [shareTooltip, setShareTooltip] = useState("Share to feed");

  const copyFunc = () => {
    navigator.clipboard.writeText(decodedContent).then(() => {
      setCopy(true);
      setTimeout(() => setCopy(false), 2000);
    });
  };

  const createThreadId = async () => {
    try {
      const response = await fetch(`${sourceUrl}/threads`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
      });
      const responseData = await response.json();
      if (responseData.status) {
        const newThreadId = responseData.data.match(/(.*)/)[1];
        setThreadId(newThreadId);
        localStorage.setItem('threadId', newThreadId);
        return newThreadId;
      } else {
        throw new Error("Thread creation failed");
      }
    } catch (error) {
      console.error("Thread creation error:", error);
      return null;
    }
  };

  // const ContinueChat = async (prompt) => {
  //   setIswebTrackClicked(false);
  //   // setCurrentWebsiteLinkedId(null);
  //   setShowSuggested(true);
  //   clearThread();
  //   setThreadFiles([]);
  //   setHistoryFiles([]);
  //   localStorage.removeItem("current_thread_id");
  //   localStorage.removeItem("current_history_id");
  //   const newThreadId = await createThreadId();
  //   setThreadId(newThreadId);
  //   const latestEntry = { sender: "user", content: prompt };
  //   setCurrentThread((prevThread) => [...prevThread, latestEntry]);
  //   setEditClick(true);
  //   setProjectTooltip(""); // Clear tooltip after click
  // };

  const handleSubmit = async (input) => {
    // setIswebTrackClicked(false);
    // trackSearchInteraction(input);
    // setCurrentWebsiteLinkedId(null);
    // clearThread();
    // setThreadFiles([]);
    // setHistoryFiles([]);
    // localStorage.removeItem("current_thread_id");
    // localStorage.removeItem("current_history_id");
    // const newThreadId = await createThreadId();
    // setThreadId(newThreadId);
    setSending(true);
    setWaitThread(true);
    if (input.trim()) {
      const latestEntry = { sender: "user", content: input };
      // setCurrentThread((prevThread) => {
      //   const updatedThread = prevThread[prevThread.length - 1]?.sender === "user"
      //     ? prevThread.slice(0, -1) // Remove the last entry if it's from the user
      //     : prevThread;
      //   return [...updatedThread, latestEntry];
      // })
      if(currentThread[currentThread.length - 1]?.sender !== "user"){
        setCurrentThread((prevThread) => [...prevThread, latestEntry]);
      }
      
      const entryResponse = await sendMessage(latestEntry).catch(console.error);
      // if(findUrl(entryResponse.content)){
      //   setHasLink(true)
      //   await extractLinks(entryResponse.content)
      //   }
      if (entryResponse) {
        const url = findUrl(entryResponse.content);
        if (url && url.includes("dailylimitexceeded")) {
          const fileFormat = getFileFormat(url);
          const responseMessage = {
            content: `Hi there,\n\nIt looks like you’ve reached your daily limit for generating `+ fileFormat +` files...`,
            sender: 'modalX',
          };
          setCurrentThread((prevThread) => [...prevThread, responseMessage]);
        } else {
          setCurrentThread((prevThread) => [...prevThread, entryResponse]);
        }
        setSending(false);
      } else {
        setCurrentThread((prevThread) => [...prevThread, { content: "Unable to process your query!..", sender: 'modalX' }]);
        setSending(false);
      }
    }
  
    setWaitThread(false);
  };

  const findUrl = (msg) => {
    const urlRegex = /(https?:\/\/[^']*?\.(mp3|mp4|pptx|jpg|jpeg|png|txt|ppt))/g;
    const match = urlRegex.exec(msg);
    return match ? match[1] : null;
  };

  const getFileFormat = (url) => {
    // Extract the file extension from the URL
    const parts = url.split('.');
    if (parts.length > 1) {
      return parts[parts.length - 1]; // Return the last part as the file format
    } else {
      return ''; // Return empty string if no file extension found
    }
  };

  const handleClickMoreOption = (event) => {
    setMoreOption(event.currentTarget); // Get button element
  }

  const data1 = [
    { id: 1, image: <ThumbUpOffAltIcon />, Hovertitle: 'Good response', },
    { id: 2, image: <ThumbDownOffAltIcon />, Hovertitle: 'Bad response', },
  ];

  const handleOpenSettings = async (fileType) => {
    setSelectedOneClickButtonFromWebtrackNotificationMsg(fileType);
    setNotificationTextFromWebTracking(`Create ${(fileType === ('ppt' || 'video'))?'a':'an'} ${fileType} based on the following content ${prompt}`);
    setIswebTrackClicked(false);
    setCurrentWebsiteLinkedId(null);
    setShowSuggested(true)
    clearThread();
    setThreadFiles([]);
    setHistoryFiles([]);
    localStorage.removeItem("current_thread_id");
    localStorage.removeItem("current_history_id");
    const newThreadId = await createThreadId();
    setThreadId(newThreadId);
    if(fileType === 'ppt'){
      setShowPptSettings(true)
    }
    else if(fileType === 'image'){
      setShowImageSettings(true);
    }
    else if(fileType === 'audio'){
      setShowAudioSettings(true);
    }
    else if(fileType === 'video'){
      setShowVideoSettings(true);
    }
  }

  return (
    <div>
      {
        !isUnder992px ?
        <Box
        sx={{
          // width: "600px",
          height: "48px",
          marginTop: "12px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* <Box
          sx={{
            width: "108px",
            height: "48px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "48px",
              height: "48px",
              borderRadius: "50%",
              bgcolor: "#1F233E",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              "&:hover": {
                backgroundColor: "#282D51",
                cursor: "pointer"
              },
            }}
          ><ThumbUpOffAltIcon/>
          </Box>
          <Box
            sx={{
              width: "48px",
              height: "48px",
              borderRadius: "50%",
              bgcolor: "#1F233E",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              "&:hover": {
                backgroundColor: "#282D51",
                cursor: "pointer"
              },
            }}
          >
            <ThumbDownOffAltIcon/>
          </Box>
        </Box> */}
        <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between", width:"100%" }}>
        <Box sx={{display:"flex", gap:"12px"}}>
        {/* {data1.map((e) => (
                  <Box 
                    // onClick={() => handleClick(e.id)}
                    onClick={() => {
                      const action = e.id === 1 ? 'like' : 'dislike';
                      setSelectedAction(action);
                      // onUserAction(action);
                      // handleClick(e.id); // Call handleClick after setting the action
                    }}
                    key={e.id}
                    sx={{
                      width: "48px",
                      height: "48px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // backgroundColor: clickedItems[e.id] ? "#2F355F" : "#1F233E",
                      backgroundColor : e.id === 1 && selectedAction === 'like' || e.id === 2 && selectedAction === 'dislike' ? '#2F355F' : '#1F233E',
                      borderRadius: "50%",
                      "&:hover": {
                        // backgroundColor: clickedItems[e.id] ? "" : "#282D51",
                        cursor: "pointer"
                      },
    
                    }}
                  >
                    <Tooltip
                      title={e.Hovertitle}
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#E6E0E9",
                            color: "#17182C",
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontWeight: 500,
                            padding: "4px 8px 4px 8px",
                            borderRadius: "4px",
                            maxWidth: "104px",
                            textAlign: "left",
                          },
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: 'center',
                          // color: clickedItems[e.id]
                          // ? colorMap[e.id] || "blue" // Use color from colorMap or default to blue
                          // : "#CAC4D0",   
                          // color : getColor(e.id)                      
                          // "&:hover": {
                          //   color: "#FFFFFF",
                          //   cursor : "pointer"
                          // },
                        }}
                        // onClick={(e) => onUserAction(e.id === 1?'like':'dislike')}
                        // onClick={() => {
                        //   const action = e.id === 1 ? 'like' : 'dislike';
                        //   setSelectedAction(action);
                        //   onUserAction(action);
                        // }}
                      >
                        {(e.id === 1 && selectedAction === 'like') ? <ThumbUpIcon /> : (e.id === 2 && selectedAction === 'dislike') ? <ThumbDownIcon /> : e.image}
    
                      </Box>
                    </Tooltip>
                  </Box>
                ))} */}
        </Box>
        <Box
          sx={{
            height: "48px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap:"12px",
          }}
        >

          <Tooltip componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "#E6E0E9",
                    color: "#17182C",
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontWeight: 500,
                    padding: "4px 8px 4px 8px",
                    borderRadius: "4px",
                    textAlign: "left",
                  },
                },
              }}  title={pptTooltip} arrow>
            <Button
              sx={{
                width: "146px",
                height: "48px",
                borderRadius: "24px",
                bgcolor: "#1F233E",
                display: "flex",
                justifyContent: 'space-evenly',
                alignItems: "center",
                "&:hover": {
                  backgroundColor: "#282D51",
                  cursor: "pointer"
                },
                textTransform: 'none'
              }}
              // onClick={() => handleSubmit(`Create a PPT based on the following content \n ${prompt}`)}
              onClick={() => handleOpenSettings('ppt')}
            >
              
              <img src={presentationIcon} alt="presentationIcon" />
              <Typography sx={{
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: "500",
                textAlign: "left",
                color: "#CAC4D0",
              }}>Create PPT</Typography>
            </Button>
          </Tooltip>

          <Tooltip componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "#E6E0E9",
                    color: "#17182C",
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontWeight: 500,
                    padding: "4px 8px 4px 8px",
                    borderRadius: "4px",
                    textAlign: "left",
                  },
                },
              }}  title={audioTooltip} arrow>
            <Button
              sx={{
                width: "146px",
                height: "48px",
                borderRadius: "24px",
                bgcolor: "#1F233E",
                display: "flex",
                justifyContent: 'space-evenly',
                alignItems: "center",
                "&:hover": {
                  backgroundColor: "#282D51",
                  cursor: "pointer"
                },
                textTransform: 'none'
              }}
              // onClick={() => handleSubmit(`Create an audio based on the following content \n ${prompt}`)}
              onClick={() => handleOpenSettings('audio')}
            >
               <img src={musicIcon} alt="musicIcon" />
              <Typography sx={{
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: "500",
                textAlign: "left",
                color: "#CAC4D0",
              }}>Create audio</Typography>
            </Button>
          </Tooltip>

          <Tooltip componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "#E6E0E9",
                    color: "#17182C",
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontWeight: 500,
                    padding: "4px 8px 4px 8px",
                    borderRadius: "4px",
                    textAlign: "left",
                  },
                },
              }}  title={imageTooltip} arrow>
            <Button
              sx={{
                width: "146px",
                height: "48px",
                borderRadius: "24px",
                bgcolor: "#1F233E",
                display: "flex",
                justifyContent: 'space-evenly',
                alignItems: "center",
                "&:hover": {
                  backgroundColor: "#282D51",
                  cursor: "pointer"
                },
                textTransform: 'none'
              }}
              // onClick={() => handleSubmit(`Create an image based on the following content \n ${prompt}`)}
              onClick={() => handleOpenSettings('image')}
            >
               <img src={pictureIcon} alt="pictureIcon" />
              <Typography sx={{
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: "500",
                textAlign: "left",
                color: "#CAC4D0",
              }}>Create image</Typography>
            </Button>
          </Tooltip>

          <Tooltip componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "#E6E0E9",
                    color: "#17182C",
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontWeight: 500,
                    padding: "4px 8px 4px 8px",
                    borderRadius: "4px",
                    textAlign: "left",
                  },
                },
              }}  title={videoTooltip} arrow>
            <Button
              sx={{
                width: "146px",
                height: "48px",
                borderRadius: "24px",
                bgcolor: "#1F233E",
                display: "flex",
                justifyContent: 'space-evenly',
                alignItems: "center",
                "&:hover": {
                  backgroundColor: "#282D51",
                  cursor: "pointer"
                },
                textTransform: 'none'
              }}
              // onClick={() => handleSubmit(`Create a video based on the following content \n ${prompt}`)}
              onClick={() => handleOpenSettings('video')}
            >
              <img src={videoIcon} alt="videoIcon" />
              <Typography sx={{
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: "500",
                textAlign: "left",
                color: "#CAC4D0",
              }}>Create video</Typography>
            </Button>
          </Tooltip>

          <Tooltip componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "#E6E0E9",
                    color: "#17182C",
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontWeight: 500,
                    padding: "4px 8px 4px 8px",
                    borderRadius: "4px",
                    textAlign: "left",
                  },
                },
              }}  title={copy ? "Copied" : "Copy"} arrow>
            <Box
              onClick={copyFunc}
              sx={{
                width: "48px",
                height: "48px",
                borderRadius: '50%',
                bgcolor: "#1F233E",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                "&:hover": {
                  backgroundColor: "#282D51",
                  cursor: "pointer"
                },
              }}
            >
              <ContentCopyIcon />
            </Box>
          </Tooltip>

          <Tooltip componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "#E6E0E9",
                    color: "#17182C",
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontWeight: 500,
                    padding: "4px 8px 4px 8px",
                    borderRadius: "4px",
                    textAlign: "left",
                  },
                },
              }}  title={shareTooltip} arrow>
            <Box
              onClick={() => {
                alert("Coming soon!..");
                setShareTooltip(""); // Clear tooltip after click
              }}
              sx={{
                width: "48px",
                height: "48px",
                borderRadius: '50%',
                bgcolor: "#1F233E",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                "&:hover": {
                  backgroundColor: "#282D51",
                  cursor: "pointer"
                },
              }}
            >
              <ShareIcon />
            </Box>
          </Tooltip>
        </Box>
        </Box>
      </Box>
      :
      <Box
      sx={{
        maxWidth: '600px',
        height: "48px",
        display: "flex",
        justifyContent: "space-between",
        // marginLeft: message.sender ?  "12px" : '12px',
        marginTop: "12px",
      }}
    >
          <Box
            sx={{
              width: "36px",
              height: "36px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // backgroundColor : moreoption ?   "#2F355F" : "#1F233E",
              backgroundColor :   "#1F233E",
              borderRadius: "50%",
              "&:hover": {
                backgroundColor:  "#1F233E",
                cursor: "pointer"
              },

            }}
          >
            <Tooltip
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "#E6E0E9",
                    color: "#17182C",
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontWeight: 500,
                    padding: "4px 8px 4px 8px",
                    borderRadius: "4px",
                    maxWidth: "104px",
                    textAlign: "left",
                  },
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: 'center',
                }}
                onClick={handleClickMoreOption}
                >
                <MoreVertIcon />
              </Box>
              
            </Tooltip>
          </Box>
      </Box> 
    }
    {
    isUnder992px && <AdditionalOptionForLastConversationForTrackWebsiteForSmallScreen moreoption={moreoption} setMoreOption={setMoreOption} selectedAction={selectedAction} setSelectedAction={setSelectedAction} copyFunc={copyFunc} ContinueChat={handleSubmit} isUnder991px={isUnder992px} prompt={prompt}/> 
    }
    </div>
  );
}

export default AdditionalOptionForLastConversationForTrackWebsite;
